import React from 'react';
import { PaymentOptionProps } from './types';

import { PaymentOption as StyledPaymentOption, PaymentText, Icon } from './styles';

const PaymentOption: React.FC<PaymentOptionProps> = ({
  title,
  description,
  feeInfo,
  icon,
  onClick,
}) => (
  <StyledPaymentOption onClick={onClick}>
    <Icon src={icon} />
    <PaymentText isTitle>{title}</PaymentText>
    <PaymentText>{description}</PaymentText>
    <PaymentText className="fee">{feeInfo}</PaymentText>
  </StyledPaymentOption>
);
export { PaymentOption };
