import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import Ticket from 'Components/Ticket';
import { getData } from 'api/api';
import { RootState } from 'store';
import useMobileView from 'hooks/useMobileView';
import { SearchIcon } from 'Assets/Icons';
// import { queryClient } from 'QueryClientConfig';
import {
  Container,
  Input,
  InputContainer,
  QrCodeAdviseText,
  Row,
  Search,
  TixologiLogo,
} from './styles';

interface Props {
  pastEvents?: boolean;
  myListings?: boolean;
}

const TicketList = ({ pastEvents, myListings }: Props) => {
  const isMobile = useMobileView();
  const partnerId = localStorage.getItem('partnerId');
  const hasToken = !!localStorage.getItem('accessToken');
  const [query, setQuery] = useState<string>('');
  const { primaryFontColor, secondaryFontColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );
  const { data: ticket } = useQuery(
    `getTickets${pastEvents ? 'Upcoming' : 'Past'}`,
    () => getData(`/ticket_types/my-tickets/${partnerId}?not_past=${!pastEvents}`),
    {
      enabled: hasToken,
    },
  );
  // const ticketsFromMail: any = queryClient.getQueryData('getTicketsFromMail');

  // const { data: ticketMyListings } = useQuery(
  //   'getEventsMyListings',
  //   () => getData(`/ticket_types/my-listings/${partnerId}`),
  //   {
  //     enabled: hasToken,
  //   },
  // );

  return (
    <Container>
      <InputContainer>
        <Search src={SearchIcon} alt="search" />
        <Input placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
      </InputContainer>
      {/* {!isMobile && (
        <QrCodeAdviseText color={secondaryFontColor}>
          To view your QR code and scan into your event, please view your tickets on your smartphone
          or in the <TixologiLogo>TIXOLOGI</TixologiLogo> mobile app.
        </QrCodeAdviseText>
      )} */}
      {/* {myListings ? (
        <Row isMobile={isMobile} className="wrap">
          {ticketMyListings &&
            Object.entries(ticketMyListings)?.map((data: [any, any]) => {
              const event = {
                name: data[1]?.event_name,
                start_date: data[1]?.event_start_time,
                end_date: data[1]?.event_end_time,
                street_address: data[1]?.venue,
                time_zone: data[1]?.time_zone,
              };
              const myTicket = {
                image: data[1]?.ticket_type_image,
                initial_price: data[1]?.total_price,
                name: data[1]?.ticket_type_name,
                order: null,
                display_type: data[1].display_type,
              };
              return (
                <Ticket
                  myListings
                  item={data[1]}
                  quantity={data[1]?.quantity}
                  id={data[0]}
                  displayType={data[1].display_type}
                  template={{ event, ticket: myTicket }}
                />
              );
            })}
        </Row>
      ) : ( */}
      <Row isMobile={isMobile} className="wrap">
        {ticket &&
          Object.entries(ticket).map((data: [any, any]) => {
            const myTicket = {
              image: data[1]?.ticket_type_image,
              initial_price: data[1]?.tickets[0]?.bought_at,
              name: data[1]?.ticket_type_name,
              order: data[1]?.tickets[0]?.order_id,
            };
            if (data[1].event_name.toLowerCase().includes(query.toLowerCase()))
              return (
                <Ticket
                  key={data[0]}
                  // pastTicket={pastEvents}
                  quantity={data[1].tickets.length}
                  // id={data[0]}
                  item={data[1]}
                  displayType={data[1].display_type}
                  template={{ event: data[1], ticket: myTicket }}
                />
              );
            return null;
          })}
      </Row>
      {/* )} */}
    </Container>
  );
};

export default TicketList;
