import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getData } from 'api/api';
import { BSTypes } from './types';
import { Card, CardNumber, Container } from './Bluesnap';

const cardUrl: { [key: string]: string } = {
  AMEX: 'https://files.readme.io/97e7acc-Amex.png',
  DINERS: 'https://files.readme.io/8c73810-Diners_Club.png',
  DISCOVER: 'https://files.readme.io/caea86d-Discover.png',
  JCB: 'https://files.readme.io/e076aed-JCB.png',
  MASTERCARD: 'https://files.readme.io/5b7b3de-Mastercard.png',
  VISA: 'https://files.readme.io/9018c4f-Visa.png',
};

const loadScript = (src: string) =>
  new Promise<void>((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: (e: any) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

const bsObj = (token: string, setDisable: any) => ({
  token,
  onFieldEventHandler: {
    onError(tagId: string) {
      setDisable((prevState: any) => ({ ...prevState, [tagId]: true }));
    },
    onValid(tagId: string) {
      setDisable((prevState: any) => ({ ...prevState, [tagId]: false }));
    },
    onType(_tagId: string, cardType: string | number) {
      const imageElement = document.querySelector('#card-logo > img');
      imageElement?.setAttribute(
        'src',
        cardUrl[cardType] || '//files.readme.io/d1a25b4-generic-card.png',
      );
    },
  },
  style: {
    ':focus': {},
    input: {
      color: '#4a5568',
    },
    '.invalid': {},
  },
  ccnPlaceHolder: '1234 5678 9012 3456',
  cvvPlaceHolder: '123',
  expPlaceHolder: 'MM/YY',
});

export const submitBS = (bs: any, purchase: (last: number) => void, purchaseError: () => void) => {
  bs?.hostedPaymentFieldsSubmitData((callback: { cardData: { last4Digits: number } }) => {
    if (callback.cardData != null) {
      purchase(callback.cardData.last4Digits);
    } else {
      purchaseError();
    }
  });
};

export const BluesnapFields = ({
  setBS,
  setBsToken,
  setPurchaseButtonState,
  FieldContainer,
  Label,
  ComplianceText,
}: BSTypes) => {
  const [disable, setDisable] = useState({ ccn: false, exp: false, cvv: false });

  useEffect(() => {
    setPurchaseButtonState(Object.values(disable).some((value) => value));
  }, [disable]);

  useQuery({
    queryKey: ['token'],
    queryFn: () => getData('/public/ticketing/financial/initiate_payment'),
    onSuccess: (successData) => {
      const token = successData?.substring(successData?.lastIndexOf('/') + 1);
      loadScript(process.env.REACT_APP_BLUESNAP as string).then(() => {
        setBS((window as any).bluesnap);
        const config = bsObj(token, setDisable);
        setBsToken(token);
        (window as any).bluesnap.hostedPaymentFieldsCreate(config);
      });
    },
  });

  return (
    <Container>
      <CardNumber>
        <Label>Card number</Label>
        <FieldContainer className={disable.ccn ? 'error' : ''} data-bluesnap="ccn" />
        <Card id="card-logo" className="input-group-addon">
          <img
            alt="Card type"
            src="https://files.readme.io/d1a25b4-generic-card.png"
            height="28px"
          />
        </Card>
      </CardNumber>

      <div>
        <Label>Expiration Date</Label>
        <FieldContainer data-bluesnap="exp" className={disable.exp ? 'error' : ''} />
      </div>

      <div>
        <Label>CVV</Label>
        <FieldContainer id="cvv" data-bluesnap="cvv" className={disable.cvv ? 'error' : ''} />
        <span className="helper-text" id="cvv-help" />
      </div>

      <ComplianceText>
        Amex, Discover, Mastercard and Visa all accepted. Final item price includes a surcharge of
        2.94% of the item price to cover credit card service fees.
      </ComplianceText>
    </Container>
  );
};
