import { IsMobileProps } from 'Utils/types';
import styled from 'styled-components';

export const Wrapper = styled.div<IsMobileProps>`
  padding: 40px ${(props) => (props.isMobile ? '40px' : 'min(10%,180px)')};
  height: ${(props) => (props.isMobile ? '60%' : '64%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
  width: 240px;
`;
