import styled from 'styled-components';

import { color2, palette, shadowW } from 'Utils/theme';

export const TicketCard = styled.div<{ isMobile?: boolean }>`
  position: relative;
  width: ${(props) => (props.isMobile ? '90vw' : '400px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${palette.gray[10]};
  box-sizing: border-box;
  box-shadow: 8px 8px 12px rgba(77, 77, 77, 0.19);
  border-radius: 16px;
  cursor: pointer;
`;

export const ContainerTickets = styled.div`
  display: flex;
  gap: 10px;
`;

export const Text = styled.div<{ color?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ color }) => color || palette.black};
  text-align: center;

  &.title {
    margin-top: 32px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  &.bold {
    font-weight: 700;
    margin-left: 0.5em;
  }

  &.container {
    padding: 8px 16px;
    background-color: ${palette.gray[20]};
    border-radius: 199px;
  }

  &.marginBottom {
    margin-bottom: 32px;
  }

  &.marginTop {
    margin-top: 8px;
  }
`;

export const TicketImg = styled.img<{ isMobile?: boolean }>`
  z-index: 1;
  width: 230px;
  border-radius: 18px;
  height: 500px;
  object-fit: cover;

  &.multiple {
    filter: drop-shadow(4.07679px 4.07679px 4.07679px rgba(0, 0, 0, 0.25));
  }

  &.absolute {
    position: absolute;
    transform: rotate(3.34deg);
    bottom: 20px;
    left: 20px;
    z-index: 0;
  }
`;

export const Row = styled.div<{ isMobile?: boolean }>`
  display: flex;
  &.relative {
    position: relative;
    margin: 32px 70px;
  }

  &.multiple {
    margin-left: ${(props) => (props.isMobile ? '40px' : '25px')};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 16px;

  &.background {
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
  }

  &.absolute {
    position: absolute;
  }
`;

export const Container = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 15px ${shadowW};
  border-radius: 8px;
  width: 40%;
  min-width: 150px;
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.color || color2};
    color: ${palette.white};
  }
`;
