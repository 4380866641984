import { AxiosResponse } from 'axios';
import { UseMutateFunction } from 'react-query';

import { airbrake } from 'ErrorBoundary';
import api from 'api/api';
import { BillingInfo, Card, CardInfoCompleted, EncryptedData } from 'Utils/types';
import { submitBS } from 'Components/TicketPurchase/Bluesnap';
import { convertObject, Available } from './utils';

interface PublicKey {
  keyId: string;
  publicKey: string;
}

interface CreateCardProps {
  userId: string | null;
  cardInfo: CardInfoCompleted;
  billingInfo: BillingInfo;
  encryptedData: EncryptedData;
}

export interface PurchaseNewCardProps {
  userId: string | null;
  cardName: string;
  phoneNumber: string;
  isPrimary: boolean;
  resp?: any;
  sessionToken: string;
  tax: number;
  price: any;
  mutateVoid: UseMutateFunction<AxiosResponse<any, any>, unknown, VoidPurchaseProps, unknown>;
  handleAfterPurchase: () => void;
  partnerId: string;
  tixologiFees: number;
  nuveiFees: number;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  fromCart?: boolean;
  serviceFee: number;
  fixedServiceFee: number;
  ticketsWithPromocodes: number[];
  billingDetails: any;
  ourFees: any;
  totalDiscount: any;
  taxes: any;
  last4: any;
  token: string;
}

export interface PurchaseProps {
  userId: string | null;
  cardId?: string;
  sessionToken: string;
  resp: any;
  tax: number;
  price: any;
  relatedTransaction?: string;
  mutateVoid: UseMutateFunction<AxiosResponse<any, any>, unknown, VoidPurchaseProps, unknown>;
  handleAfterPurchase: () => void;
  partnerId: string;
  tixologiFees: number;
  nuveiFees: number;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  fromCart?: boolean;
  serviceFee: number;
  fixedServiceFee: number;
  ticketsWithPromocodes: number[];
  ourFees: any;
  totalDiscount: any;
  taxes: any;
}
interface FreeTicket {
  userId: string | null;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
}

interface PrimaryProps {
  userId: string | null;
  cardId: string;
}

interface PromoCodeValidation {
  ticketTypesID: number[];
  partnerId: number;
  promoCode: string;
}

interface VoidPurchaseProps {
  clientUniqueId: string;
  amount: string;
  currency: string;
  relatedTransactionId: string;
}

interface PaymentWithCard {
  sessionToken: string;
  safeCharge: any;
  cards?: Card[];
  cvv: string;
  PurchaseOnce: (arg: PurchaseProps) => void;
  tax: number;
  price: string;
  ticketTypeId?: string;
  setTransactionId: React.Dispatch<React.SetStateAction<string>>;
  setErrorDescription: React.Dispatch<React.SetStateAction<string>>;
  mutateVoid: UseMutateFunction<AxiosResponse<any, any>, unknown, VoidPurchaseProps, unknown>;
  handleAfterPurchase: () => void;
  tixologiFees: number;
  nuveiFees: number;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  fromCart?: boolean;
  serviceFee: number;
  fixedServiceFee: number;
  ticketsWithPromocodes: number[];
  ourFees: any;
  totalDiscount: any;
  taxes: any;
}
interface Payment {
  sessionToken: string;
  PurchaseNewCardOnce: (arg: PurchaseNewCardProps) => void;
  tax: number;
  price: string;
  ticketTypeId?: string;
  setTransactionId: React.Dispatch<React.SetStateAction<string>>;
  cardN: any;
  cardInfo: CardInfoCompleted;
  billingInfo: BillingInfo;
  setErrorDescription: React.Dispatch<React.SetStateAction<string>>;
  checkedPrimary: boolean;
  mutateVoid: UseMutateFunction<AxiosResponse<any, any>, unknown, VoidPurchaseProps, unknown>;
  handleAfterPurchase: () => void;
  tixologiFees: number;
  nuveiFees: number;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  fromCart?: boolean;
  serviceFee: number;
  fixedServiceFee: number;
  ticketsWithPromocodes: number[];
  ourFees: any;
  totalDiscount: any;
  taxes: any;
  bs: any;
  setLast4: any;
  last4: any;
  bsToken: any;
  purchaseError: any;
}

export const updatePrimary = ({ userId, cardId }: PrimaryProps) =>
  api.put(`/cards/users/primary/${cardId}`);

export const validatePromoCode = ({ ticketTypesID, partnerId, promoCode }: PromoCodeValidation) =>
  api.post(`/public/ticketing/promocodes/validate`, {
    ticket_types: ticketTypesID.map(String),
    promocode_name: promoCode,
    partner_id: partnerId,
  });

export const checkTicketsAvailability = async (tickets: {
  [key: string]: { quantity: number; promocode_id: string };
}): Promise<Available> => {
  const body = convertObject(tickets);
  return api.post('/public/ticketing/ticket_types/availability', body);
};

export const voidPurchase = ({
  clientUniqueId,
  amount,
  currency,
  relatedTransactionId,
}: VoidPurchaseProps) =>
  api.post(`/financial/void_payment`, {
    clientUniqueId,
    amount,
    currency,
    relatedTransactionId,
  });

export const PurchaseNewCard = async ({
  userId,
  cardName,
  isPrimary,
  // resp,
  phoneNumber,
  sessionToken,
  tax,
  price,
  mutateVoid,
  handleAfterPurchase,
  partnerId,
  tixologiFees,
  nuveiFees,
  ticketTypeDic,
  fromCart,
  serviceFee,
  fixedServiceFee,
  ticketsWithPromocodes,
  billingDetails,
  ourFees,
  totalDiscount,
  taxes,
  last4,
  token,
}: PurchaseNewCardProps) => {
  if (partnerId === 'null') {
    // eslint-disable-next-line no-alert
    alert('Something went wrong');
    throw new Error('No partner Id');
  } else {
    const url =
      window.location !== window.parent.location ? document.referrer : document.location.href;

    return api
      .post(`/tickets/multiple_purchase_bluesnap?new_card=true&from_cart=${fromCart}`, {
        payment_type: 'credit_hold',
        ticket_types: ticketTypeDic,
        session_token_id: token,
        last4,
        applied_promo_codes: ticketsWithPromocodes,
        billing_details: billingDetails,
        parent_url: url,
      })
      .catch(() => {
        handleAfterPurchase();
        mutateVoid({
          clientUniqueId: String(partnerId),
          currency: 'USD',
          amount: String((price + tax + tixologiFees + nuveiFees).toFixed(2)),
          relatedTransactionId: token,
        });
      });
  }
};

export const Purchase = async ({
  userId,
  cardId,
  sessionToken,
  resp,
  tax,
  price,
  mutateVoid,
  handleAfterPurchase,
  partnerId,
  tixologiFees,
  nuveiFees,
  ticketTypeDic,
  fromCart,
  serviceFee,
  fixedServiceFee,
  ticketsWithPromocodes,
  ourFees,
  totalDiscount,
  taxes,
}: PurchaseProps) => {
  if (partnerId === 'null') {
    // eslint-disable-next-line no-alert
    alert('Something went wrong');
    throw new Error('No partner Id');
  } else
    return api
      .post(`/tickets/multiple_purchase?from_cart=${fromCart}`, {
        payment_type: 'credit_hold',
        ticket_types: ticketTypeDic,
        card_id: cardId,
        session_token_id: sessionToken,
        client_unique_id: partnerId,
        last4: resp.last4Digits,
        applied_promo_codes: ticketsWithPromocodes,
      })
      .catch(() => {
        handleAfterPurchase();
        mutateVoid({
          clientUniqueId: String(partnerId),
          currency: 'USD',
          amount: String((price + tax + tixologiFees + nuveiFees).toFixed(2)),
          relatedTransactionId: resp.transactionId,
        });
      });
};

export const PurchaseFreeTicket = async ({ userId, ticketTypeDic }: FreeTicket) => {
  const partnerId = localStorage.getItem('partnerId');
  if (partnerId === null) {
    // eslint-disable-next-line no-alert
    alert('Something went wrong. Please refresh page and try again.');
    throw new Error('No partner Id');
  } else
    return api.post(`/tickets/multiple_purchase?free=true`, {
      ticket_types: ticketTypeDic,
      user_id: Number(userId),
      client_unique_id: String(partnerId),
    });
};

export const createPaymentExistingCard = ({
  sessionToken,
  safeCharge,
  cards,
  cvv,
  PurchaseOnce,
  tax,
  price,
  setTransactionId,
  setErrorDescription,
  mutateVoid,
  handleAfterPurchase,
  tixologiFees,
  nuveiFees,
  ticketTypeDic,
  fromCart,
  serviceFee,
  fixedServiceFee,
  ticketsWithPromocodes,
  ourFees,
  totalDiscount,
  taxes,
}: PaymentWithCard) => {
  const userId = localStorage.getItem('userId');
  const partnerId = localStorage.getItem('partnerId');
  if (partnerId === null) {
    setErrorDescription('Something went wrong. Please refresh page and try again.');
    throw new Error('No partner Id');
  }
  try {
    safeCharge.createPayment(
      {
        sessionToken,
        userTokenId: userId,
        paymentOption: {
          userPaymentOptionId: cards?.filter((card: Card) => card.is_primary)[0].id,
          card: {
            CVV: cvv,
          },
        },
        clientUniqueId: partnerId,
      },
      (resp: any) => {
        if (resp?.result === 'APPROVED') {
          PurchaseOnce({
            userId,
            resp,
            sessionToken,
            cardId: cards?.filter((card: Card) => card.is_primary)[0].id,
            tax,
            price,
            mutateVoid,
            handleAfterPurchase,
            partnerId: String(partnerId),
            tixologiFees,
            nuveiFees,
            ticketTypeDic,
            fromCart,
            serviceFee,
            fixedServiceFee,
            ticketsWithPromocodes,
            ourFees,
            totalDiscount,
            taxes,
          });
          setTransactionId(resp.transactionId);
        } else if (resp?.processingOrder || resp?.errCode === 1058) {
          // catches a duplicate invocation of safeCharge.createPayment
        } else {
          setErrorDescription(
            'Something went wrong with your payment. Please try a different card',
          );
        }
      },
    );
  } catch (e) {
    setErrorDescription('Something went wrong with your payment. Please try a different card');
    airbrake.notify({
      error: e,
      context: { component: 'CreditCard/CreditCardModal/Services - external Nuvei Error' },
    });
  }
};

export const createPaymentNewCard = ({
  sessionToken,
  PurchaseNewCardOnce,
  tax,
  price,
  setTransactionId,
  cardN,
  cardInfo,
  billingInfo,
  setErrorDescription,
  checkedPrimary,
  mutateVoid,
  handleAfterPurchase,
  tixologiFees,
  nuveiFees,
  ticketTypeDic,
  fromCart,
  serviceFee,
  fixedServiceFee,
  ticketsWithPromocodes,
  ourFees,
  totalDiscount,
  taxes,
  bs,
  setLast4,
  last4,
  bsToken,
  purchaseError,
}: Payment) => {
  const userId = localStorage.getItem('userId');
  const partnerId = localStorage.getItem('partnerId');
  if (partnerId === null) {
    setErrorDescription('Something went wrong. Please refresh page and try again.');
    throw new Error('No partner Id');
  }
  try {
    submitBS(
      bs,
      (last: number) =>
        PurchaseNewCardOnce({
          userId,
          cardName: cardInfo.name,
          isPrimary: checkedPrimary,
          phoneNumber: billingInfo.phone,
          sessionToken,
          tax,
          price,
          mutateVoid,
          handleAfterPurchase,
          partnerId: String(partnerId),
          tixologiFees,
          nuveiFees,
          ticketTypeDic,
          fromCart,
          serviceFee,
          fixedServiceFee,
          ticketsWithPromocodes,
          billingDetails: {
            name: cardInfo.name,
            city: billingInfo.city,
            country: billingInfo.country,
            district: billingInfo.state,
            line1: billingInfo.addressLine1,
            line2: billingInfo.addressLine2,
            postalCode: billingInfo.postalCode,
          },
          ourFees,
          totalDiscount,
          taxes,
          last4: last,
          token: bsToken,
        }),
      purchaseError,
    );
  } catch (e) {
    setErrorDescription('Something went wrong with your payment. Please try again later');
    airbrake.notify({
      error: e,
      context: { component: 'CreditCard/CreditCardModal/Services - external Nuvei Error' },
    });
  }
};
