import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 20px;
`;

export const Row = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  &.wrap {
    flex-wrap: wrap;
    padding: ${(props) => (props.isMobile ? '' : '40px')};
    gap: ${(props) => (props.isMobile ? '20px' : '40px')};
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 48px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${palette.black};
  margin-left: 70px;
`;
export const Input = styled.input`
  all: unset;
  width: 100%;
  color: #c2c2c2;
  padding: 7px 0px 7px 8px;
`;

export const Search = styled.img`
  width: 20px;
  margin-left: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  background: ${palette.white};
  width: 318px;
  height: 38px;
  border: 1px solid #c2c2c2;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-left: 12px;
`;

export const QrCodeAdviseText = styled.div<{ color?: string }>`
  color: ${({ color }) => color};
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
`;

export const TixologiLogo = styled.strong`
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 30px;
  margin: 0 3px;
  background: linear-gradient(180.18deg, #37fb6e -15.2%, #0a50eb 169.12%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
`;
