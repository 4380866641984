import styled from 'styled-components';

import { palette, shadow } from 'Utils/theme';

export const Container = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &.mobile {
    padding: 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TicketContainer = styled.div`
  padding: 20px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${shadow};
  border-radius: 8px;
  background-color: ${palette.white};
`;

export const Label = styled.label`
  font-size: 14px;

  &.title {
    font-size: 18px;
    font-weight: 600;
  }

  &.price {
    font-weight: 400;
    font-size: 14px;
  }

  &.sold-out {
    opacity: 0.5;
    font-weight: 500;
    width: 112px;
    text-align: center;
    padding: 14px 0;
  }
  &.description {
    font-weight: 400;
    font-size: 12px;
    opacity: 0.7;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;

  &.button {
    gap: 0px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.div`
  background: rgba(228, 228, 228, 0.5);
  border: 1px solid ${palette.gray[100]};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${palette.gray};
  padding: 6px 12px;
  cursor: pointer;
  height: fit-content;
  &.left {
    border-radius: 3.53563px 0px 0px 3.53563px;
  }
  &.right {
    border-radius: 0px 3.53563px 3.53563px 0px;
  }
  &.opacity {
    opacity: 0.5;
  }
`;

export const Input = styled.input`
  all: unset;
  background: ${palette.white};
  border: 0.883907px solid #bbbbbb;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${palette.gray};
  width: 45px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &.opacity {
    opacity: 0.5;
  }
`;
