import React from 'react';

import useMobileView from 'hooks/useMobileView';
import { BluesnapFields } from 'Components/TicketPurchase/Bluesnap';
import { FieldContainer, Label, ComplianceText } from 'Components/TicketPurchase/Bluesnap/Bluesnap';
import { Wrapper, InfoContainer, Title, Div } from './styles';

interface Props {
  setBS: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  setBsToken: React.Dispatch<React.SetStateAction<string>>;
  setPurchaseButtonState: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreditCardDetails = ({ setBsToken, setBS, setPurchaseButtonState }: Props) => {
  const isMobile = useMobileView();

  return (
    <Wrapper isMobile={isMobile}>
      <InfoContainer className={`${isMobile ? 'mobile' : ''}`}>
        <Title>Enter Card Information</Title>
        <Div>
          <BluesnapFields
            setBsToken={setBsToken}
            setBS={setBS}
            setPurchaseButtonState={setPurchaseButtonState}
            FieldContainer={FieldContainer}
            Label={Label}
            ComplianceText={ComplianceText}
          />
        </Div>
      </InfoContainer>
    </Wrapper>
  );
};

export default CreditCardDetails;
