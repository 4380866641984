import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { blackOpacity, kinectAirColors, palette } from 'Utils/theme';

export const styles = {
  container: {
    background: palette.gray[30],
    border: `1px solid ${blackOpacity}`,
    borderRadius: 8,
    padding: '8px',
  },
  input: {
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 20,
    color: palette.black,
    paddingLeft: 68,
    height: 30,
    background: palette.gray[30],
    width: '100%',
  },
  button: {
    background: palette.white,
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: 3,
    height: '80%',
    top: 4,
    width: 58,
    paddingLeft: 8,
  },
};

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: `1px solid ${blackOpacity}`,
    background: palette.gray[30],
    borderRadius: 8,
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${blackOpacity}`,
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 20,
    color: palette.black,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isFocused && 'rgba(196, 196, 196, 0.5)',
    fontStyle: 'normal',
    fontWeight: state.isFocused ? '600' : '400',
    fontSize: 18,
    color: palette.black,
  }),
};

export const StyledInputMask = styled(InputMask)<{ error: boolean }>`
  all: unset;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${palette.black};
  padding: 8px;
  cursor: auto;
  background: ${palette.gray[30]};
  border-radius: 8px;
  border: 1px solid ${(props) => (props.error ? palette.error : blackOpacity)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${kinectAirColors.font};
`;

export const StyledInput = styled.input<{ isCreditCard?: boolean }>`
  all: unset;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${palette.black};
  padding: 8px;
  cursor: auto;
  background: ${palette.gray[30]};
  border-radius: 8px;
  border: ${(props) => !props.isCreditCard && `1px solid ${blackOpacity}`};
  width: ${(props) => props.isCreditCard && '100%'};
  &.error {
    border: 1px solid ${palette.error};
  }
`;

export const Row = styled.div`
  display: flex;
  &.borderBottom {
    border: 1px solid ${blackOpacity};
    background: ${palette.gray[30]};
    border-radius: 8px;
  }
  &.space {
    justify-content: space-between;
  }
  &.error {
    border: 1px solid ${palette.error};
  }
`;

export const Img = styled.img`
  width: 38px;
`;
