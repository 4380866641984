import { ClosedDropdown, OpenedDropdown } from 'Assets/Icons';
import React, { useState } from 'react';
import { SELECT } from 'Utils/constants';

import { Container, InputContainer, Label, Span, DropdownContainer, Button, Icon } from './styles';

interface Props {
  label: string;
  setValue: (option: string) => void;
  options: string[];
  value: string;
  className?: string;
}

const Select = ({ label, setValue, options, value, className }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Label className={className}>{label.toUpperCase()}</Label>
      <InputContainer
        className={`${open ? 'open' : ''} ${className}`}
        onClick={() => setOpen(!open)}
        onMouseLeave={() => setOpen(false)}
      >
        <Span className={value ? '' : 'placeholder'}>{value || SELECT.toUpperCase()}</Span>
        <Button className={open ? 'open' : ''}>
          <Icon src={open ? OpenedDropdown : ClosedDropdown} />
        </Button>
        {open && (
          <DropdownContainer>
            {options.map((option) => (
              <Span className="option" key={option} onClick={() => setValue(option)}>
                {option}
              </Span>
            ))}
          </DropdownContainer>
        )}
      </InputContainer>
    </Container>
  );
};

export default Select;
