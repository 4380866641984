import styled from 'styled-components';

import { kinectAirColors, palette } from 'Utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.gray[50]};
  width: 100%;
  height: 700px;
`;

export const Row = styled.div`
  color: ${kinectAirColors.font};
  display: flex;
  &.border-bottom {
    border-bottom: 1px solid ${palette.gray[75]};
    margin-left: 12px;
  }
  &.padding {
    padding: 12px 12px 12px 0px;
  }
  &.margin-left {
    margin-left: 12px;
  }
  &.margin-top {
    margin-top: 12px;
  }
  &.full-width {
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.full-width {
    width: 100%;
  }
  &.margin-left {
    margin-left: 12px;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.mobile {
    gap: 8px;
  }
  &.center {
    justify-content: center;
  }
  &.row {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TicketContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 150px;
`;

export const Text = styled.div`
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${kinectAirColors.font};
  &.title {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
  &.description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
=  }
  &.time {
    font-size: 10px;
  }
  &.price {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
`;

export const Button = styled.div`
  background: ${palette.gray[11]};
  border: 1px solid ${palette.gray[100]};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${palette.gray[12]};
  padding: 6px 12px;
  height: fit-content;
  cursor: pointer;
  margin-left: 16px;
`;

export const Img = styled.img`
  height: 100%;
  width: 90%;
  object-fit: cover;
  border-radius: 4px;
`;
