import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';
import { ThreeDots } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'store';
import { kinectAirColors, palette } from 'Utils/theme';
import { Card } from 'Utils/types';
import {
  setCardInfo,
  setBillingInfo,
  setReceivingTicketInfo,
  reset,
} from 'Slices/paymentInfoSlice';
import useMobileView from 'hooks/useMobileView';
import { poweredTixologi } from 'Assets/Images';
import { OpenOrderProps, openOrder } from 'Components/TicketPurchase/services';
import { queryClient } from 'QueryClientConfig';
import { Button } from 'Components/Common';
import { deleteFromCart } from 'Components/Common/Cart/CartRow/services';
import { sendGAEvent } from 'Utils/utils';
import CreditCardDetails from '../CreditCardDetails';
import BillingDetails from '../BillingDetails';
import RecevingTickets from '../RecevingTickets';
import {
  checkTicketsAvailability,
  createPaymentNewCard,
  createPaymentExistingCard,
  Purchase,
  PurchaseFreeTicket,
  PurchaseNewCard,
  voidPurchase,
  PurchaseNewCardProps,
  PurchaseProps,
} from './services';
import PurchaseWithCard from '../PurchaseWithCard';
import { NestedTicketObject, filterObjectByKeys, getKeysWithFalseValues } from './utils';
import { useMutateOnce } from './hooks';

import {
  ModalContainer,
  modalStyle,
  Row,
  Title,
  TitleContainer,
  Icon,
  Img,
  ButtonContainer,
} from './styles';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  fromPurchase?: boolean;
  titles: {
    title: string;
    code: string;
  }[];
  cards?: Card[];
  setShowCards?: React.Dispatch<React.SetStateAction<boolean>>;
  showCards?: boolean;
  totalTaxes: number;
  nuveiFees: number;
  tixologiFees: number;
  ourFees: any;
  price: any;
  setCardN: React.Dispatch<React.SetStateAction<null>>;
  cardN: any;
  isLoading: boolean;
  setTransactionId: React.Dispatch<React.SetStateAction<string>>;
  setIsRejectedOpen: React.Dispatch<React.SetStateAction<boolean>>;
  totalAmount: number;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  fromCart?: boolean;
  setIsChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseOnClick?: React.Dispatch<React.SetStateAction<boolean>>;
  serviceFee: number;
  fixedServiceFee: number;
  setTicketsWithPromocodes: React.Dispatch<React.SetStateAction<number[]>>;
  ticketsWithPromocodes: number[];
  taxes: any;
  fetchCart: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>;
  setErrorDescription: React.Dispatch<React.SetStateAction<string>>;
  errorDescription: string;
  openSoldOutModal: () => void;
  setUnavailableTickets: (obj: NestedTicketObject) => void;
}

const CreditCardModal = ({
  isOpen,
  setIsOpen,
  setIsConfirmationOpen,
  fromPurchase,
  titles,
  cards,
  setShowCards = () => false,
  showCards,
  setCardN,
  cardN,
  isLoading,
  setTransactionId,
  totalTaxes,
  nuveiFees,
  tixologiFees,
  price,
  setIsRejectedOpen,
  totalAmount,
  ticketTypeDic,
  fromCart,
  setIsChanged = () => Function,
  setPurchaseOnClick = () => Function,
  serviceFee,
  fixedServiceFee,
  setTicketsWithPromocodes,
  ticketsWithPromocodes,
  ourFees,
  taxes,
  fetchCart,
  setErrorDescription,
  errorDescription,
  openSoldOutModal,
  setUnavailableTickets,
}: Props) => {
  const [selected, setSelected] = useState<string>(fromPurchase ? 'RT' : 'CI');
  const [isCardInfoCompleted, setIsCardInfoCompleted] = useState<boolean>(true);
  const [isBillingInfoCompleted, setIsBillingInfoCompleted] = useState<boolean>(false);
  const [isReceivingTicketInfoCompleted, setIsReceivingTicketInfoCompleted] =
    useState<boolean>(false);
  const [isValidCard, setIsValidCard] = useState<boolean>(false);
  const [isValidExpDate, setIsValidExpDate] = useState<boolean>(false);
  const [isAllCardsOpen, setIsAllCardsOpen] = useState<boolean>(false);
  const [isSelectedPressed, setIsSelectedPressed] = useState<boolean>(false);
  const [checkedPrimary, setCheckedPrimary] = useState<boolean>(false);
  const [cvv, setCvv] = useState<string>('');
  const [isNewCard, setNewCard] = useState<boolean>(false);
  const [wasChanged, setWasChanged] = useState<boolean>(false);
  const [disablePurchaseButton, setPurchaseButtonState] = useState<boolean>(false);
  const modalUUID = uuidv4();

  const [last4, setLast4] = useState(null);
  const [bsToken, setBsToken] = useState('');
  const [bs, setBS] = useState<Record<string, unknown>>({});

  const isMobile = useMobileView();
  const dispatch = useDispatch();
  const { secondaryColor, calloutsColor, primaryColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );
  const { cardInfo, billingInfo, receivingTicketInfo } = useSelector(
    ({ paymentInfo }: RootState) => paymentInfo,
  );

  const userId = localStorage.getItem('userId');
  const partnerId = localStorage.getItem('partnerId');

  const handleVoidPurchase = () => {
    setIsRejectedOpen(true);
    setIsConfirmationOpen(false);
    setPurchaseButtonState(false);
  };

  const { mutate: mutateVoid } = useMutation(voidPurchase, {
    onSuccess: handleVoidPurchase,
    onError: handleVoidPurchase,
  });

  const handleAfterPurchase = () => {
    setIsOpen(false);
    setIsRejectedOpen(true);
    setPurchaseButtonState(false);
  };

  const { mutate: mutatePurchaseNewCard, status: purchaseNewCardStatus } = useMutation(
    PurchaseNewCard,
    {
      onSuccess: ({ data }: any) => {
        setTransactionId(data?.transaction_code || '');
        setIsOpen(false);
        setIsConfirmationOpen(true);
        sendGAEvent({ category: 'Journeys', action: `Purchase partner - ${partnerId}` });
        if (fromCart)
          queryClient.setQueryData(['getCarts'], (oldData: any) => {
            oldData.events = [];
            return oldData;
          });
      },
    },
  );

  const { mutate: mutatePurchase, status: purchaseStatus } = useMutation(Purchase, {
    onSuccess: () => {
      setIsOpen(false);
      setIsConfirmationOpen(true);
      setIsChanged(true);
      sendGAEvent({ category: 'Purchase', action: `Purchase partner - ${partnerId}` });
      if (fromCart)
        queryClient.setQueryData(['getCarts'], (oldData: any) => {
          oldData.events = [];
          return oldData;
        });
    },
  });

  const PurchaseOnce = useMutateOnce<PurchaseProps>(mutatePurchase);
  const PurchaseNewCardOnce = useMutateOnce<PurchaseNewCardProps>(mutatePurchaseNewCard);

  const { mutate: mutateFreeTicket } = useMutation(PurchaseFreeTicket, {
    onSuccess: () => {
      setIsConfirmationOpen(true);
      setIsOpen(false);
      sendGAEvent({ category: 'Journeys', action: `Purchase free partner - ${partnerId}` });
      if (fromCart)
        queryClient.setQueryData(['getCarts'], (oldData: any) => {
          oldData.events = [];
          return oldData;
        });
    },
  });

  useEffect(() => {
    if (isOpen) sendGAEvent({ category: 'Purchase', action: `Purchase Modal` });
  }, [isOpen]);

  useEffect(() => {
    if (errorDescription)
      if (
        !errorDescription.includes('missing') &&
        !errorDescription.includes('card_number') &&
        !errorDescription.includes('expirationMonth') &&
        !errorDescription.includes('CVV')
      ) {
        setIsOpen(false);
        setIsRejectedOpen(true);
        setPurchaseButtonState(false);
      }
  }, [errorDescription]);

  useEffect(() => {
    if (billingInfo) {
      for (let i = 0; i < Object.values(billingInfo).length; i++) {
        if (i !== 1 && !Object.values(billingInfo)[i]) {
          setIsBillingInfoCompleted(false);
          break;
        } else if (Object.values(billingInfo).length - 1 === i) setIsBillingInfoCompleted(true);
      }
    }
  }, [billingInfo]);

  useEffect(() => {
    if (receivingTicketInfo) {
      for (let i = 0; i < Object.values(receivingTicketInfo).length; i++) {
        if (!Object.values(receivingTicketInfo)[i]) {
          setIsReceivingTicketInfoCompleted(false);
          break;
        } else if (Object.values(receivingTicketInfo).length - 1 === i)
          setIsReceivingTicketInfoCompleted(true);
      }
    }
  }, [receivingTicketInfo]);

  const handleClose = () => {
    setIsOpen(false);
    dispatch(reset());
    setPurchaseOnClick(false);
    // TODO - show cards when we have bluesnap cards stored -- setShowCards(true); --
    setTicketsWithPromocodes([]);
    fetchCart();
    sendGAEvent({ category: 'Purchase', action: `Close Modal` });
  };

  const mutateOrder = () => {
    return price === 0
      ? mutateFreeTicket({ userId, ticketTypeDic })
      : createPaymentNewCard({
          sessionToken: modalUUID,
          PurchaseNewCardOnce,
          tax: totalTaxes,
          taxes,
          price,
          setTransactionId,
          cardN,
          cardInfo,
          billingInfo,
          checkedPrimary,
          setErrorDescription,
          mutateVoid,
          handleAfterPurchase,
          tixologiFees,
          nuveiFees,
          ticketTypeDic,
          fromCart,
          serviceFee,
          fixedServiceFee,
          ticketsWithPromocodes,
          ourFees,
          totalDiscount: {
            amount: String(
              Object.values(ticketTypeDic).reduce(
                (acc: number, { discount_applied, number_of_applied_promocodes }: any) =>
                  acc + discount_applied * number_of_applied_promocodes,
                0,
              ),
            ),
            currency: 'USD',
          },
          setLast4,
          bs,
          last4,
          bsToken,
          purchaseError: handleVoidPurchase,
        });
  };

  const mutateOrderOnce = useMutateOnce<OpenOrderProps>(mutateOrder);

  const { mutate: mutateCheckAvailability, data: availabilityData } =
    useMutation(checkTicketsAvailability);

  useEffect(() => {
    if (availabilityData) {
      const ticketsUnavailables = getKeysWithFalseValues(availabilityData);
      if (ticketsUnavailables.length > 0) {
        const filteredTicketsData = filterObjectByKeys(ticketTypeDic, ticketsUnavailables);
        setUnavailableTickets(filteredTicketsData);
        ticketsUnavailables.forEach((ticketId) =>
          deleteFromCart({
            partnerId: localStorage.getItem('partnerId'),
            ticketTypeId: Number(ticketId),
          }),
        );
        openSoldOutModal();
      } else {
        mutateOrderOnce({
          amount: String(totalAmount),
          currency: 'USD',
          newCard: isNewCard,
          modalUUID,
        });
      }
    }
  }, [availabilityData]);

  useEffect(() => {
    if (wasChanged) {
      mutateCheckAvailability(ticketTypeDic);
    }
  }, [wasChanged]);

  const handleMutateOrder = (newCard: boolean) => {
    setNewCard(newCard);
    setWasChanged(true);
  };

  const handleNextButton = async () => {
    if (fromPurchase) {
      if (selected === 'RT' && isReceivingTicketInfoCompleted) {
        setSelected('BI');
        setIsReceivingTicketInfoCompleted(false);
      }
      if (selected === 'CI') {
        if (isCardInfoCompleted) {
          setPurchaseButtonState(true);
          handleMutateOrder(true);
        }
      } else if (isBillingInfoCompleted) {
        setSelected('CI');
      } else if (showCards && isAllCardsOpen) {
        setIsSelectedPressed(true);
      } else if (showCards && !isAllCardsOpen && cvv.length >= 3) {
        setPurchaseButtonState(true);
        handleMutateOrder(false);
      }
    } else if (selected === 'CI' && isCardInfoCompleted && isValidCard && isValidExpDate) {
      setSelected('BI');
      setIsCardInfoCompleted(false);
    } else if (isBillingInfoCompleted) {
      // create card without payment should be here
      setIsOpen(false);
    }
  };

  const handleClassName = () => {
    if (
      (isCardInfoCompleted && isValidCard && isValidExpDate) ||
      isBillingInfoCompleted ||
      isReceivingTicketInfoCompleted
    )
      return '';
    if (showCards && isAllCardsOpen) return '';
    if (showCards && !isAllCardsOpen && cvv.length >= 3) return '';
    return 'disabled';
  };

  return (
    <Modal open={isOpen} onClose={handleClose} sx={modalStyle}>
      <ModalContainer isMobile={isMobile} className={isMobile ? 'mobile' : ''}>
        <TitleContainer className={isMobile ? 'mobile' : ''}>
          <Row className="half-height">
            <Title>Payment Method</Title>
          </Row>
          <Row className="around half-height">
            {titles.map(({ title, code }) => {
              return (
                <Row
                  className="half-width center"
                  style={{
                    borderBottom:
                      selected === code || (selected === 'BI' && code !== 'RT' && showCards)
                        ? `5px #EF6B57 solid`
                        : 'none',
                  }}
                >
                  <Title
                    className="sub"
                    style={{
                      // TODO: Move to const or theme
                      color: selected === code ? '#EF6B57' : kinectAirColors.font,
                    }}
                  >
                    {title}
                  </Title>
                </Row>
              );
            })}
          </Row>
        </TitleContainer>

        {selected === 'BI' ? (
          showCards ? (
            <PurchaseWithCard
              primaryCard={cards?.filter((card: Card) => card.is_primary)[0]}
              cards={cards}
              isAllCardsOpen={isAllCardsOpen}
              setIsAllCardsOpen={setIsAllCardsOpen}
              isSelectedPressed={isSelectedPressed}
              setIsSelectedPressed={setIsSelectedPressed}
              setCvv={setCvv}
              cvv={cvv}
            />
          ) : (
            <BillingDetails
              setInputData={setBillingInfo}
              inputData={billingInfo}
              receivingTicketInfoData={receivingTicketInfo}
            />
          )
        ) : selected === 'CI' ? (
          <>
            <CreditCardDetails
              setBsToken={setBsToken}
              setBS={setBS}
              setPurchaseButtonState={setPurchaseButtonState}
            />
          </>
        ) : (
          <RecevingTickets setInputData={setReceivingTicketInfo} inputData={receivingTicketInfo} />
        )}
        <ButtonContainer>
          <Row className={`center ${isMobile ? 'h25' : 'h15'}`} style={{ gap: '16px' }}>
            <Button
              className={`${isMobile ? 'full' : ''} outlined`}
              color={kinectAirColors.callout}
              onClick={() => {
                if (isAllCardsOpen) setIsAllCardsOpen(false);
                else handleClose();
              }}
              label={isAllCardsOpen ? 'Back' : 'Cancel'}
            />

            {selected === 'BI' && showCards && !isAllCardsOpen && (
              <Button
                // color={primaryFontColor}
                // className="cancel"
                color={kinectAirColors.callout}
                className={`${isMobile ? 'full' : ''} font-weight-small`}
                onClick={() => setShowCards(false)}
                label="Add New Credit Card"
              />
            )}
            <Button
              disabled={disablePurchaseButton}
              className={`${isMobile ? 'full' : ''} font-weight-small`}
              color={kinectAirColors.callout}
              onClick={handleNextButton}
              label={
                selected === 'CI' && !isLoading ? (
                  'Purchase'
                ) : (selected === 'CI' &&
                    (isLoading ||
                      purchaseStatus === 'loading' ||
                      purchaseNewCardStatus === 'loading')) ||
                  (selected === 'BI' &&
                    (isLoading ||
                      purchaseStatus === 'loading' ||
                      purchaseNewCardStatus === 'loading')) ? (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    // TODO: Move to const or theme
                    color="#FFFFFF"
                    ariaLabel="three-dots-loading"
                    visible
                  />
                ) : isAllCardsOpen ? (
                  'Select Payment Method'
                ) : selected === 'BI' && showCards ? (
                  'Purchase'
                ) : (
                  'Next'
                )
              }
            />
          </Row>
          <Icon>
            <Img src={poweredTixologi} />
          </Icon>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default CreditCardModal;
