import React, { Fragment } from 'react';
import moment from 'moment';

import { ticketTypeBlack } from 'Assets/Icons';
import { convertUTCToDate } from 'Utils/utils';

import * as Styled from './Template1.styles';

export const Template1 = ({ event, ticket, className }: any) => (
  <Styled.TemplateContainer className={className}>
    <Styled.Image src={ticket?.image} />
    <Styled.InfoContainer>
      <Styled.MainInfoContainer>
        <Styled.Title>{event?.name}</Styled.Title>
      </Styled.MainInfoContainer>
      {event.days.map(({ start_date, end_date }: any, index: number) => {
        const isDepartureFlight = index === 0;
        return (
          <Fragment key={start_date}>
            <Styled.InfoRow>
              <Styled.InfoItem>
                <Styled.InfoTitle>Flight {index + 1}</Styled.InfoTitle>
                <Styled.InfoText>
                  {moment(
                    convertUTCToDate(
                      start_date,
                      isDepartureFlight ? event?.time_zone : event?.secondary_time_zone,
                    ),
                  )?.format('MM.DD.YYYY')}
                </Styled.InfoText>
              </Styled.InfoItem>
              <Styled.InfoItem>
                <Styled.InfoTitle>Departure Time</Styled.InfoTitle>
                <Styled.InfoText>
                  {moment(
                    convertUTCToDate(
                      start_date,
                      isDepartureFlight ? event?.time_zone : event?.secondary_time_zone,
                    ),
                  )?.format('hh:mm a')}{' '}
                  -{' '}
                  {moment(
                    convertUTCToDate(
                      end_date,
                      isDepartureFlight ? event?.secondary_time_zone : event?.time_zone,
                    ),
                  )?.format('hh:mm a')}
                </Styled.InfoText>
              </Styled.InfoItem>
            </Styled.InfoRow>
            <Styled.Divider />
          </Fragment>
        );
      })}
      <Styled.InfoRow>
        <Styled.InfoItem>
          <Styled.InfoTitle>Order</Styled.InfoTitle>
          <Styled.InfoText>{ticket?.order || '#'}</Styled.InfoText>
        </Styled.InfoItem>
      </Styled.InfoRow>
      <Styled.TicketType>
        <Styled.Ticket src={ticketTypeBlack} />
        <Styled.TicketTypeText>KinectAir Ticket</Styled.TicketTypeText>
      </Styled.TicketType>
    </Styled.InfoContainer>
    <Styled.BottomLine />
  </Styled.TemplateContainer>
);
