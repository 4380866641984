import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CheckBox = styled.div<any>`
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid ${(props) => props.color};
  padding: 2px;
`;

export const CheckBoxIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const Label = styled.label`
  font-size: 16px;
`;
