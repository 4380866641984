import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
// import { ROUTES } from 'Utils/constants';
// import moment from 'moment';
import useMobileView from 'hooks/useMobileView';
// import { convertUTCToDate } from 'Utils/utils';
import { TicketType } from './TicketType';

import { Row, TicketCard, Text, ContainerTickets } from './styles';

interface TicketProps {
  // myListings?: boolean;
  // pastTicket?: boolean;
  // id?: number;
  quantity: number;
  item?: any;
  displayType?: string;
  template?: { event?: any; ticket?: any };
}

const Ticket = ({
  // myListings,
  // pastTicket,
  // id,
  quantity,
  item,
  displayType,
  template,
}: TicketProps) => {
  // const history = useHistory();
  const isMobile = useMobileView();
  const moreTickets = quantity > 1;
  const { primaryFontColor, secondaryFontColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );
  const handleClick = () => {
    // if (myListings)
    //   history.push(`${ROUTES.TRANSACTIONS}/${id}`, {
    //     state: {
    //       item,
    //       template,
    //     },
    //   });
    // else
    //   history.push(pastTicket ? `${ROUTES.PAST_EVENTS}/${id}` : `${ROUTES.MY_TICKETS}/${id}`, {
    //     pastEvent: pastTicket,
    //   });
  };

  return (
    <TicketCard color={primaryFontColor} isMobile={isMobile} onClick={handleClick}>
      {/* {myListings ? (
        <>
          <Text color={secondaryFontColor} className="title">
            {item.event_name}
          </Text>
          <Text color={secondaryFontColor}>
            {item.venue}{' '}
            {moment(convertUTCToDate(item.event_start_time, item.time_zone)).format('MM-DD-YYYY')}
          </Text>
          <Row isMobile={isMobile} className={`relative ${moreTickets ? 'multiple' : ''}`}>
            <Row>
              <TicketType
                event={template?.event}
                ticket={template?.ticket}
                moreTickets={moreTickets}
                isMobile={isMobile}
                displayType={displayType}
              />
              {moreTickets && (
                <TicketType
                  event={template?.event}
                  ticket={template?.ticket}
                  moreTickets={moreTickets}
                  isMobile={isMobile}
                  displayType={displayType}
                  className="absolute"
                />
              )}
            </Row>
          </Row>
          <ContainerTickets>
            <Text className="container">
              Transferred
              <Text className="bold">
                {item?.transferred !== null ? item?.transferred?.length : '0'}
              </Text>
            </Text>
            <Text className="container">
              For Transfer
              <Text className="bold">
                {item?.for_transfer !== null ? item?.for_transfer?.length : '0'}
              </Text>
            </Text>
          </ContainerTickets>
          <ContainerTickets>
            <Text className="container">
              For Sale
              <Text className="bold">{item?.for_sale !== null ? item?.for_sale?.length : '0'}</Text>
            </Text>
            <Text className="container">
              Sold <Text className="bold">{item?.sold !== null ? item?.sold?.length : '0'}</Text>
            </Text>
          </ContainerTickets>
        </>
      ) : ( */}
      <>
        <Text color={primaryFontColor} className="title">
          {item.event_name}
        </Text>
        {/* <Text color={secondaryFontColor}>{item.venue}</Text> */}
        {/* <Text className="container marginTop">
          <Text>
            {' '}
            {moment(convertUTCToDate(item.event_start_time, item.time_zone)).format('MM/DD/YYYY')}
          </Text>{' '}
        </Text> */}
        <Row isMobile={isMobile} className={`relative ${moreTickets ? 'multiple' : ''}`}>
          <Row>
            <TicketType
              event={template?.event}
              ticket={template?.ticket}
              moreTickets={moreTickets}
              isMobile={isMobile}
              displayType={displayType}
            />
            {/* {moreTickets && (
              <TicketType
                event={template?.event}
                ticket={template?.ticket}
                moreTickets={moreTickets}
                isMobile={isMobile}
                displayType={displayType}
                className="absolute"
              />
            )} */}
          </Row>
        </Row>
        <Text className="container marginBottom">
          Tickets <Text className="bold">{quantity}</Text>
        </Text>
      </>
      {/* )} */}
    </TicketCard>
  );
};

export default Ticket;
