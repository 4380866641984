import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { Button, Input } from 'Components/Common';
import { SEND_EMAIL, FORGOT_PASSWORD_INFO } from 'Utils/constants';
import { reset } from 'Slices/userSlice';
import { post } from 'api/api';
import useMobileView from 'hooks/useMobileView';
import { toggleAuthModalType } from 'Slices/authModal';
import {
  RESET_PASSWORD_INITIAL_VALUES,
  ResetPasswordSchema,
  resetPasswordInputData,
} from './utils/formData';
import EmailSentModal from './EmailSentModal';

import { FormContainer, ButtonContainer, FormTitle, Space } from './styles';

export default function ForgotPassword() {
  const isAuthenticated = localStorage.getItem('accessToken');
  const partnerId = localStorage.getItem('partnerId') || '0';
  const dispatch = useDispatch();
  const [isSuccess, setSuccess] = useState(false);
  const isMobile = useMobileView();

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: RESET_PASSWORD_INITIAL_VALUES,
    validationSchema: ResetPasswordSchema,
    onSubmit: () => {
      post('/public/users/forgot-password', {
        email: values.email,
        origin: 'widget',
        partner: parseInt(partnerId, 10),
      })
        .then(() => {
          setSuccess(true);
          dispatch(toggleAuthModalType({ type: 'login', back: 'register' }));
        })
        .catch();
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !isAuthenticated && dispatch(reset());
  }, [dispatch, isAuthenticated]);

  const typedErrors = errors as { [key: string]: string };
  const typedValues = values as { [key: string]: string };
  const typedTouched = touched as { [key: string]: boolean };

  return isSuccess ? (
    <EmailSentModal />
  ) : (
    <>
      <Space className={isMobile ? 'mobile' : ''}>
        <FormTitle>{FORGOT_PASSWORD_INFO}</FormTitle>
        <FormContainer className="forgot-pw-modal">
          {resetPasswordInputData.map(({ label, name }) => (
            <Input
              key={name}
              value={typedValues[name]}
              onChange={
                handleChange(name) as unknown as (e: string | React.ChangeEvent<unknown>) => void
              }
              type="text"
              error={typedErrors[name]}
              touched={typedTouched[name]}
              label={label}
            />
          ))}
        </FormContainer>
      </Space>
      <ButtonContainer>
        <Button
          label={SEND_EMAIL}
          className="full"
          disabled={false}
          onClick={handleSubmit}
          color="#EF6B57"
        />
      </ButtonContainer>
    </>
  );
}
