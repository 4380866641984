import React from 'react';
import { useDispatch } from 'react-redux';

import { PaymentOption } from 'Components/PaymentOption';
import usePlaidHook from 'Components/TicketPurchase/usePlaidHook/usePlaidHook';
import { ACHPayment, CreditCard } from 'Assets/Icons';
import { setShowPaymentOptions } from 'Slices/paymentInfoSlice';

import { PaymentContainer, PaymentText } from './styles';

export const PaymentOptions = () => {
  const dispatch = useDispatch();
  // PLAID
  const { open, ready } = usePlaidHook();
  const PAYMENT_OPTIONS = [
    {
      title: 'Credit Card',
      description: 'Complete this purchase via credit card',
      feeInfo: '2.94% transaction fee',
      icon: CreditCard,
      onClick: () => {
        dispatch(setShowPaymentOptions(false));
      },
    },
    {
      title: 'ACH Payment',
      description: 'Complete this purchase through a bank account. Secured by Plaid',
      feeInfo: '$35 transfer fee. Usually less expensive than CC',
      icon: ACHPayment,
      onClick: () => {
        if (ready) open();
      },
    },
  ];
  return (
    <div>
      {' '}
      <PaymentContainer>
        <PaymentText>Please select a payment method</PaymentText>
        {PAYMENT_OPTIONS.map((option) => (
          <PaymentOption {...option} key={option.title} />
        ))}
      </PaymentContainer>
    </div>
  );
};
