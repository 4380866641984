import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #444444;
  border: 0.72973px solid #000000;
  border-radius: 50px;
  position: absolute;
  top: -3px;
  right: -5px;
  width: 18px;
  height: 18px;
`;

export const Container = styled.div<{ isUpperHeader?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  background: #ffffff;
  border-radius: 48px;
  position: absolute;
  right: 30px;
  top: ${(props) => (props.isUpperHeader ? '30px' : '25px')};
  cursor: pointer;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  display: flex;
  letter-spacing: -0.02em;
  color: ${palette.white};
`;

export const CartIcon = styled.img`
  width: 17px;
`;
