import styled from 'styled-components';

import { blackOpacity, checkBoxOrange, kinectAirColors, palette } from 'Utils/theme';

export const Wrapper = styled.div<{ isMobile: boolean; isAllCardsOpen: boolean }>`
  padding: 40px
    ${(props) =>
      props.isMobile && !props.isAllCardsOpen
        ? '40px'
        : props.isMobile && props.isAllCardsOpen
        ? '10px'
        : 'min(10%, 180px)'};
  height: ${(props) => (props.isMobile ? 'max(fit-content, 60%)' : '64%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  &.overflow {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${kinectAirColors.font};
  opacity: 0.7;
  margin-bottom: 10px;
`;

export const Row = styled.div<{ checked?: boolean }>`
  display: flex;

  &.primary {
    flex-wrap: wrap;
    justify-content: center;
  }

  &.checkbox-container {
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 40px 10px 10px;
    ${(props) =>
      props.checked && {
        background: 'rgba(220, 114, 67, 0.1)',
        border: `1px solid ${checkBoxOrange}`,
        borderRadius: 16,
      }}
  }
  &.pointer {
    cursor: pointer;
  }
  &.selected {
    padding: 10px 40px 10px 10px;
  }
`;

export const CheckBox = styled.div<{ checked: boolean }>`
  border: 3px solid ${(props) => (props.checked ? checkBoxOrange : palette.gray[9])};
  border-radius: 50px;
  width: 25px;
  height: 25px;
  position: relative;
  margin-right: 10px;
  &.pointer {
    cursor: pointer;
  }
`;

export const Filled = styled.div`
  background: ${checkBoxOrange};
  border-radius: 50px;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const Img = styled.img``;

export const Button = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 150px;
  padding: 10px;
  color: ${palette.white};
  background-color: ${(props) => props.color || palette.gray[70]};
  border-radius: 8px;
  cursor: pointer;
  &.anotherCard {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: ${palette.gray[35]};
    background: ${palette.gray[3]};
    width: fit-content;
    margin-bottom: 10px;
    padding: 5px 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 10px;
  justify-content: center;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${kinectAirColors.font};
`;

export const StyledInput = styled.input`
  all: unset;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${palette.black};
  padding: 8px;
  cursor: auto;
  background: ${palette.gray[30]};
  border-radius: 8px;
  border: 1px solid ${blackOpacity};
  width: 100px;
`;
