import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';

import { creditCard } from 'Assets/Images';
import { CardInfoCompleted, BillingInfo, ReceivingTicketInfo, PromoCodeInfo } from 'Utils/types';
import { setCardInfo } from 'Slices/paymentInfoSlice';
import { RootState } from 'store';
import {
  Container,
  Label,
  StyledInput,
  Row,
  Img,
  styles,
  StyledInputMask,
  customSelectStyles,
} from './styles';

interface Props {
  label: string;
  property: string;
  placeholder: string;
  isCreditCard?: boolean;
  isPhoneNumber?: boolean;
  isCVV?: boolean;
  isExpiration?: boolean;
  isSelect?: boolean;
  isEmail?: boolean;
  setOptions?: React.Dispatch<any>;
  options?: any;
  setInputData:
    | React.Dispatch<React.SetStateAction<CardInfoCompleted>>
    | React.Dispatch<React.SetStateAction<BillingInfo>>
    | React.Dispatch<React.SetStateAction<ReceivingTicketInfo>>
    | React.Dispatch<React.SetStateAction<PromoCodeInfo>>;
  inputData: any;
  setIsValidCard?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValidExpDate?: React.Dispatch<React.SetStateAction<boolean>>;
  error?: boolean;
}

const Input = ({
  label,
  property,
  placeholder,
  isCreditCard,
  isPhoneNumber,
  isCVV,
  isExpiration,
  isSelect,
  setOptions = () => {},
  options,
  setInputData,
  inputData,
  isEmail,
  setIsValidCard = () => false,
  setIsValidExpDate = () => false,
  error,
}: Props) => {
  const dispatch = useDispatch();
  const valid = require('card-validator');
  const { cardInfo } = useSelector(({ paymentInfo }: RootState) => paymentInfo);

  useEffect(() => {
    if (isCreditCard) {
      setIsValidCard(valid.number(inputData[property]).isValid);
      dispatch(setCardInfo({ ...cardInfo, type: valid.number(inputData[property]) }));
    }
    if (isExpiration) setIsValidExpDate(valid.expirationDate(inputData[property]).isValid);
  }, [inputData[property]]);

  const updatedCountries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));
  const updatedStates = (countryId: any) =>
    State.getStatesOfCountry(countryId).map((state) => {
      return {
        label: state.name,
        value: state.isoCode,
        ...state,
      };
    });
  const updatedCities = (countryId: any, stateId: any) =>
    City.getCitiesOfState(countryId, stateId).map((city) => ({
      label: city.name,
      value: city.name,
      ...city,
    }));

  const handleCardDisplay = () => {
    const rawText = [...(inputData[property].split(' ').join('') as any)]; // Remove old space
    const creditCard: any = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0) creditCard.push(' '); // Add space
      creditCard.push(t);
    });
    return creditCard.join(''); // Transform card array to string
  };

  const renderInput = () => {
    if (isCreditCard)
      return (
        <Row
          className={`borderBottom space ${
            !valid.number(inputData[property]).isValid && inputData[property].length > 0
              ? 'error'
              : ''
          }`}
        >
          <StyledInput
            value={handleCardDisplay()}
            onChange={(e) => dispatch(setInputData({ ...inputData, [property]: e.target.value }))}
            placeholder={placeholder}
            isCreditCard={isCreditCard}
            maxLength={20}
          />
          <Img src={creditCard} />
        </Row>
      );
    if (isPhoneNumber)
      return (
        <PhoneInput
          country="us"
          inputStyle={{ ...styles.input, opacity: inputData[property] === '' ? 0.5 : 1 }}
          containerStyle={styles.container}
          buttonStyle={styles.button}
          value={inputData[property]}
          onChange={(value) => dispatch(setInputData({ ...inputData, [property]: value }))}
        />
      );
    if (isExpiration)
      return (
        <StyledInputMask
          mask="99/9999"
          placeholder={placeholder}
          value={inputData[property]}
          onChange={(e: any) =>
            dispatch(setInputData({ ...inputData, [property]: e.target.value }))
          }
          disableUnderline
          maskChar=" "
          error={
            !valid.expirationDate(inputData[property]).isValid && inputData[property].length > 0
          }
        />
      );
    if (isSelect) {
      if (label === 'country') {
        return (
          <Select
            id={label}
            name={label}
            options={updatedCountries}
            value={options.country}
            onChange={(value) => {
              setOptions({ country: value, state: null, city: null });
            }}
            styles={customSelectStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Select Country"
          />
        );
      }
      if (label === 'city') {
        return (
          <Select
            id={label}
            name={label}
            options={
              options.state
                ? updatedCities(options.country.value, options.state.value)
                : updatedCities(null, null)
            }
            value={options.city}
            onChange={(value) => {
              setOptions({ ...options, city: value });
            }}
            styles={customSelectStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Select City"
          />
        );
      }
      return (
        <Select
          id={label}
          name={label}
          options={updatedStates(options.country ? options.country.value : null)}
          value={options.state}
          onChange={(value) => {
            setOptions({ ...options, state: value, city: null });
          }}
          styles={customSelectStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
          placeholder="Select State or District"
        />
      );
    }
    if (isEmail) {
      return (
        <StyledInput
          value={inputData[property]}
          placeholder={placeholder}
          onChange={(e) => dispatch(setInputData({ ...inputData, [property]: e.target.value }))}
        />
      );
    }
    return (
      <StyledInput
        className={error ? 'error' : ''}
        value={inputData[property]}
        onChange={(e) => dispatch(setInputData({ ...inputData, [property]: e.target.value }))}
        placeholder={placeholder}
        maxLength={isCVV ? 3 : undefined}
      />
    );
  };

  return (
    <Container>
      <Label>{label}</Label>
      {renderInput()}
    </Container>
  );
};

export default Input;
