import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';

import useMobileView from 'hooks/useMobileView';
import { Button } from 'Components/Common';
import { RootState } from 'store';
import { RejectedModal } from 'Components/Common/ConfirmationModal/RejectedModal';
import ConfirmationModal from 'Components/Common/ConfirmationModal';
import { purchaseConfirmation } from 'Assets/Images';
import { CreateAchTransfer, PurchaseWithBank } from './services';
import { bankInfo } from './utils';
import { PurchaseBankConnectionProps } from './types';

import { PurchaseContainer, Row, Label, Column } from './styles';

export const PurchaseBankConnection = ({
  eventInfo,
  totalAmount,
  totalDiscount,
  ourFees,
  tixologiFees,
  tax,
  taxes,
  ticketTypeDic,
  ticketsWithPromocodes,
  price,
  serviceFee,
  fixedServiceFee,
  nuveiFees,
}: PurchaseBankConnectionProps) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isRejectedOpen, setIsRejectedOpen] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [errorDescription, setErrorDescription] = useState<string>('');
  const isMobile = useMobileView();
  const { plaidMetadata, billingInfo, plaidPublicToken } = useSelector(
    ({ paymentInfo }: RootState) => paymentInfo,
  );
  const { user }: any = useSelector(({ user }: RootState) => user);
  const userId = localStorage.getItem('userId');
  const partnerId = localStorage.getItem('partnerId');

  const { mutate: mutatePurchase } = useMutation(PurchaseWithBank, {
    onSuccess: ({ data }) => {
      setTransactionId(`${eventInfo.ticket_types[0].name}${data.ID}`);
      setIsConfirmationOpen(true);
    },
    onError: () => setIsRejectedOpen(true), // TODO: add void method before being in production
  });

  const { mutate: mutateTranfer } = useMutation(CreateAchTransfer, {
    onSuccess: ({ data }) => {
      mutatePurchase({
        sessionTokenId: data,
        userId,
        tax,
        price,
        partnerId: String(partnerId),
        tixologiFees,
        ticketTypeDic,
        ticketsWithPromocodes,
        ourFees,
        totalDiscount,
        taxes,
        billingDetails: {
          name: user.displayName,
          city: billingInfo.city,
          country: billingInfo.country,
          district: billingInfo.state,
          line1: billingInfo.addressLine1,
          line2: billingInfo.addressLine2,
          postalCode: billingInfo.postalCode,
        },
        serviceFee,
        fixedServiceFee,
        nuveiFees,
      });
    },
    onError: () => setIsRejectedOpen(true),
  });

  const handlePurchase = () =>
    mutateTranfer({
      name: user.displayName,
      amount: String(totalAmount),
      publicToken: plaidPublicToken,
      account: String(plaidMetadata.accounts[0]?.id),
      partnerId: String(partnerId),
    });

  return (
    <PurchaseContainer className={isMobile ? 'isMobile' : ''} isBankConnected>
      <Column className="bankInfo">
        <Column>
          <Row>
            <Label className="order-total">Order total</Label>
            <Label className="order-total">${totalAmount}USD</Label>
          </Row>
          <Row className="border light" />
          {!!plaidMetadata.accounts.length &&
            bankInfo(plaidMetadata).map(({ name, description }) => (
              <Row>
                <Label className="bankInfo">{name}</Label>
                <Label className="bankInfo">{description}</Label>
              </Row>
            ))}
        </Column>
        <Column className="gap">
          <Label className="bankInfo">
            By selecting “Confirm purchase” you authorize [Tixologi] to make a one time ACH debit
            from the listed account. Contact KinectAir Directly if you would like to revoke this
            charge
          </Label>
          <Button
            label="Confirm Purchase"
            className="font-weight-small"
            color="#EF6B57"
            onClick={handlePurchase}
          />
        </Column>
      </Column>
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          title={price === 0 ? 'Free Ticket Complete' : 'Purchase Complete'}
          image={purchaseConfirmation}
          orderNumber={transactionId}
          span={`Your ${
            price === 0 ? 'free ticket' : 'purchase'
          } is complete. Expect an email with your ticket 
          information in the next 10mins.`}
          closeButton
          fromPurchase={price !== 0}
        />
      )}
      {isRejectedOpen && (
        <RejectedModal
          title="Payment not complete"
          isOpen={isRejectedOpen}
          setIsOpen={setIsRejectedOpen}
          errorDescription={errorDescription}
          setErrorDescription={setErrorDescription}
        />
      )}
    </PurchaseContainer>
  );
};
