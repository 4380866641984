import moment from 'moment';
import { NAME_EVENT, COMPANY, QUANTITY, DATE_EVENT, QUANTITY_OF_TICKETS } from 'Utils/constants';
import { convertUTCToDate } from 'Utils/utils';

export const ticketSummary = (
  event_name: string,
  date: any,
  total_price: number,
  qty: number,
  partner_name: string,
  timeZone: string,
) => [
  {
    label: NAME_EVENT,
    value: event_name,
    className: 'bold large',
  },
  {
    label: COMPANY,
    value: partner_name,
    className: 'bold large',
  },
  {
    label: QUANTITY,
    value: qty,
    className: 'xlarge',
  },

  {
    label: DATE_EVENT,
    value: moment(convertUTCToDate(date, timeZone)).format('MM/DD/YYYY'),
    className: 'xlarge',
  },
];

// TODO: when backend is ready
export const ticketTransactionForSale = (
  paymentProcessingFeeForSale?: number,
  profitForSale?: number,
  quantityOfTicketsForSale?: number,
  sellingPriceForSale?: number,
  serviceFeeForSale?: number,
) => [
  {
    label: QUANTITY_OF_TICKETS,
    classNameLabel: 'large',
    value: quantityOfTicketsForSale,
    className: 'xlarge',
  },
  // {
  //   label: 'Selling price',
  //   classNameLabel: 'large',
  //   value: sellingPriceForSale,
  //   className: 'xlarge',
  // },
  // {
  //   label: 'Payment processing fee (1.29%)',
  //   classNameLabel: 'large',
  //   value: paymentProcessingFeeForSale,
  //   className: 'xlarge',
  // },
  // {
  //   label: 'Service Fee (.02%)',
  //   classNameLabel: 'large',
  //   value: serviceFeeForSale,
  //   className: 'xlarge',
  // },
  // {
  //   label: 'Your Profit',
  //   classNameLabel: 'large bold',
  //   value: profitForSale,
  //   className: 'xlarge',
  // },
];

export const ticketTransactionSold = (
  paymentProcessingFeeSold?: number,
  profitSold?: number,
  quantityOfTicketsSold?: number,
  sellingPriceSold?: number,
  serviceFeeSold?: number,
) => [
  {
    label: QUANTITY_OF_TICKETS,
    classNameLabel: 'large',
    value: quantityOfTicketsSold,
    className: 'xlarge',
  },
  {
    label: 'Selling price',
    classNameLabel: 'large',
    value: sellingPriceSold,
    className: 'xlarge',
  },
  {
    label: 'Payment processing fee (1.29%)',
    classNameLabel: 'large',
    value: paymentProcessingFeeSold,
    className: 'xlarge',
  },
  {
    label: 'Service Fee (.02%)',
    classNameLabel: 'large',
    value: serviceFeeSold,
    className: 'xlarge',
  },
  {
    label: 'Your Profit',
    classNameLabel: 'large bold',
    value: profitSold,
    className: 'xlarge',
  },
];

export const ticketTransfer = (quantityOfTicketsSold?: number) => [
  {
    label: QUANTITY_OF_TICKETS,
    classNameLabel: 'large',
    value: quantityOfTicketsSold,
    className: 'xlarge',
  },
];
