import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authModal from 'Slices/authModal';
import user from 'Slices/userSlice';
import widgetData from 'Slices/widgetInfoSlice';
import paymentInfo from 'Slices/paymentInfoSlice';
import ticketsSlice from 'Slices/ticketsSlice';

const store = configureStore({
  reducer: {
    authModal,
    user,
    widgetData,
    paymentInfo,
    ticketsSlice,
  },
});

export { store };
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
