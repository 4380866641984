export const palette = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    0: '#D7D7D7',
    3: '#D9D9D9',
    5: '#C0C0C0',
    7: '#8F8F8F',
    9: '#808080',
    10: '#E7E7E7',
    11: '#e4e4e4',
    12: '#4e4e4e',
    15: '#F0F0F0',
    20: '#F2F2F2',
    25: '#F3F3F3',
    30: '#F4F4F4',
    35: '#474747',
    40: '#393939',
    45: '#DADADA',
    50: '#F5F5F5',
    60: '#7A7A7A',
    65: '#828282',
    70: '#848484',
    75: '#B5B5B5',
    80: '#636b78',
    83: '#919191',
    85: '#b4b4b4',
    90: '#999999',
    95: '#292929',
    100: '#BBBBBB',
    150: '#0E0E0E',
  },
  pink: '#C47551',
  orange: '#B86961',
  blue: '#2F80ED',
  error: '#F3333E',
  green: '#59CE63',
};

// TODO: replace with widget color customization when 2.0 is launched
export const kinectAirColors = {
  background: '#082B54',
  background2: '#17457D',
  callout: '#EF6B57',
  font: '#FFFFFF',
};

export const color1 = '#A75873';

export const color2 = '#C8794C';

export const shadow = '0px 4px 14px rgba(0, 0, 0, 0.2)';

export const gradient = `linear-gradient(96.2deg, ${color1} 0.99%, ${color2} 96.46%)`;
export const verticalGreyGradient =
  'linear-gradient(180deg, #121212 61.98%, rgba(18, 18, 18, 0) 100%)';

export const greenBlue2 = '#6DE0A2';

export const shadowW = 'rgba(0, 0, 0, 0.25)';

export const shadowContainer = 'rgba(221, 221, 221, 0.35)';

export const border = 'rgba(0, 0, 0, 0.2)';

export const blackOpacity = 'rgba(0, 0, 0, 0.5)';
export const checkBoxOrange = '#DC7243';
