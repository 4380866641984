import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Column = styled.div`
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0;
  position: relative;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${palette.error};
  width: 100%;
  height: 12px;
  padding: 5px 10px;
`;

export const IconContainer = styled.div`
  position: absolute;
  bottom: 30%;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconButton = styled.button`
  all: unset;
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

export const SInput = styled.input`
  height: 45px;
  width: 100%;
  background-color: ${palette.gray[30]};
  border-radius: 8px;
  border: 1px solid ${palette.gray[100]};
  padding: 0px 15px;
  outline: none;
  font-size: 14px;

  &.white {
    background-color: ${palette.white};
    border-color: ${palette.white};
  }

  &.medium {
    width: 45%;
  }

  &.placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &.error {
    border: 1px solid ${palette.error};
  }

  &.mobile {
    width: 100%;
  }
`;

export const InputLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
`;
