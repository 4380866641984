import {
  CITY,
  CONFIRM_NEW_PASSWORD,
  EMAIL,
  FEMALE,
  GENDER,
  MALE,
  FULL_NAME,
  NEW_PASSWORD,
  OTHER,
  PASSWORD,
  SECONDARY_ADDRESS,
  STATE_PROVINCE,
  STREET_ADDRESS,
  ZIP_POSTAL_CODE,
  ADDRESS,
  PAYMENT_METHOD,
} from 'Utils/constants';

export const labels = [
  {
    label: FULL_NAME,
    value: ['full_name'],
    inputs: [{ label: FULL_NAME, value: 'full_name' }],
  },
  {
    label: EMAIL,
    value: ['email'],
    inputs: [{ label: EMAIL, value: 'email' }],
  },
  {
    label: PASSWORD,
    value: ['password'],
    inputs: [
      { label: NEW_PASSWORD, value: 'newPassword' },
      { label: CONFIRM_NEW_PASSWORD, value: 'confirmNewPassword' },
    ],
  },
  {
    label: GENDER,
    value: ['gender'],
    inputs: [{ label: GENDER, value: 'gender', options: [MALE, FEMALE, OTHER] }],
  },
  {
    label: ADDRESS,
    value: ['street_address', 'secondary_address', 'city'],
    inputs: [
      { label: STREET_ADDRESS, value: 'street_address' },
      { label: SECONDARY_ADDRESS, value: 'secondary_address' },
      { label: CITY, value: 'city' },
      { label: STATE_PROVINCE, value: 'state_province' },
      { label: ZIP_POSTAL_CODE, value: 'postal_code' },
    ],
  },
  {
    label: PAYMENT_METHOD,
    value: [],
    inputs: [],
  },
];
