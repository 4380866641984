import styled from 'styled-components';

import { kinectAirColors, shadowContainer } from 'Utils/theme';

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  padding: 0px ${(props) => (props.isMobile ? '40px' : 'min(15%, 180px)')};
  height: ${(props) => (props.isMobile ? 'max(fit-content, 60%)' : '64%')};
  box-shadow: -12px -20px 25px ${shadowContainer};
  border-radius: 0 0 16px 16px;
  overflow-y: scroll;
`;

export const MaxWidthWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 400px;
  padding: 40px 0px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  &.margin {
    margin-bottom: 10px;
  }
  &.space {
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  color: ${kinectAirColors.font};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Img = styled.img`
  width: 20px;
  cursor: pointer;
`;
