export const ACCOUNT = 'Account';
export const ACCOUNT_NUMBER = 'Account number';
export const ADD_ANOTHER_CONTACT = 'Add another contact';
export const ALERT_ME_TRANSFER = 'Alert me when ticket is transferred.';
export const ALL_EVENTS = 'All Events';
export const ADDRESS = 'Address';
export const ADDED_TO_CART = 'Added to Cart';

export const BANK_INFO = 'Bank Info';
export const BILLING_ADDRESS = 'Billing address';

export const CANCEL = 'Cancel';
export const CANCEL_TRANSACTION = 'Are you sure you want to cancel the transaction?';
export const CHANGE_YOUR_PASSWORD = 'Enter your new password.';
export const CITY = 'City';
export const COMPANY = 'Company';
export const CONFIRM_NEW_PASSWORD = 'Confirm new password';
export const CONTACT = 'Contact';
export const CONTINUE = 'Continue';
export const CONTACT_EMAIL = 'Contact Email';
export const COMPLETED_TRANSACTIONS = 'Completed Transactions';
export const CREATE_ACCOUNT = 'Create account';
export const CURRENT_PRICE = 'Current projected selling price';

export const KEEP_SHOPPING = 'Keep Shopping';
export const DATE_BOUGHT = 'Date bought';
export const DATE_EVENT = 'Date of event';
export const DATE_OF_BIRTH = 'Date of birth';
export const DISMISS = 'Dismiss';
export const PASSWORDS_DOESNT_MATCH = "Passwords doesn't match";

export const EDIT = 'Edit';
export const EMAIL = 'Email';
export const EMAIL_IS_INVALID = 'Email is invalid';
export const EMAIL_IS_REQUIRED = 'Email is required';

export const FEMALE = 'Female';
export const FIRST_NAME = 'First name';
export const FULL_NAME = 'Full name';
export const FORGOT = 'Forgot username or password';
export const FORGOT_PASSWORD = 'Forgot password?';
export const FORGOT_PASSWORD_INFO =
  'We’ll rather send you an email to reset your password. \nPlease enter the email associated with your account.';

export const GENDER = 'Gender';

export const MALE = 'Male';

export const NAME = 'Name';
export const NAME_IS_REQUIRED = 'First name is required';
export const NAME_EVENT = 'Name event';
export const NEW_PASSWORD = 'New password';
export const NEW_PASSWORD_CONFIRMATION = 'New password confirmation';
export const PASSWORD_SUCCESSFULLY_CHANGED = 'Your new password was successfully registered!';
export const NUMBER_OF_TICKETS = 'Number of tickets';

export const LAST_NAME = 'Last name';
export const LAST_NAME_IS_REQUIRED = 'Last name is required';
export const LOGIN = 'Log in';
export const LOGIN_GOOGLE = 'Continue with Google';
export const LOGIN_OR_REGISTER = 'Log In or Create Account';

export const OLD_PASSWORD = 'Old password';
export const OPTIONAL = '(Optional)';
export const OR = 'Or';
export const ORDER_TOTAL = 'Order total';
export const OTHER = 'Other';

export const PASSWORD = 'Password';
export const PASSWORD_IS_REQUIRED = 'Password is required';
export const PRICE_BOUGHT = 'Price bought for';
export const PURCHASE = 'Purchase';
export const PURCHASE_TICKETS = 'Purchase Tickets';
export const PAYMENT_METHOD = 'Payment method';
export const PENDING_TRANSACTIONS = 'Pending Transactions';
export const PHONE_NUMBER = 'Phone number';
export const PHONE_NUMBER_PLACEHOLDER = '+00 000 000 0000';

export const QUANTITY = 'Quantity';
export const QUANTITY_OF_TICKETS = 'Quantity of tickets';

export const RESALE_TICKETS = 'Resale tickets';
export const RESET_PASSWORD = 'Reset password';
export const ROUTING_NUMBER = 'Routing number';

export const SAVE = 'Save';
export const SECONDARY_ADDRESS = 'Secondary address';
export const SELECT = 'Select';
export const SELECT_TICKET_TYPE = 'Select your ticket type';
export const SELL = 'Sell';
export const SELL_TICKET = 'Sell Tickets';
export const SEND_EMAIL = 'Send email';
export const STATE_PROVINCE = 'State/Province';
export const STREET_ADDRESS = 'Street address';

export const TAX = 'Tax';
export const TICKET_INFO = 'Ticket information';
export const TICKET_ON_WAY = "Your ticket is on it's way";
export const TICKET_PENDING_UNTIL_TRANSFER =
  'The ticket will be marked as pending until it is transferred.';
export const TICKET_PRICE = 'Ticket price';
export const TICKET_SUMMARY = 'Ticket summary';
export const TICKET_SOLD_MARKETPLACE = 'Ticket sold on marketplace';
export const TICKET_SELL_MARKETPLACE = 'Ticket price in marketplace';
export const TICKET_SELLING = 'Ticket selling';
export const TICKET_TRANSFERING = 'Ticket transfering';
export const TRANSACTION_FEES = 'Transaction fees';

export const TOTAL_VALUE = 'Total value';
export const TRANSFER = 'Transfer';
export const TRANSFER_TICKET = 'Transfer Ticket';
export const TICKET_TRANSFERRED = 'Ticket transferred';
export const TICKET_SOLD_PER_TO_PER = 'Ticket sold per to per';

export const TRANSFERRING_INSTRUCTIONS =
  'Enter the email of the person you would like to transfer tickets. Note that once tickets are transferred, the recipient will have full ownership.';
export const TRANSFERRING_TICKETS = 'Transfering tickets';
export const TRANSACTION_INFO = 'Transaction info';

export const UP_NEXT = 'Up Next';
export const USD = ' USD';

export const VIEW = 'View';
export const VIEW_TICKET = 'View ticket';

export const WE_SENT_EMAIL = 'We have sent an email to confirm the transfer.';

export const YOUR_TICKETS = 'Your Tickets';
export const MY_LISTINGS = 'My Listings';

export const ZIP_POSTAL_CODE = 'Zip/Postal code';

// ROUTES
export const ROUTES = {
  BUY_TICKETS: '/buy-tickets',
  MY_TICKETS: '/existing-tickets',
  PAST_EVENTS: '/past-events',
  ACCOUNT: '/account',
  RECEIVE_TRANSFER: '/transfer-tickets',
  MY_LISTINGS: '/my-listings',
  TRANSACTIONS: '/transactions',
  CART: '/cart',
};

// ROUTES
export const PRIVATE_ROUTES = [
  ROUTES.MY_TICKETS,
  ROUTES.PAST_EVENTS,
  ROUTES.ACCOUNT,
  ROUTES.RECEIVE_TRANSFER,
  `${ROUTES.BUY_TICKETS}/tickets/event`,
  ROUTES.CART,
];

export const BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
