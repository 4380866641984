import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const TicketImage = styled.img<{ isMobile?: boolean }>`
  margin: ${(props) => (props.isMobile ? '0 60px 16px auto' : '0 auto 16px auto')};
  width: 230px;
  height: 500px;
  border-radius: 18px;
  margin-left: ${(props) => (props.isMobile ? '56px' : '')};
  object-fit: cover;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.gray[25]};
  width: 90%;
  padding: 30px;
`;

export const Container = styled.div`
  display: flex;
  background-color: ${palette.white};
  width: 100%;
  align-items: center;
`;

export const Title = styled.div`
  color: ${palette.black};
  font-weight: 600;
  font-size: 24px;
`;

export const TicketInfoElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;

export const TicketInfoElementRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0px;
`;

export const TicketInfoElementTitle = styled.div`
  display: flex;
  font-size: 12px;
  &.bold {
    font-weight: 800;
  }
`;

export const TicketInfoElementContent = styled.div`
  display: flex;
  font-size: 18px;
`;

export const Divider = styled.div`
  display: flex;
  width: 100%;
  border-top: 1.5px dashed #000;
  height: 0px;
  font-size: 30px;
`;

export const DetailsContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: ${(props) => (props.isMobile ? '20px' : '')};

  &.validate {
    position: absolute;
    justify-content: center;
    bottom: 0px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  width: 53px;
  height: 53px;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  display: grid;
  justify-items: center;
  align-items: center;
  cursor: pointer;
`;

export const Img = styled.img`
  width: 20px;
  height: 20px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ButtonsRowMobile = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled.button<{ color?: string; primaryFontColor?: string }>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 40px;
  width: 120px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  margin: 10px;
  cursor: pointer;
  color: ${palette.black};
  &.mobile {
    border: 1px solid ${(props) => props.color};
    border-radius: 1000px;
    background-color: ${(props) => props.color};
    color: ${(props) => props.primaryFontColor};
  }
  &.small {
    width: 132px;
    height: 52px;
  }
  &.medium {
    width: 273px;
    height: 52px;
  }
`;

export const ButtonValidate = styled.button`
  position: absolute;
  width: 100%;
  height: 80px;
  background: #59ce63;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  text-align: center;

  &.validate {
    border: 0px;
    bottom: 0px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  background-color: ${palette.white};
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #000000;
  display: flex;
  flex-direction: column;

  &.screen {
    padding: 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  background-color: ${palette.white};
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #000000;
  display: flex;

  &.screen {
    padding: 0px;
  }
`;

export const Row = styled.div<{ isMobile?: boolean }>`
  display: flex;
  background-color: ${palette.white};
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? '20px' : '')};
  margin-bottom: ${(props) => (props.isMobile ? '15px' : '')};
  justify-content: center;

  &.view {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const ModalContainer = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  height: 100%;
  background: ${palette.white};
  outline: none;
`;

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ModalStyleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
