import ReactGA from 'react-ga4';
import moment from 'moment-timezone';

import { GAEventType, GAPageType } from './types';

export const resetLocalStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userId');
  window.location.reload();
};

export const nuveiTax1 = 0.0294;
export const nuveiTax2 = 0.0;

export const achFee = 35.0;

export const convertUTCToDate = (date: string, timezone: string) => {
  const newTimezone = timezone?.split(' ')[0];
  const dateUTC = moment.utc(date);
  const dateWithTimezone = dateUTC.tz(newTimezone);
  const finalDate = dateWithTimezone?.format('YYYY-MM-DD HH:mm:ss');
  return finalDate;
};

export const sendGAPage = ({ page, title }: GAPageType) =>
  ReactGA.send({ hitType: 'pageview', page, title });

export const sendGAEvent = ({ category, action }: GAEventType) =>
  ReactGA.event({ category, action });
