import { createSlice } from '@reduxjs/toolkit';

interface WidgetDataProps {
  data: {
    primaryColor: string;
    secondaryColor: string;
    primaryFont: string;
    secondaryFont: string;
    primaryFontColor: string;
    secondaryFontColor: string;
    backgroundColor: string;
    headerColor: string;
    calloutsColor: string;
  };
}

const initialWidgetData: WidgetDataProps = {
  data: {
    primaryColor: '',
    secondaryColor: '',
    primaryFont: '',
    secondaryFont: '',
    primaryFontColor: '',
    secondaryFontColor: '',
    backgroundColor: '',
    headerColor: '',
    calloutsColor: '',
  },
};

const widgetData = createSlice({
  name: 'widgetData',
  initialState: initialWidgetData,
  reducers: {
    setWidgetData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setWidgetData } = widgetData.actions;

export default widgetData.reducer;
