import React, { useState } from 'react';

/* eslint-disable no-nested-ternary */
import { eyeOff, eyeOn } from 'Assets/Images';
import { Column, InputLabel, SInput, IconContainer, Icon, IconButton, Error } from './styles';

export interface InputProps {
  label: string;
  onChange: (e: string | React.ChangeEvent<unknown> | React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  error?: string | boolean;
  className?: string;
  touched?: boolean;
  type?: React.HTMLInputTypeAttribute;
  min?: number;
  max?: number;
  disabled?: boolean;
}

const Input = ({
  label,
  onChange,
  value,
  type = 'text',
  className,
  error,
  disabled = false,
}: InputProps) => {
  const [showPassword, toggleShowPassword] = useState(false);
  return (
    <Column>
      <InputLabel>{label}</InputLabel>
      <SInput
        disabled={disabled}
        className={`${className} ${value ? '' : 'placeholder'} ${error ? 'error' : ''}`}
        onChange={onChange}
        value={value}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
      />
      <IconContainer>
        {type === 'password' && (
          <IconButton onClick={() => toggleShowPassword(!showPassword)}>
            {showPassword ? <Icon src={eyeOn} /> : <Icon src={eyeOff} />}
          </IconButton>
        )}
      </IconContainer>
      <Error>{error}</Error>
    </Column>
  );
};
export default Input;
