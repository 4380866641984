import * as Yup from 'yup';

import {
  DATE_OF_BIRTH,
  EMAIL,
  EMAIL_IS_INVALID,
  EMAIL_IS_REQUIRED,
  FEMALE,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  MALE,
  OPTIONAL,
  OTHER,
  PASSWORD,
  PASSWORDS_DOESNT_MATCH,
  PASSWORD_IS_REQUIRED,
  CONFIRM_NEW_PASSWORD,
  NAME_IS_REQUIRED,
  LAST_NAME_IS_REQUIRED,
  PHONE_NUMBER,
  PHONE_NUMBER_PLACEHOLDER,
} from 'Utils/constants';

export const LOGIN_FIELDS = {
  email: 'email',
  password: 'password',
  firstName: 'firstName',
  lastName: 'lastName',
  dateOfBirth: 'dateOfBirth',
  gender: 'gender',
  phoneNumber: 'phoneNumber',
};

export const CHANGE_PASSWORD_FIELDS = {
  password: 'password',
  confirmPassword: 'confirmPassword',
};

export const LOGIN_INITIAL_VALUES = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
  phoneNumber: '',
};

export const CHANGE_PASSWORD_INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
};

export const RESET_PASSWORD_INITIAL_VALUES = {
  email: '',
};

export const LoginSchema = Yup.object().shape({
  email: Yup.string().required(EMAIL_IS_REQUIRED).email(EMAIL_IS_INVALID),
  password: Yup.string().required(PASSWORD_IS_REQUIRED),
});

export const ConfirmPasswordSchema = Yup.object().shape({
  password: Yup.string().required(PASSWORD_IS_REQUIRED),
  confirmPassword: Yup.string()
    .required(PASSWORD_IS_REQUIRED)
    .oneOf([Yup.ref(CHANGE_PASSWORD_FIELDS.password), ''], PASSWORDS_DOESNT_MATCH),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().required(EMAIL_IS_REQUIRED).email(EMAIL_IS_INVALID),
});

export const loginInputData = [
  {
    name: LOGIN_FIELDS.email,
    label: EMAIL,
  },
  {
    name: LOGIN_FIELDS.password,
    label: PASSWORD,
  },
];

export const changePasswordInputData = [
  {
    name: CHANGE_PASSWORD_FIELDS.password,
    label: PASSWORD,
  },
  {
    name: CHANGE_PASSWORD_FIELDS.confirmPassword,
    label: CONFIRM_NEW_PASSWORD,
  },
];

export const resetPasswordInputData = [
  {
    name: LOGIN_FIELDS.email,
    label: EMAIL,
  },
];

export const SignUpSchema = Yup.object().shape({
  email: Yup.string().required(EMAIL_IS_REQUIRED).email(EMAIL_IS_INVALID),
  password: Yup.string().required(PASSWORD_IS_REQUIRED),
  firstName: Yup.string().trim().required(NAME_IS_REQUIRED),
  lastName: Yup.string().trim().required(LAST_NAME_IS_REQUIRED),
});

export const createAccountInputData = [
  {
    name: LOGIN_FIELDS.email,
    label: EMAIL,
  },
  {
    name: LOGIN_FIELDS.password,
    label: PASSWORD,
  },
  {
    name: LOGIN_FIELDS.phoneNumber,
    isPhoneNumber: true,
    label: PHONE_NUMBER,
    placeholder: PHONE_NUMBER_PLACEHOLDER,
  },
  {
    row: [
      {
        name: LOGIN_FIELDS.firstName,
        label: FIRST_NAME,
      },
      {
        name: LOGIN_FIELDS.lastName,
        label: LAST_NAME,
      },
    ],
  },
  {
    row: [
      {
        name: LOGIN_FIELDS.dateOfBirth,
        label: `${DATE_OF_BIRTH} ${OPTIONAL}`,
      },
      {
        name: LOGIN_FIELDS.gender,
        label: `${GENDER} ${OPTIONAL}`,
        options: [MALE, FEMALE, OTHER],
      },
    ],
  },
];
