import styled from 'styled-components';

import { IsMobileProps } from 'Utils/types';
import { kinectAirColors, palette } from 'Utils/theme';

export const Wrapper = styled.div<IsMobileProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px ${(props) => (props.isMobile ? '40px' : 'min(10%, 180px)')};
  height: ${(props) => (props.isMobile ? '60%' : '64%')};
  overflow-y: scroll;
`;

export const InfoContainer = styled.div`
  height: 100%;
  width: 360px;
  &.mobile {
    width: 240px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${kinectAirColors.font};
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  &.mobile {
    flex-direction: column;
  }
`;

export const CustomFlex = styled.div`
  display: flex;
  justify-content: space-between;

  &.mobile {
    flex-direction: column;
  }
`;

export const Div = styled.div`
  height: 100px;
`;

export const Img = styled.img``;

export const Label = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${kinectAirColors.font};
`;

export const styleCardNumber = {
  base: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    color: palette.black,
    cursor: 'auto',
    background: palette.gray[30],
    width: '100%',
    position: 'unset',
    marginTop: '2px',
  },
  invalid: {
    color: palette.error,
    ':focus': {
      color: palette.error,
    },
  },
  empty: {
    color: '#BADA55',
  },
  valid: {
    color: palette.black,
  },
};
