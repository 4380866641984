import { UseMutateFunction } from 'react-query';
import { AxiosResponse } from 'axios';

export const useMutateOnce = <T,>(
  callback: UseMutateFunction<void | AxiosResponse<any, any>, unknown, T, unknown>,
) => {
  let hasTriggered = false;
  return (arg: T) => {
    if (!hasTriggered) {
      hasTriggered = true;
      callback(arg);
    }
  };
};
