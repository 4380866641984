import React from 'react';
import { Modal } from '@mui/material';

import { exit, rejected } from 'Assets/Images';
import { Button } from 'Components/CreditCard/CreditCardModal/styles';
import useMobileView from 'hooks/useMobileView';
import { kinectAirColors } from 'Utils/theme';
import { modalStyle, ModalContainer, Title, TitleContainer, Img, Wrapper, Text } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  errorDescription: string;
  setErrorDescription: React.Dispatch<React.SetStateAction<string>>;
}

export const RejectedModal = ({
  isOpen,
  setIsOpen,
  title,
  errorDescription,
  setErrorDescription,
}: Props) => {
  const isMobile = useMobileView();

  const handleClose = () => {
    setIsOpen(false);
    setErrorDescription('');
  };

  return (
    <Modal open={isOpen} onClose={handleClose} sx={modalStyle}>
      <ModalContainer isMobile={isMobile}>
        <TitleContainer isMobile={isMobile}>
          <Title>{title}</Title>
          <Img className="close" src={exit} onClick={handleClose} />
        </TitleContainer>
        <Wrapper>
          <Img className="main" src={rejected} />
          <Text className="span">
            There was an error processing your payment. Please try again.
          </Text>
          {errorDescription && <Text className="span">{errorDescription}</Text>}
          <Button
            color={kinectAirColors.font}
            background={kinectAirColors.callout}
            onClick={handleClose}
          >
            Try Again
          </Button>
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};
