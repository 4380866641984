import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { RootState } from 'store';
import { ACCOUNT, ROUTES } from 'Utils/constants';
import { Navigation, Label, Row, Column } from './styles';

const navigationOptions = [
  { name: 'Buy Tickets', value: ROUTES.BUY_TICKETS },
  { name: 'My Tickets', value: ROUTES.MY_TICKETS },
  { name: 'Past Events', value: ROUTES.PAST_EVENTS },
  { name: 'My Listings', value: ROUTES.MY_LISTINGS },
];

const event = localStorage.getItem('event');

const spinupNavigationOptions = [
  { name: 'Buy Tickets', value: `${ROUTES.BUY_TICKETS}/${event}` },
  { name: 'My Tickets', value: ROUTES.MY_TICKETS },
  { name: 'Past Events', value: ROUTES.PAST_EVENTS },
  { name: 'My Listings', value: ROUTES.MY_LISTINGS },
];

const account = {
  name: ACCOUNT,
  value: ROUTES.ACCOUNT,
};

interface Props {
  user: any;
}

export default function NavbarDesktop({ user }: Props) {
  const history = useHistory();
  const location = useLocation();
  const isSpinup = localStorage.getItem('isSpinup') === 'true';

  const { primaryColor, secondaryFontColor, secondaryColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );

  return (
    <Navigation>
      <Row>
        {(isSpinup ? spinupNavigationOptions : navigationOptions).map(({ name, value }) => (
          <Label
            key={value}
            className={
              location.pathname.startsWith(value) ? 'selected apply-font-1' : 'apply-font-1'
            }
            onClick={() => history.push(value)}
            primary={primaryColor}
            fontColor={secondaryFontColor}
          >
            {name}
          </Label>
        ))}
      </Row>
      {location.pathname !== '/account' ? (
        <Label
          className={
            location.pathname.startsWith(account.value) ? 'selected apply-font-1' : 'apply-font-1'
          }
          onClick={() => history.push(account.value)}
          primary={primaryColor}
          fontColor={secondaryFontColor}
        >
          {account.name}
        </Label>
      ) : (
        user && (
          <Column>
            <Label className="apply-font-1" fontColor={secondaryFontColor}>
              Welcome
            </Label>
            <Label className="apply-font-1 capitalize" fontColor={secondaryColor}>
              {user.full_name}
            </Label>
          </Column>
        )
      )}
    </Navigation>
  );
}
