import { createSlice } from '@reduxjs/toolkit';

interface TicketSliceProps {
  selectedTickets: number[];
}

const initialWidgetData: TicketSliceProps = {
  selectedTickets: [],
};

const ticketsSlice = createSlice({
  name: 'ticketsSlice',
  initialState: initialWidgetData,
  reducers: {
    setSelectedTickets(state, { payload }) {
      state.selectedTickets = state.selectedTickets.includes(payload)
        ? state.selectedTickets.filter((selected) => selected !== payload)
        : [...state.selectedTickets, payload];
    },
    selectAll(state, { payload }) {
      state.selectedTickets = payload;
    },
  },
});

export const { setSelectedTickets, selectAll } = ticketsSlice.actions;

export default ticketsSlice.reducer;
