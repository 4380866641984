import React, { useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  // BuyTickets,
  // TicketsList,
  PurchaseTickets,
  ExistingTickets,
  // PastEvents,
  // Account,
  // Transactions,
  // Cart,
} from 'Views';
import { ROUTES } from 'Utils/constants';
import { getData } from 'api/api';
import { setWidgetData } from 'Slices/widgetInfoSlice';
import ShowRoutes from 'ShowRoute';
// import MyEventTickets from 'Views/MyEventTickets';
// import ReceiveTransfer from 'Views/ReceiveTransfer';
// import MyListings from 'Views/MyListings/MyListings';
// import SetPartner from 'Views/SetPartner';
// import TimeSlots from 'Views/TimeSlots';
// import EventInfo from 'Views/EventInfo';
import { palette } from 'Utils/theme';

const paths = [
  // {
  //   path: ROUTES.BUY_TICKETS,
  //   name: 'BuyTickets',
  //   Page: BuyTickets,
  // },
  // {
  //   path: `${ROUTES.BUY_TICKETS}/event/:event_id`,
  //   name: 'EventInfo',
  //   Page: EventInfo,
  // },
  // {
  //   path: `${ROUTES.BUY_TICKETS}/:id`,
  //   name: 'TicketList',
  //   Page: TicketsList,
  // },
  // {
  //   path: `${ROUTES.BUY_TICKETS}/:id/slots`,
  //   name: 'TicketList',
  //   Page: TimeSlots,
  // },
  // {
  //   path: `${ROUTES.BUY_TICKETS}/partner/:id`,
  //   name: 'SetPartner',
  //   Page: SetPartner,
  // },
  // {
  //   path: `${ROUTES.BUY_TICKETS}/partner/:id/transaction-id/:transaction_id`,
  //   name: 'SetPartner',
  //   Page: SetPartner,
  // },
  {
    path: `${ROUTES.BUY_TICKETS}/tickets/event/:event_id`,
    // path: ROUTES.BUY_TICKETS,
    name: 'PurchaseTickets',
    Page: PurchaseTickets,
  },
  {
    path: ROUTES.MY_TICKETS,
    name: 'MyTickets',
    Page: ExistingTickets,
  },
  // {
  //  path: `${ROUTES.MY_TICKETS}/partner/:id`,
  //  name: 'MyTickets',
  //  Page: ExistingTickets,
  // },
  // {
  //   path: `${ROUTES.MY_TICKETS}/:id`,
  //   name: 'MyTickets',
  //   Page: MyEventTickets,
  // },
  // {
  //   path: ROUTES.PAST_EVENTS,
  //   name: 'PastEvents',
  //   Page: PastEvents,
  // },
  // {
  //   path: `${ROUTES.PAST_EVENTS}/:id`,
  //   name: 'PastEvents',
  //   Page: MyEventTickets,
  // },
  // {
  //   path: ROUTES.ACCOUNT,
  //   name: 'Account',
  //   Page: Account,
  // },
  // {
  //   path: ROUTES.RECEIVE_TRANSFER,
  //   name: 'ReceiveTransfer',
  //   Page: ReceiveTransfer,
  // },
  // // TO DO WHEN INFO IS READY
  // {
  //   path: ROUTES.MY_LISTINGS,
  //   name: 'MyListings',
  //   Page: MyListings,
  // },
  // {
  //   path: `${ROUTES.TRANSACTIONS}/:id`,
  //   name: 'Transactions',
  //   Page: Transactions,
  // },
  // {
  //   path: `${ROUTES.CART}`,
  //   name: 'Cart',
  //   Page: Cart,
  // },
];

const Router = () => {
  const partnerId = `${process.env.REACT_APP_KINECTAIR_PARTNER_ID}`;
  const initialLoadRef = useRef(true);
  if (initialLoadRef.current) {
    // Code runs on initial render (not after) but only once
    initialLoadRef.current = false;
    localStorage.clear();
    localStorage.setItem('partnerId', partnerId);
  }

  const isSpinup = false;
  // const hasToken = !!localStorage.getItem('accessToken');
  // const previewToken = localStorage.getItem('previewToken');
  const dispatch = useDispatch();
  const { black, white } = palette;

  const handleWidgetData = (data: any) =>
    setTimeout(
      () =>
        dispatch(
          setWidgetData({
            primaryColor: isSpinup ? black : data.theme_color,
            secondaryColor: isSpinup ? black : data.header_color,
            primaryFont: 'Barlow', // Kinectair font
            secondaryFont: 'Barlow', // Kinectair font
            primaryFontColor: isSpinup ? white : data.title_brand_color,
            secondaryFontColor: isSpinup ? black : data.text_brand_color,
            // backgroundColor: data.background_color,
            // headerColor: data.header_brand_color,
            // calloutsColor: data.callouts_brand_color,
          }),
        ),
      1000,
    );

  useQuery('getWidgetData', () => getData(`public/users/partners/${partnerId}/widget`), {
    enabled: !!partnerId,
    onSuccess: handleWidgetData,
    refetchOnWindowFocus: true,
  });

  // useQuery('getEvent', () => getData(`public/ticketing/events/${event}/websettings`), {
  //   enabled: isSpinup && previewToken === 'null',
  //   onSuccess: handleWidgetData,
  //   refetchOnWindowFocus: true,
  // });

  // useQuery('getEventPreview', () => getData(`events/${event}/websettings`), {
  //   enabled: isSpinup && previewToken !== 'null',
  //   onSuccess: handleWidgetData,
  //   refetchOnWindowFocus: true,
  // });

  // useQuery('getCarts', () => getData(`carts/${partnerId}`), {
  //   enabled: hasToken,
  // });

  return (
    <Switch>
      {paths.map(({ name, path, Page }) => (
        <ShowRoutes key={name} path={path} exact component={Page} />
      ))}
      <Route path="*" render={() => <Redirect to="/buy-tickets" />} />
    </Switch>
  );
};

export default Router;
