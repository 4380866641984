import React from 'react';
import { Route } from 'react-router-dom';

import useLoading from 'hooks/useLoading';
import { kaLoading } from 'Assets/animations';
import { Div, Loading } from 'Views/styles';

function ShowRoutes({ component: Component, ...restOfProps }: any) {
  const isLoading = useLoading();

  return (
    <Route
      {...restOfProps}
      render={(props) => (
        <>
          <Component {...props} />
          {!!isLoading && (
            <>
              <Div />
              <Loading src={kaLoading} alt="loading..." />
            </>
          )}
        </>
      )}
    />
  );
}

export default ShowRoutes;
