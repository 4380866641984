import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'Components/Common';
import useMobileView from 'hooks/useMobileView';
import { getData } from 'api/api';
import { RootState } from 'store';
import {
  PurchaseFreeTicket,
  checkTicketsAvailability,
} from 'Components/CreditCard/CreditCardModal/services';
import { setIsFromPurchase, toggleAuthModal, toggleAuthModalType } from 'Slices/authModal';
import { reset, setReceivingTicketInfo } from 'Slices/paymentInfoSlice';
import { ORDER_TOTAL, PURCHASE, ROUTES, TAX, TRANSACTION_FEES } from 'Utils/constants';
import { checkmarks } from 'Assets/Images';
import CartRow from 'Components/Common/Cart/CartRow';
import { GlobalTicket } from 'Utils/types';
import {
  NestedTicketObject,
  filterObjectByKeys,
  getKeysWithFalseValues,
} from 'Components/CreditCard/CreditCardModal/utils';
import { deleteFromCart } from 'Components/Common/Cart/CartRow/services';
import { AddToCart, CreateCart } from './services';
import PromoCodeInputApply from './PromoCodeInputApply';

import {
  CheckBox,
  ContainerText,
  Filled,
  Label,
  Link,
  PurchaseContainer,
  Row,
  AppliedTag,
  Column,
} from './styles';

interface Props {
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  quantity: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentPurchaseCart: any;
  setCurrentPurchaseCart: React.Dispatch<any>;
  totalTaxes: number;
  totalFees: number;
  fixedPrice: number;
  eventInfo: any;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  purchaseOnClick: boolean;
  setPurchaseOnClick: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketsWithPromocodes: React.Dispatch<React.SetStateAction<number[]>>;
  ticketTypesID: number[];
  setPromoCodeId?: React.Dispatch<React.SetStateAction<number>>;
  openSoldOutModal: () => void;
  setUnavailableTickets: (obj: NestedTicketObject) => void;
}

export const PurchaseWrapper = ({
  setIsConfirmationOpen,
  quantity,
  setIsOpen,
  currentPurchaseCart,
  setCurrentPurchaseCart,
  totalTaxes,
  totalFees,
  fixedPrice,
  eventInfo,
  ticketTypeDic,
  purchaseOnClick,
  setPurchaseOnClick,
  setTicketsWithPromocodes,
  ticketTypesID,
  setPromoCodeId,
  openSoldOutModal,
  setUnavailableTickets,
}: Props) => {
  const [checked, setChecked] = useState<boolean>(false);
  // const [addToCart, setAddToCart] = useState<boolean>(false);
  // const { primaryColor } = useSelector(({ widgetData }: RootState) => widgetData.data);
  const userId = localStorage.getItem('userId');
  // const partnerId = localStorage.getItem('partnerId');
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = !!localStorage.getItem('accessToken');
  const isMobile = useMobileView();
  // const history = useHistory();
  const { data: userInfo } = useQuery('getInfo', () => getData(`/users/me`), {
    enabled: isAuthenticated,
  });

  // CHECK: Ticket quantity
  // const ticketQuantity = [1];

  // const handleUpdatedCart = (cart: any) => {
  //   let currentCart: { id: number; quantity: number }[] = [];
  //   cart?.events.forEach((event: any) => {
  //     event.ticket_types.forEach(({ id, quantity }: { id: number; quantity: number }) => {
  //       currentCart = [...currentCart, { id, quantity }];
  //     });
  //   });
  //   const updatedPurchaseCart = currentPurchaseCart?.map(
  //     ({ ID, quantity }: { ID: number; quantity: number }) => ({
  //       id: ID,
  //       quantity,
  //     }),
  //   );
  //   const cartWithRepeatedTickets = [...currentCart, ...updatedPurchaseCart];
  //   const newCartObject = cartWithRepeatedTickets.reduce(
  //     (acc, actual) => {
  //       if (acc.object[actual.id]) {
  //         acc.list[acc.object[actual.id].listId] = {
  //           ...acc.object[actual.id],
  //           quantity: acc.object[actual.id].quantity + actual.quantity,
  //         };
  //         acc.object[actual.id] = {
  //           ...acc.object[actual.id],
  //           quantity: acc.object[actual.id].quantity + actual.quantity,
  //         };
  //       } else {
  //         acc.object[actual.id] = { ...actual, listId: acc.list.length };
  //         acc.list.push(actual);
  //       }
  //       return acc;
  //     },
  //     { object: {}, list: [] },
  //   );
  //   return newCartObject.list;
  // };
  // change when backend is ready: ticket id and quantity is wrong
  const { mutate: mutateFreeTicket } = useMutation(PurchaseFreeTicket, {
    onSuccess: () => setIsConfirmationOpen(true),
  });

  const { mutate: mutateCheckAvailability } = useMutation(checkTicketsAvailability, {
    onSuccess: (data) => {
      const ticketsUnavailables = getKeysWithFalseValues(data);
      if (ticketsUnavailables.length > 0) {
        const filteredTicketsData = filterObjectByKeys(ticketTypeDic, ticketsUnavailables);
        setUnavailableTickets(filteredTicketsData);
        ticketsUnavailables.forEach((ticketId) =>
          deleteFromCart({
            partnerId: localStorage.getItem('partnerId'),
            ticketTypeId: Number(ticketId),
          }),
        );
        openSoldOutModal();
      } else {
        mutateFreeTicket({ userId, ticketTypeDic });
      }
    },
  });

  // const { mutate: mutateCreateCart } = useMutation(CreateCart, {
  //   onSuccess: () => setIsOpenModal(true),
  // });

  // const { mutate: mutateAddToCart } = useMutation(AddToCart, {
  //   onSuccess: () => {
  //     if (addToCart) setIsOpenModal(true);
  //     else setTimeout(() => history.push(ROUTES.CART), 100);
  //   },
  // });

  // useQuery('getCarts', () => getData(`carts/${partnerId}`), {
  //   enabled: isAuthenticated && (addToCart || purchaseOnClick),
  //   onSuccess: (data) => {
  //     if (purchaseOnClick)
  //       if (data.events[0])
  //         mutateAddToCart({ userId, partnerId, ticketQuantity: handleUpdatedCart(data) });
  //       else mutateAddToCart({ userId, partnerId, ticketQuantity: handleUpdatedCart(data) });
  //   },
  //   onError: () => {
  //     if (!purchaseOnClick) mutateCreateCart({ userId, partnerId, ticketQuantity });
  //   },
  // });

  return (
    <PurchaseContainer className={isMobile ? 'isMobile' : ''}>
      {/* TODO CartRow commented out for demo on 8.17.23 may be added back after demo */}
      {/* {currentPurchaseCart?.map((ticket: GlobalTicket, index: number) => (
        <CartRow
          key={ticket.ID}
          ticket={ticket}
          setCartValue={setCurrentPurchaseCart}
          cartValue={currentPurchaseCart}
          eventIndex={0}
          ticketIndex={index}
          eventInfo={eventInfo}
        />
      ))}
      <Row className="border" /> */}
      {fixedPrice !== 0 && (
        <>
          {currentPurchaseCart?.map((ticket: any) => (
            <Row key={ticket.ID}>
              <Row>
                <Label className="purchase">{ticket?.name}</Label>
                {ticket.promocode_applied && <AppliedTag>Promo Applied</AppliedTag>}
              </Row>
              <Column>
                <Label className={`purchase ${ticket.promocode_applied && 'applied'}`}>
                  $
                  {Number(
                    ticket.promocode_applied ? ticket.price_no_discount : ticket?.initial_price,
                  ).toFixed(2) === 'NaN'
                    ? 0
                    : Number(
                        ticket.promocode_applied ? ticket.price_no_discount : ticket?.initial_price,
                      ).toFixed(2)}{' '}
                  x{ticket?.quantity}
                </Label>
                {ticket.promocode_applied && (
                  <Label className="purchase">
                    $
                    {Number(ticket?.initial_price).toFixed(2) === 'NaN'
                      ? 0
                      : Number(ticket?.initial_price).toFixed(2)}{' '}
                    x{ticket?.quantity}
                  </Label>
                )}
              </Column>
            </Row>
          ))}
          <Row>
            <Label className="purchase">{TAX}</Label>
            <Label className="purchase">
              ${Number(totalTaxes).toFixed(2) === 'NaN' ? 0 : Number(totalTaxes).toFixed(2)}
            </Label>
          </Row>
          <Row>
            <Label className="purchase">{TRANSACTION_FEES}</Label>
            <Label className="purchase">
              ${Number(totalFees).toFixed(2) === 'NaN' ? 0 : Number(totalFees).toFixed(2)}
            </Label>
          </Row>
          <Row className="border" />
          <Row className="margin-bottom">
            <Label className="order-total">{ORDER_TOTAL}</Label>
            <Label className="order-total">
              $
              {(fixedPrice + totalTaxes + totalFees).toFixed(2) === 'NaN'
                ? 0
                : Number((fixedPrice + totalTaxes + totalFees).toFixed(2))}{' '}
              <Label>USD</Label>
            </Label>
          </Row>
          <PromoCodeInputApply
            setPromoCodeId={setPromoCodeId}
            ticketTypesID={ticketTypesID}
            setTicketsWithPromocodes={setTicketsWithPromocodes}
          />
          <Row className="margin-bottom conditions margin-top">
            <CheckBox
              className="pointer"
              primaryColor="#EF6B57"
              onClick={() => setChecked(!checked)}
              checked={checked}
            >
              {checked && <Filled primaryColor="#EF6B57" src={checkmarks} />}
            </CheckBox>
            <ContainerText>
              <Label className="conditions">I accept KinectAir&apos;s and Tixologi&apos;s </Label>
              <Link href="https://tixologi.com/terms-of-service/" target="_blank">
                terms of service
              </Link>
              <Label className="conditions">, and that they will be completing this purchase</Label>
            </ContainerText>
          </Row>
        </>
      )}
      <Button
        label={fixedPrice === 0 ? 'Get Free Ticket' : PURCHASE}
        className="font-weight-small"
        disabled={fixedPrice === 0 ? !quantity : !quantity || !checked}
        // TODO: Move to const or theme
        color="#EF6B57"
        onClick={() => {
          dispatch(setReceivingTicketInfo({ name: userInfo?.full_name, email: userInfo?.email }));
          if (isAuthenticated) setIsOpen(true);
          else {
            dispatch(toggleAuthModal(true));
            dispatch(
              toggleAuthModalType({
                type: 'register',
                back: '',
              }),
            );
          }
          if (fixedPrice === 0) {
            if (isAuthenticated) mutateCheckAvailability(ticketTypeDic);
          } else setPurchaseOnClick(true);
        }}
      />
    </PurchaseContainer>
  );
};
