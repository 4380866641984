import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const TransferContainer = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: ${(props) => (props.isMobile ? 'flex' : 'grid')};
  flex-direction: ${(props) => (props.isMobile ? 'column' : '')};
  grid-template-columns: 1fr 2fr;
  gap: 32px;
  padding: 0 32px;
`;

export const NotificationContainer = styled.div<{ isMobile?: boolean }>`
  margin: 40px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: ${(props) => (props.isMobile ? '100%' : '')}; ;
`;

export const TicketSummary = styled.div`
  width: 100%;
  background-color: ${palette.gray[20]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

export const Title = styled.label`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;

  &.large {
    font-size: 22px;
  }
`;

export const Text = styled.label`
  font-size: 12px;
  letter-spacing: -0.02em;
  letter-spacing: -0.02em;
  margin: 0;

  &.gray {
    color: ${palette.gray[60]};
  }

  &.bold {
    font-weight: 600;
  }

  &.large {
    font-size: 14px;
  }

  &.xlarge {
    font-size: 16px;
  }

  &.small {
    font-size: 10px;
  }
`;

export const Image = styled.img`
  box-sizing: border-box;
  margin-bottom: 16px;
  width: 100%;
  padding: 0 42px;
  max-width: 680px;
  border-radius: 18px;
`;

export const Container = styled.div<{ isMobile?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.gap {
    gap: 30px;
  }

  &.small-gap {
    gap: 8px;
  }

  &.padding-right {
    padding-right: ${(props) => (props.isMobile ? '' : '62px')};
  }
`;

export const Row = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : '')};
`;

export const Column = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: ${(props) => (props.isMobile ? 'center' : '')};
`;

export const SmallInputContainer = styled.div<{ isMobile?: boolean }>`
  width: ${(props) => (props.isMobile ? '40%' : '30%')};
  margin-left: ${(props) => (props.isMobile ? '' : '42px')};
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: ${palette.gray[20]};
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 26px;

  &.white {
    background-color: ${palette.white};
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  }
`;

export const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  border-bottom: 1px dashed ${palette.black};
  margin-bottom: 12px;
`;

export const ButtonsContainer = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : '')};
  align-items: center;
  gap: 16px;
  &.reverse {
    flex-direction: column-reverse;
  }
`;

export const AddContact = styled.div`
  width: 70%;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${palette.gray[100]};
  padding: 14px;
  margin: 5px 0;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  color: ${palette.gray[65]};
`;

export const AddImage = styled.img`
  background-color: ${palette.gray[5]};
  border-radius: 50%;
  padding: 6px;
`;
