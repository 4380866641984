import { createSlice } from '@reduxjs/toolkit';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

import { CardInfoCompleted, BillingInfo, ReceivingTicketInfo, PromoCodeInfo } from 'Utils/types';

interface PaymentInfoProps {
  cardInfo: CardInfoCompleted;
  billingInfo: BillingInfo;
  receivingTicketInfo: ReceivingTicketInfo;
  promoCodeInfo: PromoCodeInfo;
  showPaymentOptions: boolean;
  transferId: string;
  plaidPublicToken: string;
  plaidMetadata: PlaidLinkOnSuccessMetadata;
}

const initialPaymentInfoState: PaymentInfoProps = {
  cardInfo: {
    name: '',
    cardNumber: '',
    cvv: '',
    expiration: '',
    type: {},
  },
  billingInfo: {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    country: '',
    state: '',
    city: '',
    phone: '',
    email: '',
  },
  receivingTicketInfo: {
    name: '',
    email: '',
  },
  promoCodeInfo: {
    promoCode: '',
  },
  showPaymentOptions: true,
  transferId: '',
  plaidPublicToken: '',
  plaidMetadata: {
    institution: null,
    accounts: [],
    link_session_id: '',
  },
};

const paymentInfo = createSlice({
  name: 'paymentInfo',
  initialState: initialPaymentInfoState,
  reducers: {
    setCardInfo(state, action) {
      state.cardInfo = action.payload;
    },
    setBillingInfo(state, action) {
      state.billingInfo = action.payload;
    },
    setReceivingTicketInfo(state, action) {
      state.receivingTicketInfo = action.payload;
    },
    setPromoCodeInfo(state, action) {
      state.promoCodeInfo = action.payload;
    },
    setShowPaymentOptions(state, action) {
      state.showPaymentOptions = action.payload;
    },
    setTransactionId(state, action) {
      state.transferId = action.payload;
    },
    setPlaidToken(state, action) {
      state.plaidPublicToken = action.payload;
    },
    setPlaidMetadata(state, action) {
      state.plaidMetadata = action.payload;
    },
    reset: () => initialPaymentInfoState,
  },
});

export const {
  setCardInfo,
  setBillingInfo,
  setReceivingTicketInfo,
  setPromoCodeInfo,
  setShowPaymentOptions,
  setTransactionId,
  setPlaidToken,
  setPlaidMetadata,
  reset,
} = paymentInfo.actions;

export default paymentInfo.reducer;
