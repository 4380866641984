import { useEffect, useState } from 'react';
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { useDispatch } from 'react-redux';

import { setPlaidMetadata, setPlaidToken, setShowPaymentOptions } from 'Slices/paymentInfoSlice';
import { createTransferToken } from './services';

const usePlaidHook = () => {
  const dispatch = useDispatch();
  const [linkToken, setLinkToken] = useState<string>('');

  useEffect(() => {
    const getToken = async () => {
      const { link_token } = await createTransferToken();
      setLinkToken(link_token);
    };
    getToken();
  }, []);

  const onSuccess = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    dispatch(setPlaidToken(public_token));
    dispatch(setPlaidMetadata(metadata));
    dispatch(setShowPaymentOptions(false));
  };

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return { open, ready };
};

export default usePlaidHook;
