import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button, Input } from 'Components/Common';
import { RESET_PASSWORD, CHANGE_YOUR_PASSWORD } from 'Utils/constants';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from 'api/auth';
import { request, reset, success } from 'Slices/userSlice';
import { FormContainer, ButtonContainer, Space, FormTitle, ErrorMessage } from './styles';
import {
  changePasswordInputData,
  ConfirmPasswordSchema,
  CHANGE_PASSWORD_INITIAL_VALUES,
} from './utils/formData';

const ChangePassword = () => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [error, setError] = useState('');
  const [passwordWasReset, setPasswordWasReset] = useState(false);

  const handleSignIn = (password: string) => {
    const code = new URLSearchParams(search).get('oobCode');

    verifyPasswordResetCode(auth, code as string)
      .then(() => {
        dispatch(request());
        confirmPasswordReset(auth, code as string, password)
          .then(() => {
            dispatch(success());
            setPasswordWasReset(true);
          })
          .catch(() => setError('Request failed'));
      })
      .catch(() => setError('Request failed'));
  };

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: CHANGE_PASSWORD_INITIAL_VALUES,
    validationSchema: ConfirmPasswordSchema,
    onSubmit: ({ password }) => {
      handleSignIn(password);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !isAuthenticated && dispatch(reset());
  }, [dispatch, isAuthenticated]);

  const typedErrors = errors as { [key: string]: string };
  const typedValues = values as { [key: string]: string };
  const typedTouched = touched as { [key: string]: boolean };

  return (
    <Space>
      <FormTitle>{CHANGE_YOUR_PASSWORD}</FormTitle>
      <FormContainer>
        {changePasswordInputData.map(({ label, name }) => (
          <Input
            key={name}
            value={typedValues[name]}
            onChange={
              handleChange(name) as unknown as (e: string | React.ChangeEvent<unknown>) => void
            }
            type="password"
            error={typedErrors[name]}
            touched={typedTouched[name]}
            label={label}
          />
        ))}
      </FormContainer>
      <ButtonContainer>
        <Button label={RESET_PASSWORD} className="full" disabled={false} onClick={handleSubmit} />
        <ErrorMessage>{error}</ErrorMessage>
      </ButtonContainer>
    </Space>
  );
};

export default ChangePassword;
