import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 64px 12%;
  background-color: ${palette.white};
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${palette.gray[95]};
  opacity: 0.7;
  margin-bottom: 10px;
`;

export const Column = styled.div<{ isPayment: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${(props) => !props.isPayment && `1px solid ${palette.black}`};

  &.edit {
    border: none;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Label = styled.label`
  width: 90%;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-bottom: 8px;

  &.information {
    font-weight: 300;
    font-size: 22px;
    text-transform: none;
  }
`;

export const Edit = styled.label`
  width: fit-content;
  color: ${palette.blue};
  cursor: pointer;
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 28px;
  margin-bottom: 8px;
`;
export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export const Button = styled.div<{ color?: string }>`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 150px;
  padding: 10px;
  color: ${palette.white};
  background-color: ${(props) => props.color || palette.gray[70]};
  border-radius: 8px;
  cursor: pointer;
  &.logout {
    padding: 5px 50px;
    border: 1px solid ${palette.black};
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    letter-spacing: -0.02em;
    color: ${palette.black};
    background-color: transparent;
    margin-top: 30px;
  }
  &.payment {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    width: fit-content;
    padding: 10px 50px;
  }
  &.anotherCard {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: ${palette.gray[35]};
    background: ${palette.gray[3]};
    width: fit-content;
    margin-bottom: 10px;
    padding: 5px 10px;
  }
`;
