import styled from 'styled-components';

import { kinectAirColors, palette } from 'Utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 18px;

  &.header {
    padding: 0;
    padding-bottom: 6px;
    gap: 0;
  }

  &.main-content {
    flex-direction: row;
    padding: 14px 0;
    justify-content: space-between;
    gap: 0;
  }

  &.isMobile {
    padding: 10px;
  }
`;

export const Img = styled.img`
  height: 160px;
  margin-bottom: 15px;
`;

export const ContainerText = styled.label`
  margin-top: 12px;
`;

export const Link = styled.a`
  opacity: 2;
  font-weight: 200;
  padding-top: 10px;
  font-size: 14px;
  color: #2f80ed;
  text-decoration: underline;
  cursor: pointer;
`;
export const Label = styled.label`
  font-size: 16px;
  opacity: 0.7;
  font-weight: 600;

  &.title {
    opacity: 1;
    text-transform: uppercase;
    font-size: 20px;
  }

  &.purchase {
    opacity: 1;
    font-weight: 400;
  }

  &.conditions {
    opacity: 2;
    font-weight: 200;
    padding-top: 10px;
    font-size: 14px;
  }
  &.conditions-terms {
    opacity: 2;
    font-weight: 200;
    padding-top: 10px;
    font-size: 14px;
    color: #2f80ed;
    text-decoration: underline;
    cursor: pointer;
  }

  &.order-total {
    opacity: 1;
    font-weight: 600;
  }

  &.resale {
    opacity: 1;
    font-size: 20px;
    padding-bottom: 6px;
  }

  &.ticket-name {
    opacity: 1;
    font-weight: 300;
    font-size: 20px;
  }

  &.ticket-info {
    opacity: 0.7;
    font-weight: 300;
  }

  &.applied {
    color: #9a9a9a;
    text-decoration: line-through;
  }

  &.bankInfo {
    font-size: 12px;
    text-align: center;
  }
`;

export const TicketImage = styled.img`
  width: 230px;
  height: 500px;
  border-radius: 18px;
  object-fit: cover;
`;

export const Wrapper = styled.div`
  &.w65 {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.w45 {
    width: 45%;
  }
  &.w100 {
    width: 100%;
  }
`;

export const PurchaseContainer = styled.div<{ isBankConnected?: boolean }>`
  background: ${kinectAirColors.background2};
  padding: 22px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  width: 100%;
  max-width: 671px;
  min-width: 300px;
  height: ${({ isBankConnected }) => (isBankConnected ? '500px' : 'fit-content')};
  &.isMobile {
    width: 100%;
    padding: 12px;
  }
`;

export const Filled = styled.img<{ primaryColor: string }>`
  background: ${(props) => props.primaryColor};
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const CheckBox = styled.div<{ checked: boolean; primaryColor: string }>`
  border: 1px solid ${(props) => props.primaryColor};
  border-radius: 2px;
  max-width: 20px;
  max-height: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: 10px;
  position: relative;
  margin-bottom: -5px;
  &.pointer {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.border {
    border-bottom: 1px solid ${palette.black};
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  &.light {
    border-bottom: 1px solid ${palette.white};
    opacity: 0.5;
  }

  &.margin-bottom {
    margin-bottom: 20px;
  }
  &.conditions {
    justify-content: normal;
  }
  &.border-top {
    border-top: 1px solid black;
  }

  &.ticket-row {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  &.margin-top {
    margin-top: 12px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.text {
    width: 50%;
  }
  &.bankInfo {
    height: 100%;
    justify-content: space-between;
  }
  &.gap {
    gap: 12px;
  }
`;

export const Input = styled.input`
  display: flex;
  justify-content: space-between;
  width: 80px;
  box-sizing: border-box;
  padding: 5px 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  border: 1px solid ${palette.gray[100]};
  border-radius: 2px;

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 22px 0;

  &.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
  }
  &.empty {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
  }
`;

export const ModalContainer = styled.div<{ isMobile?: boolean }>`
  margin: ${(props) => (props.isMobile ? '100px 0 30px 0' : '')};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const ImageContainer = styled.div<{ primaryColor?: string }>`
  background-color: ${(props) => props.primaryColor};
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div<{ isMobile?: boolean }>`
  gap: 2px;
  width: ${(props) => (props.isMobile ? '60%' : '40%')};
`;

export const Image = styled.img<{ isMobile?: boolean }>`
  box-sizing: border-box;
  width: ${(props) => (props.isMobile ? '20%' : '10%')};
  position: absolute;
  margin-right: 13px;
  &.mobile {
    padding: 0 42px;
  }
`;

export const AppliedTag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #219653;
  background: #e9f8f3;
  border: 1px solid #27ae60;
  border-radius: 4px;
  padding: 4px 6px;
  margin-left: 12px;
  max-height: 22px;
`;
