import { useEffect } from 'react';
import { Notifier } from '@airbrake/browser';

import { ErrorBoundaryType } from 'Utils/types';

export const airbrake = new Notifier({
  projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID),
  projectKey: String(process.env.REACT_APP_AIRBRAKE_PROJECT_KEY),
  environment: String(process.env.REACT_APP_AIRBRAKE_ENVIRONMENT),
});

const ErrorBoundary = ({ children }: ErrorBoundaryType) => {
  useEffect(() => {
    const handleError = (error: string | Event, info: string | undefined) => {
      airbrake.notify({
        error,
        params: { info },
      });
    };

    window.onerror = handleError;

    return () => {
      window.onerror = null;
    };
  }, []);

  return children;
};

export default ErrorBoundary;
