import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

import { Button, Input, Select } from 'Components/Common';
import { CREATE_ACCOUNT, LOGIN, LOGIN_GOOGLE, OR } from 'Utils/constants';
import { googleSignin, signup } from 'api/auth';
import { RootState } from 'store';
import useMobileView from 'hooks/useMobileView';
import { Google } from 'Assets/Icons';
import { toggleAuthModalType } from 'Slices/authModal';
import {
  LOGIN_INITIAL_VALUES,
  createAccountInputData,
  LOGIN_FIELDS,
  SignUpSchema,
} from './utils/formData';

import { InputLabel } from 'Components/Common/Input/styles';
import {
  Container,
  FormContainer,
  ButtonContainer,
  InputRow,
  ErrorMessage,
  PhoneInputWrap,
  styles,
} from './styles';

const getType = (name: string) => {
  if (name === LOGIN_FIELDS.password) return 'password';
  if (name === LOGIN_FIELDS.dateOfBirth) return 'date';
  return 'text';
};

const RegisterForm = () => {
  const [typedErrors, setTypedErrors] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch();
  const isMobile = useMobileView();
  const { error } = useSelector((store: RootState) => store.user);
  const handleSignUp = ({
    email,
    firstName,
    lastName,
    password,
    gender,
    dateOfBirth,
    phoneNumber,
  }: any) =>
    dispatch(signup(email, firstName, lastName, password, gender, dateOfBirth, phoneNumber));

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: LOGIN_INITIAL_VALUES,
    validationSchema: SignUpSchema,
    onSubmit: (_formValues) => {
      handleSignUp(_formValues);
    },
  });

  const submit = () => {
    setTypedErrors(errors as { [key: string]: string });
    handleSubmit();
  };

  const typedValues = values as { [key: string]: string };
  const typedTouched = touched as { [key: string]: boolean };

  return (
    <>
      <Container>
        <FormContainer className={isMobile ? 'mobile' : ''}>
          {createAccountInputData.map((inputData) => {
            return inputData.row ? (
              <InputRow className={isMobile ? 'mobile' : ''}>
                {inputData.row.map((input) => {
                  const { label, name, options } = input;
                  return options ? (
                    <Select
                      key={name}
                      label={label}
                      value={typedValues[name]}
                      className="gray"
                      options={options}
                      setValue={
                        handleChange(name) as unknown as (
                          e: string | React.ChangeEvent<unknown>,
                        ) => void
                      }
                    />
                  ) : (
                    <Input
                      key={name}
                      value={typedValues[name]}
                      onChange={
                        handleChange(name) as unknown as (
                          e: string | React.ChangeEvent<unknown>,
                        ) => void
                      }
                      type={getType(name)}
                      error={typedErrors[name]}
                      touched={typedTouched[name]}
                      label={label}
                    />
                  );
                })}
              </InputRow>
            ) : inputData.isPhoneNumber ? (
              <PhoneInputWrap>
                <InputLabel>Phone Number</InputLabel>
                <PhoneInput
                  country="us"
                  inputStyle={{ ...styles.input, opacity: inputData.name === '' ? 0.5 : 1 }}
                  containerStyle={styles.container}
                  buttonStyle={styles.button}
                  value={inputData.name}
                  onChange={
                    handleChange(inputData.name) as unknown as (
                      e: string | React.ChangeEvent<unknown>,
                    ) => void
                  }
                />
              </PhoneInputWrap>
            ) : (
              inputData.name &&
              inputData.label && (
                <Input
                  key={inputData.name}
                  value={typedValues[inputData.name]}
                  onChange={
                    handleChange(inputData.name) as unknown as (
                      e: string | React.ChangeEvent<unknown>,
                    ) => void
                  }
                  type={getType(inputData.name)}
                  error={typedErrors[inputData.name]}
                  touched={typedTouched[inputData.name]}
                  label={inputData.label}
                />
              )
            );
          })}
          <ErrorMessage>{error}</ErrorMessage>
        </FormContainer>
      </Container>
      <ButtonContainer>
        <Button
          label={CREATE_ACCOUNT}
          className="full font-weight-small register-form"
          // TODO: Move to const or theme
          color="#EF6B57"
          disabled={false}
          onClick={submit}
        />
        <Button
          label={LOGIN}
          className="full outlined register-form"
          color="#EF6B57"
          disabled={false}
          onClick={() => dispatch(toggleAuthModalType({ type: 'login', back: 'register' }))}
          id="login-button"
        />
        <Button
          label={LOGIN_GOOGLE}
          className="full outlined register-form-google"
          color="#EF6B57"
          icon={Google}
          disabled={false}
          onClick={() => {
            dispatch(googleSignin());
          }}
        />
      </ButtonContainer>
    </>
  );
};

export default RegisterForm;
