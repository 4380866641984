import styled from 'styled-components';

import { kinectAirColors, palette } from 'Utils/theme';

export const TemplateContainer = styled.div`
  width: 300px;
  height: 500px;
  cursor: pointer;
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  background-color: ${palette.white};
  z-index: 1;
  box-shadow: 0 0px 4px ${palette.black};

  &.multiple {
    filter: drop-shadow(4.07679px 4.07679px 4.07679px rgba(0, 0, 0, 0.25));
  }

  &.absolute {
    position: absolute;
    transform: rotate(3.34deg);
    bottom: 20px;
    left: 20px;
    z-index: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

export const InfoContainer = styled.div`
  width: 300px;
  height: 340px;
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
`;

export const MainInfoContainer = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 24px;
  text-align: left;
  line-height: 28px;
  color: ${palette.black};
`;

export const InfoText = styled.div`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  line-height: 18px;
  text-transform: none;
  color: ${palette.black};
`;

export const InfoTitle = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  color: ${palette.gray[83]};
`;

export const InfoRow = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  padding: 8px 0;
  box-sizing: border-box;
`;

export const InfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.3;
  border-bottom: ${`1.5px dashed ${palette.gray[35]}`};
`;

export const BottomLine = styled.div`
  left: 0;
  bottom: 0;
  width: 175px;
  height: 12px;
  position: absolute;
  border-radius: 0 0 0 10px;
  background-color: ${kinectAirColors.callout};
`;

export const DecorationTitle = styled.div`
  opacity: 0.1;
  width: 400px;
  rotate: 270deg;
  font-size: 64px;
  line-height: 64px;
  position: absolute;
  left: 90px;
  bottom: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  color: ${palette.black};
`;

export const TicketType = styled.div`
  width: 100%;
  bottom: 16px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const TicketTypeText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.black};
`;

export const Ticket = styled.img`
  width: unset !important;
  width: 20px;
  height: 20px;
`;
