import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from 'Components/Common';
import { LOGIN, OR, FORGOT, LOGIN_GOOGLE } from 'Utils/constants';
import { toggleAuthModalType } from 'Slices/authModal';
import { reset } from 'Slices/userSlice';
import { RootState } from 'store';
import { Google } from 'Assets/Icons';
import { googleSignin, signin } from '../../api/auth';
import { FormContainer, ButtonContainer, Text, ButtonLink, ErrorMessage } from './styles';
import { LOGIN_INITIAL_VALUES, LoginSchema, loginInputData, LOGIN_FIELDS } from './utils/formData';

interface Props {
  link: string;
}
const LoginForm = ({ link }: Props) => {
  const [typedErrors, setTypedErrors] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('accessToken');
  const { error } = useSelector((store: RootState) => store.user);

  const handleSignIn = ({ email, password }: any) => dispatch(signin(email, password));

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: LOGIN_INITIAL_VALUES,
    validationSchema: LoginSchema,
    onSubmit: (_formValues) => {
      handleSignIn(_formValues);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !isAuthenticated && dispatch(reset());
  }, [dispatch, isAuthenticated]);

  const submit = () => {
    setTypedErrors(errors as { [key: string]: string });
    handleSubmit();
  };

  const typedValues = values as { [key: string]: string };
  const typedTouched = touched as { [key: string]: boolean };

  return (
    <>
      <FormContainer className="login-modal">
        {loginInputData.map(({ label, name }) => (
          <Input
            key={name}
            value={typedValues[name]}
            onChange={
              handleChange(name) as unknown as (e: string | React.ChangeEvent<unknown>) => void
            }
            type={name === LOGIN_FIELDS.password ? 'password' : 'text'}
            error={typedErrors[name]}
            touched={typedTouched[name]}
            label={label}
          />
        ))}
        <ErrorMessage>{error}</ErrorMessage>
      </FormContainer>
      <ButtonContainer className="login-modal">
        <Button
          label={LOGIN}
          className="full font-weight-small"
          color="#EF6B57"
          disabled={false}
          onClick={submit}
          id="login-button"
        />
        <Text>{OR}</Text>
        <Button
          label={LOGIN_GOOGLE}
          className="full outlined"
          color="#EF6B57"
          icon={Google}
          disabled={false}
          onClick={() => {
            dispatch(googleSignin());
          }}
        />
        <ButtonLink
          type="button"
          onClick={() => {
            dispatch(toggleAuthModalType({ type: 'forgot_password', back: 'login' }));
          }}
        >
          {FORGOT}
        </ButtonLink>
      </ButtonContainer>
    </>
  );
};

export default LoginForm;
