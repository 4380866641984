import styled from 'styled-components';

import { IsMobileProps } from 'Utils/types';
import { kinectAirColors, palette } from 'Utils/theme';

export const ModalContainer = styled.div<IsMobileProps>`
  background: ${kinectAirColors.background};
  color: ${kinectAirColors.font};
  width: ${(props) => (props.isMobile ? '100%' : '60%')};
  height: ${(props) => (props.isMobile ? '100%' : '700px')};
  border-radius: 16px;
  outline: none;
  overflow: auto;

  &.mobile {
    border-radius: 0px;
  }
`;

export const TitleContainer = styled.div`
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${kinectAirColors.background2};
  color: ${kinectAirColors.font};
  border-radius: 16px 16px 0px 0px;
  position: relative;

  &.mobile {
    border-radius: 0px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  &.sub {
    font-weight: 300;
    font-size: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  &.center {
    justify-content: center;
  }
  &.around {
    justify-content: space-around;
    width: 100%;
  }
  &.half-height {
    height: 50%;
  }
  &.half-width {
    width: 50%;
  }
  &.h15 {
    height: 15%;
    align-items: center;
    border-top: 1px ${palette.gray[80]} solid;
    padding: 10px;
    flex-wrap: wrap;
  }
  &.h25 {
    height: 25%;
    align-items: center;
    border-top: 1px ${palette.gray[80]} solid;
    flex-flow: column-reverse;
    padding: 10px 40px;
    gap: 10px;
  }
  &.pointer {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
`;

export const Img = styled.img`
  width: 150px;
  height: 20px;
  margin-bottom: 15px;
`;

export const Button = styled.button<{ color?: string; background?: string; isMobile?: boolean }>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  border-radius: 8px;
  background: ${(props) => props.background};
  color: ${(props) => props.color || palette.white};
  width: 100%;
  max-width: ${(props) => (props.isMobile ? 'none' : '250px')};
  padding: 5px 0px;
  cursor: pointer;
  height: fit-content;
  margin-left: ${(props) => (props.isMobile ? '0px' : '20px')};
  &.cancel {
    background: ${palette.white};
    border: 2px solid ${(props) => props.background};
    color: ${(props) => props.background};
    margin-left: 0px;
    margin-bottom: 0px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: auto;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
