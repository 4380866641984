import React, { useEffect, useState } from 'react';

import { confirmationTick, promoCodeTag } from 'Assets/Icons';
import { useMutation } from 'react-query';
import { validatePromoCode } from 'Components/CreditCard/CreditCardModal/services';
import { sendGAEvent } from 'Utils/utils';

import { StyledInput, Row, Text, Img, Button, Column } from './styles';

interface Props {
  ticketTypesID: number[];
  setPromoCodeId?: React.Dispatch<React.SetStateAction<number>>;
  setTicketsWithPromocodes: React.Dispatch<React.SetStateAction<number[]>>;
}

const PromoCodeInputApply = ({
  ticketTypesID,
  setPromoCodeId = () => 0,
  setTicketsWithPromocodes,
}: Props) => {
  const [value, setValue] = useState<string>('');
  const [isValidPromoCode, setIsValidPromoCode] = useState<boolean>(true);
  const [wasValidated, setWasValidated] = useState<boolean>(false);
  const partnerId = localStorage.getItem('partnerId');

  const { mutate: mutatePromoCode } = useMutation(validatePromoCode, {
    onSuccess: ({ data }) => {
      setIsValidPromoCode(data.ticket_types.length > 0);
      setWasValidated(true);
      if (data.ticket_types.length > 0) {
        setPromoCodeId(data.promocode_id);
        setTicketsWithPromocodes(data.ticket_types.map(Number));
        setValue('');
        sendGAEvent({ category: 'Promo Code', action: 'Promo Code Applied' });
      } else {
        sendGAEvent({ category: 'Promo Code', action: 'Promo Code No Valid' });
      }
    },
  });

  const handlePromoCode = () => {
    mutatePromoCode({
      partnerId: Number(partnerId),
      promoCode: value,
      ticketTypesID,
    });
  };

  useEffect(() => {
    if (value !== '') setWasValidated(false);
  }, [value]);

  return (
    <Column>
      <Row>
        <Text>Promo Code</Text>
        <Img className="margin" src={promoCodeTag} />
      </Row>
      <Row className="margin-top relative">
        <StyledInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          error={!isValidPromoCode}
          placeholder="Enter your code"
          id="promocode-apply-input"
        />
        <Button isTyping={value.length > 0} onClick={handlePromoCode} id="promocode-apply-button">
          Apply
        </Button>
        {wasValidated && (
          <Row className="absolute">
            {isValidPromoCode && <Img className="width" src={confirmationTick} />}
            <Text className={`span ${!isValidPromoCode ? 'error' : 'valid'}`}>
              {!isValidPromoCode ? 'Enter a valid discount code' : 'The coupon was applied'}
            </Text>
          </Row>
        )}
      </Row>
    </Column>
  );
};

export default PromoCodeInputApply;
