import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';

import useMobileView from 'hooks/useMobileView';
import { TicketType } from 'Components/TicketDetails/TicketType';
import { RootState } from 'store';
import { queryClient } from 'QueryClientConfig';
import { getData } from 'api/api';
import { nuveiTax1, nuveiTax2, achFee } from 'Utils/utils';
import PurchaseOrder from './PurchaseOrder';
import { PurchaseBankConnection } from './PurchaseBankConnection';
import { TaxesData } from './types';

import { Container, Wrapper } from './styles';

interface Props {
  ticketsIds: number[];
  currentPurchaseCart: any;
  setCurrentPurchaseCart: React.Dispatch<any>;
  eventInfo: any;
  totalQuantity: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>;
  setIsCreditCardModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreditCardModalOpen: boolean;
}

export default function TicketPurchase({
  setCurrentPurchaseCart,
  currentPurchaseCart,
  ticketsIds,
  totalQuantity,
  eventInfo,
  refetch,
  setIsCreditCardModalOpen,
  isCreditCardModalOpen,
}: Props) {
  const [ticketsWithPromocodes, setTicketsWithPromocodes] = useState<number[]>([]);
  const [fixedPrice, setFixedPrice] = useState<number>();
  const [totalTaxes, setTotalTaxes] = useState<number>();
  const [totalFees, setTotalFees] = useState<number>();
  const [tixologiFees, setTixologiFees] = useState<number>();
  const [nuveiFees, setNuveiFees] = useState<number>();
  const [totalWithNoDiscount, setTotalWithNoDiscount] = useState<number>();
  const [promoCodeApplied, setPromoCodeApplied] = useState<boolean>(false);
  const [promoCodeId, setPromoCodeId] = useState<number>(-2);
  const [queryChanged, setQueryChanged] = useState<boolean>(false);
  const [ticketTypeDic, setTicketTypeDic] = useState<{
    [key: string]: { quantity: number; promocode_id: string };
  }>({});
  const { plaidPublicToken } = useSelector(({ paymentInfo }: RootState) => paymentInfo);
  const isMobile = useMobileView();
  const isAuthenticated = !!localStorage.getItem('accessToken');

  const { data: promoCodeData, refetch: refetchPCData } = useQuery(
    'getPromocodes',
    () => getData(`/public/ticketing/promocodes/${promoCodeId}`),
    {
      enabled: (promoCodeId as number) > 0,
    },
  );

  const { data: taxData } = useQuery(
    'getTax',
    (): Promise<TaxesData> =>
      getData(`public/ticketing/ticket_types/taxes/${ticketsIds.join(',')}`),
    {
      enabled: !!ticketsIds?.length,
    },
  );

  // CreditCard modal
  useEffect(
    () => setIsCreditCardModalOpen(!plaidPublicToken && isAuthenticated),
    [plaidPublicToken, isAuthenticated],
  );

  useEffect(() => {
    if ((promoCodeId as number) > 0) refetchPCData();
  }, [promoCodeId]);

  useEffect(() => {
    if (taxData) {
      let promoCodeWasApplied = false;

      currentPurchaseCart?.reduce(
        (acc: any, item: any) => {
          if (ticketsWithPromocodes.find((id) => id === item.ID)) {
            if (promoCodeData && item.promocode_applied === false) {
              setPromoCodeApplied(true);
              promoCodeWasApplied = true;
              item.promocode_applied = true;
              if (promoCodeData.coupon_type === 'flat') {
                if (item.initial_price - promoCodeData.discount < 0) item.initial_price = 0;
                else item.initial_price -= promoCodeData.discount;
              } else if ((item.initial_price * (100 - promoCodeData.percentage)) / 100 < 0)
                item.initial_price = 0;
              else
                item.initial_price = (item.initial_price * (100 - promoCodeData.percentage)) / 100;
            } else if (promoCodeData && item.promocode_applied) {
              let fakePrice;
              if (promoCodeData.coupon_type === 'flat')
                fakePrice = item.price_no_discount - promoCodeData.discount;
              else fakePrice = (item.price_no_discount * (100 - promoCodeData.percentage)) / 100;
              if (fakePrice < item.initial_price && fakePrice < 0) item.initial_price = 0;
              else if (fakePrice < item.initial_price) item.initial_price = fakePrice;
            }
            acc.arrayOfTicketsWithPromocodes.push(item);
          } else {
            acc.arrayOfTickets.push(item);
          }
          return acc;
        },
        { arrayOfTicketsWithPromocodes: [], arrayOfTickets: [] },
      );

      const myTickets = [
        currentPurchaseCart?.reduce(
          (acc: any, ticket: any) => {
            return {
              ...acc,
              quantity: acc.quantity + ticket.quantity,
              price: acc.price + ticket.quantity * ticket.initial_price,
              tax: taxData.ticket_taxes[ticket.ID].tax_rate,
            };
          },
          { quantity: 0, price: 0, tax: 0 },
        ),
      ];

      const myFees = myTickets?.map(({ price, quantity }: any) => ({
        fee:
          price * (taxData.partner_royalties?.tixologi_primary_royalty / 100) +
          taxData.partner_royalties?.tixologi_fixed_primary_royalty * quantity,
      }));

      const myTaxes = myTickets?.map(({ price, tax }: any, index: number) => ({
        tax: (price + myFees[index].fee) * Number(tax),
      }));

      const myCart = myTickets?.reduce(
        (acc: any, { price }: any, index: number) => ({
          ...acc,
          taxes: acc.taxes + myTaxes[index].tax,
          fee: acc.fee + myFees[index].fee,
          price: acc.price + price,
        }),
        { fee: 0, taxes: 0, price: 0 },
      );
      setTixologiFees(myCart.fee);
      setTotalTaxes(myCart.taxes);
      setFixedPrice(myCart.price);

      const currentNuveiFees = (myCart.price + myCart.fee + myCart.taxes) * nuveiTax1 + nuveiTax2;
      setNuveiFees(plaidPublicToken ? achFee : currentNuveiFees);

      const currentTotalFees = myCart.fee + (plaidPublicToken ? achFee : currentNuveiFees);
      setTotalFees(currentTotalFees);

      if (!promoCodeWasApplied) {
        setTotalWithNoDiscount(myCart.price + currentTotalFees + myCart.taxes);
      }

      if (!queryChanged) {
        queryClient.setQueryData(['getPromocodes'], () => undefined);
        setQueryChanged(true);
      }
    }
  }, [currentPurchaseCart, taxData, ticketsWithPromocodes, promoCodeData, promoCodeApplied]);

  return (
    <Container className={isMobile ? 'isMobile' : ''}>
      <Container className={isMobile ? 'isMobile' : 'main-content'}>
        {!isMobile && (
          <Wrapper className="w65">
            <TicketType
              ticket={{
                ...currentPurchaseCart[0]?.template,
                name: currentPurchaseCart[0]?.name,
                initial_price: currentPurchaseCart[0]?.price_no_discount,
              }}
              event={eventInfo}
              isMobile={isMobile}
            />
          </Wrapper>
        )}
        <Wrapper className={isMobile ? 'w100' : 'w45'}>
          {!!plaidPublicToken && isAuthenticated ? (
            <PurchaseBankConnection
              eventInfo={eventInfo}
              totalAmount={Number(
                (Number(fixedPrice) + Number(totalTaxes) + Number(totalFees)).toFixed(2),
              )}
              totalDiscount={{
                amount: String(
                  Object.values(ticketTypeDic).reduce(
                    (acc: number, { discount_applied, number_of_applied_promocodes }: any) =>
                      acc + discount_applied * number_of_applied_promocodes,
                    0,
                  ),
                ),
                currency: 'USD',
              }}
              ourFees={{
                amount: String(tixologiFees),
                currency: 'USD',
                fees_rate: taxData?.partner_royalties?.tixologi_primary_royalty,
                fixed_fee: taxData?.partner_royalties?.tixologi_fixed_primary_royalty,
              }}
              tixologiFees={Number(tixologiFees)}
              tax={Number(totalTaxes)}
              taxes={{
                rate:
                  taxData?.ticket_taxes[currentPurchaseCart[0]?.ID] ||
                  taxData?.partner_royalties?.partner_default_tax,
                our_taxes: String(
                  Number(tixologiFees) *
                    (taxData?.ticket_taxes[currentPurchaseCart[0]?.ID].tax_rate ||
                      taxData?.partner_royalties?.partner_default_tax ||
                      0),
                ),
                vendor_taxes: String(
                  Number(totalTaxes) -
                    Number(tixologiFees) *
                      (taxData?.ticket_taxes[currentPurchaseCart[0]?.ID].tax_rate ||
                        taxData?.partner_royalties?.partner_default_tax ||
                        0),
                ),
              }}
              price={fixedPrice}
              ticketTypeDic={ticketTypeDic}
              ticketsWithPromocodes={ticketsWithPromocodes}
              nuveiFees={Number(nuveiFees)}
              serviceFee={nuveiTax1}
              fixedServiceFee={nuveiTax2}
            />
          ) : (
            <PurchaseOrder
              ticketsTypeIds={ticketsIds}
              currentPurchaseCart={currentPurchaseCart}
              setCurrentPurchaseCart={setCurrentPurchaseCart}
              totalQuantity={totalQuantity}
              eventInfo={eventInfo}
              refetch={refetch}
              setIsCreditCardModalOpen={setIsCreditCardModalOpen}
              isCreditCardModalOpen={isCreditCardModalOpen}
              setTicketsWithPromocodes={setTicketsWithPromocodes}
              fixedPrice={Number(fixedPrice)}
              totalTaxes={Number(totalTaxes)}
              totalFees={Number(totalFees)}
              tixologiFees={Number(tixologiFees)}
              nuveiFees={Number(nuveiFees)}
              taxData={taxData}
              promoCodeData={promoCodeData}
              promoCodeApplied={promoCodeApplied}
              promoCodeId={promoCodeId}
              setPromoCodeId={setPromoCodeId}
              ticketsWithPromocodes={ticketsWithPromocodes}
              ticketTypeDic={ticketTypeDic}
              setTicketTypeDic={setTicketTypeDic}
            />
          )}
        </Wrapper>
      </Container>
    </Container>
  );
}
