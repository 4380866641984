/* eslint-disable @typescript-eslint/no-explicit-any */
import { post } from 'api/api';

interface CreateTransferTokenProps {
  link_token: string;
}

export const createTransferToken = async (): Promise<CreateTransferTokenProps> => {
  try {
    return await post('public/ticketing/financial/ach/create_link_token', {
      client_id: process.env.REACT_APP_PLAID_CLIENT_ID,
    });
  } catch {
    throw Error('error');
  }
};
