import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${palette.white};

  &.empty {
    align-items: center;
    margin-top: ${(props) => (props.isMobile ? '30%' : '10%')};
    margin-bottom: ${(props) => (props.isMobile ? '20%' : '')};
  }
`;

export const Img = styled.img`
  height: 160px;
  margin-bottom: 15px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.gray[45]};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${palette.gray[40]};
  padding: 6px 10px;
`;

export const Text = styled.div<{ isMobile?: boolean }>`
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${palette.white};
  &.title {
    font-weight: 600;
    font-size: 18px;
  }
  &.date {
    font-weight: 500;
    font-size: 14px;
  }
  &.empty {
    color: ${palette.black};
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    width: ${(props) => (props.isMobile ? '75%' : '30%')};
  }
`;
