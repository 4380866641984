import React from 'react';

import { Template1 } from 'Components/Templates/Template1';

import { TicketImage } from './styles';

export const TicketType = ({ ticket, event, isMobile, onClick = () => {} }: any) => {
  const selectedType = () => {
    switch (ticket?.display_type) {
      case 'template0':
        return <Template1 event={event} ticket={ticket} />;
      default:
        return (
          <TicketImage
            className="screen"
            isMobile={isMobile}
            src={ticket?.image}
            onClick={onClick}
          />
        );
    }
  };
  return selectedType();
};
