import React, { useState } from 'react';

import { editCard } from 'Assets/Images';
import useMobileView from 'hooks/useMobileView';
import { CardContainer, Row, Img, CardNumber, Column, Text } from './styles';

interface Props {
  name: string;
  expirationDate: string;
  isDebit?: boolean;
  lastFour: number | string;
  setIsCardOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  fromModal?: boolean;
  fromSelect?: boolean;
  isAllCardsOpen?: boolean;
}

const CardDesign = ({
  name,
  expirationDate,
  isDebit,
  lastFour,
  setIsCardOpen = () => false,
  fromModal,
  fromSelect,
  isAllCardsOpen,
}: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  const isMobile = useMobileView();

  return (
    <CardContainer
      onClick={() => setIsCardOpen(true)}
      onMouseEnter={() => !fromModal && setHover(true)}
      onMouseLeave={() => !fromModal && setHover(false)}
      fromModal={fromModal}
      fromSelect={fromSelect}
      isMobile={isMobile}
    >
      <Row className="space">
        {hover && <Img className="edit" src={editCard} />}
        {/* TODO: wait for nuvei response */}
        {/* <Img
          src={type === 'VISA' ? visaLogo : type === 'MASTER' ? masterLogo : americanLogo}
          className={isAllCardsOpen && isMobile ? 'mobile' : ''}
        /> */}
        <div />
        <CardNumber isAllCardsOpen={isAllCardsOpen} isMobile={isMobile}>
          **** **** **** {lastFour}
        </CardNumber>
      </Row>
      <Column>
        {isDebit && <Text>{isDebit ? 'Debit Card' : 'Credit Card'}</Text>}
        <Row className="space">
          <Text className="name">{name}</Text>
          <Text className="name">{expirationDate}</Text>
        </Row>
      </Column>
    </CardContainer>
  );
};

export default CardDesign;
