import api from 'api/api';

export interface OpenOrderProps {
  amount: string;
  currency: string;
  newCard: boolean;
  modalUUID: string;
}

interface Cart {
  userId: string | null;
  partnerId: string | null;
  ticketQuantity: any[];
}

interface PurchaseBankProps {
  userId: string | null;
  tax: number;
  price: any;
  relatedTransaction?: string;
  partnerId: string;
  tixologiFees: number;
  nuveiFees: number;
  ticketTypeDic: { [key: string]: { quantity: number; promocode_id: string } };
  serviceFee: number;
  fixedServiceFee: number;
  ticketsWithPromocodes: number[];
  ourFees: any;
  sessionTokenId: string;
  totalDiscount: any;
  taxes: any;
  billingDetails: any;
}

interface AchProps {
  publicToken: string;
  account: string;
  amount: string;
  name: string;
  partnerId: string;
}

export const openOrder = ({ amount, currency, newCard, modalUUID }: OpenOrderProps) =>
  api.post(`/financial/open_order?new_card=${newCard}`, {
    amount,
    currency,
    unique_purchase_id: modalUUID,
    transaction_type: 'Auth',
  });

export const CreateCart = ({ partnerId, userId, ticketQuantity }: Cart) =>
  api.post(`/carts`, {
    partner_id: Number(partnerId),
    user_id: Number(userId),
    ticket_types: ticketQuantity,
  });

export const AddToCart = ({ partnerId, ticketQuantity }: Cart) =>
  api.put(`/carts/${partnerId}`, {
    ticket_types: ticketQuantity,
  });

export const CreateAchTransfer = ({ name, amount, account, publicToken, partnerId }: AchProps) =>
  api.post('/financial/ach/make-transfer', {
    public_token: publicToken,
    ach_account: account,
    amount,
    name,
    client_unique_id: partnerId,
  });

export const PurchaseWithBank = async ({
  userId,
  tax,
  price,
  partnerId,
  tixologiFees,
  nuveiFees,
  ticketTypeDic,
  serviceFee,
  fixedServiceFee,
  ticketsWithPromocodes,
  ourFees,
  sessionTokenId,
  totalDiscount,
  taxes,
  billingDetails,
}: PurchaseBankProps) => {
  if (partnerId === 'null') {
    // eslint-disable-next-line no-alert
    alert('Something went wrong');
    throw new Error('No partner Id');
  } else
    return api.post('/tickets/multiple_purchase', {
      ticket_types: ticketTypeDic,
      user_id: Number(userId),
      price: {
        amount: String(price.toFixed(2)),
        currency: 'USD',
      },
      taxes,
      total: {
        amount: String((price + tax + tixologiFees + nuveiFees).toFixed(2)),
        currency: 'USD',
      },
      our_fees: ourFees,
      total_discount: totalDiscount,
      provider_fees: {
        amount: String(nuveiFees.toFixed(2)),
        currency: 'USD',
        fixed_fee: fixedServiceFee,
        fees_rate: serviceFee,
      },
      client_unique_id: partnerId,
      applied_promo_codes: ticketsWithPromocodes,
      billing_details: billingDetails,
      payment_type: 'ach',
      session_token_id: sessionTokenId,
    });
};
