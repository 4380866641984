import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { TicketPurchase } from 'Components';
import { getData } from 'api/api';
import { RootState } from 'store';
import { Label } from 'Components/Common/GoBack/styles';
import { poweredTixologi } from 'Assets/Images';
import { GlobalTicket } from 'Utils/types';
import { convertUTCToDate } from 'Utils/utils';
import { PaymentOptions } from 'Views/AuthModal/PaymentOptions';
import { DayType, Event } from './types';
import { SLOT_DAY_DEFAULT } from './utils';

import { Container, Wrapper, UpperHeader, Icon, Img } from '../styles';

export default function PurchaseTickets() {
  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState<boolean>(false);
  const [currentPurchaseCart, setCurrentPurchaseCart] = useState<any>([]);
  const [updatedPurchaseCart, setUpdatedPurchaseCart] = useState([]);
  const [ticketsIds, setTicketsIds] = useState<number[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [slotDay, setSlotDay] = useState<DayType>(SLOT_DAY_DEFAULT);
  const { search } = useLocation();
  const slotId = new URLSearchParams(search).get('slot_id');
  const { event_id: eventId } = useParams<{ id: string; event_id: string }>();

  const { data: eventInfo, refetch } = useQuery(
    `getInfo-${eventId}`,
    (): Promise<Event> => getData(`/public/ticketing/events/${eventId}`),
  );
  const { secondaryColor } = useSelector(({ widgetData }: RootState) => widgetData.data);
  const { showPaymentOptions } = useSelector((state: RootState) => state.paymentInfo);

  useEffect(() => {
    const firstTicketType = eventInfo?.ticket_types[0];
    setCurrentPurchaseCart([
      {
        ...firstTicketType,
        quantity: 1,
        price_no_discount: firstTicketType?.initial_price,
        promocode_applied: false,
      },
    ]);
    if (eventInfo) {
      const _ticketsIds = eventInfo.ticket_types.map(({ ID }) => ID);
      setTicketsIds(_ticketsIds);
      const _slotDay = eventInfo.days.find(({ id }) => `${id}` === slotId);
      if (_slotDay) setSlotDay(_slotDay);
    }
  }, [eventInfo, isCreditCardModalOpen]);

  useEffect(() => {
    if (currentPurchaseCart) {
      const updatedPurchaseCart = currentPurchaseCart?.filter(
        (ticket: GlobalTicket) => ticket.quantity > 0,
      );
      setUpdatedPurchaseCart(updatedPurchaseCart);
    }
  }, [currentPurchaseCart]);

  useEffect(() => {
    let quantity = 0;
    updatedPurchaseCart?.forEach((ticket: GlobalTicket) => {
      quantity += ticket.quantity;
    });
    setTotalQuantity(quantity);
  }, [updatedPurchaseCart]);

  return (
    <Container>
      <Wrapper style={{ background: secondaryColor }}>
        {/* TODO: Move to const or theme */}
        <UpperHeader style={{ background: '#143A66' }}>
          <Label className="title width">{eventInfo?.name}</Label>
          <Label>
            {eventInfo?.time_slots
              ? `${moment(convertUTCToDate(slotDay?.start_date, eventInfo?.time_zone)).format(
                  'MMMM DD, YYYY hh:mm a',
                )} -
              ${moment(convertUTCToDate(slotDay?.end_date, eventInfo?.time_zone)).format(
                'hh:mm a',
              )}`
              : eventInfo?.days &&
                `${moment(
                  convertUTCToDate(eventInfo?.days[0]?.start_date, eventInfo?.time_zone),
                ).format('MMMM DD, YYYY hh:mm a')} -
              ${moment(
                convertUTCToDate(eventInfo?.days[0]?.end_date, eventInfo?.secondary_time_zone),
              ).format('hh:mm a')}`}
          </Label>
        </UpperHeader>
      </Wrapper>
      {showPaymentOptions ? (
        <PaymentOptions />
      ) : (
        <TicketPurchase
          ticketsIds={ticketsIds}
          setCurrentPurchaseCart={setUpdatedPurchaseCart}
          currentPurchaseCart={updatedPurchaseCart}
          totalQuantity={totalQuantity}
          eventInfo={eventInfo}
          refetch={refetch}
          setIsCreditCardModalOpen={setIsCreditCardModalOpen}
          isCreditCardModalOpen={isCreditCardModalOpen}
        />
      )}
      <Icon>
        <Img src={poweredTixologi} />
      </Icon>
    </Container>
  );
}
