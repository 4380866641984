import styled from 'styled-components';

export const FieldContainer = styled.div`
  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #f4f4f4;
  color: #4a5568;
  &.error {
    border: 1px solid red;
  }
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: white;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  right: 8px;
  bottom: 6px;
  position: absolute;
`;

export const CardNumber = styled.div`
  position: relative;
`;

export const ComplianceText = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;
