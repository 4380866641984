import { Dispatch } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAdditionalUserInfo,
} from 'firebase/auth';

import { setReceivingTicketInfo } from 'Slices/paymentInfoSlice';
import { setIsFromPurchase, toggleAuthModal } from 'Slices/authModal';
import { sendGAEvent } from 'Utils/utils';
import { ROUTES } from 'Utils/constants';
import { api, getData } from './api';
import { failure, request, setUser, success } from '../Slices/userSlice';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const me = () => (dispatch: Dispatch) => {
  getData(`/users/me`).then((data) => {
    localStorage.setItem('userId', data?.ID);
    dispatch(setReceivingTicketInfo({ name: data?.full_name, email: data?.email }));
    dispatch(success());
    dispatch(setIsFromPurchase(false));
    sendGAEvent({ category: 'Login', action: 'Login success' });
    if (window.location.pathname.includes(ROUTES.RECEIVE_TRANSFER)) {
      sendGAEvent({ category: 'Receive Ticket', action: 'Receive ticket login success' });
    }
  });
};

const loginRequest = (userId: string) => (dispatch: any) => dispatch(me());

const signUpRequest =
  (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    gender: string,
    dateOfBirth: Date,
    phoneNumber: string,
    successCallback: () => void,
  ) =>
  (dispatch: any) => {
    const fullName = `${firstName} ${lastName}`;
    const dob = dateOfBirth.toString() || '0001-01-01';

    api
      .post('/users/simple-signup', {
        firebase_id: userId,
        full_name: fullName,
        email,
        gender,
        date_of_birth: dob,
        phone_number: phoneNumber,
      })
      .then(({ data }) => {
        localStorage.setItem('accessToken', data?.login_token);
        dispatch(setReceivingTicketInfo({ name: data?.full_name, email: data?.email }));
        successCallback();
        dispatch(setIsFromPurchase(false));
        dispatch(loginRequest(userId));
        sendGAEvent({ category: 'Create Account', action: 'Create account success' });
      });
  };

const signUpRequestGoogle =
  (userId: string, email: string | null, displayName: string | null, successCallback: () => void) =>
  (dispatch: any) => {
    api
      .post('/users/simple-signup', {
        firebase_id: userId,
        email,
        full_name: displayName,
      })
      .then(({ data }) => {
        localStorage.setItem('accessToken', data?.login_token);
        localStorage.setItem('userId', data?.ID);
        dispatch(setReceivingTicketInfo({ name: data?.full_name, email: data?.email }));
        successCallback();
        dispatch(loginRequest(userId));
        sendGAEvent({ category: 'Create Account', action: 'Create account success' });
      });
  };

const signup =
  (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    gender: string,
    dateOfBirth: Date,
    phoneNumber: string,
  ) =>
  (dispatch: any) => {
    dispatch(request());
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        localStorage.setItem('accessToken', (user as any).accessToken);
        dispatch(setUser(user));
        dispatch(toggleAuthModal(false));
        dispatch(
          signUpRequest(
            user.uid,
            firstName,
            lastName,
            email,
            gender,
            dateOfBirth,
            phoneNumber,
            () => {
              dispatch(success());
            },
          ),
        );
      })
      .catch(() => {
        dispatch(failure('Account information incorrect. Try again'));
      });
  };

const signin = (email: string, password: string) => (dispatch: any) => {
  dispatch(request());
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const { user } = userCredential;
      localStorage.setItem('accessToken', (user as any).accessToken);
      dispatch(setUser(user));
      dispatch(toggleAuthModal(false));
      dispatch(loginRequest(user.uid));
    })
    .catch(() => {
      dispatch(failure('Account information incorrect. Try again'));
    });
};

const googleSignin = () => (dispatch: any) => {
  dispatch(request());
  signInWithPopup(auth, provider)
    .then((result) => {
      const { user } = result;
      localStorage.setItem('accessToken', (user as any).accessToken);
      dispatch(setUser(user));
      dispatch(toggleAuthModal(false));
      if (getAdditionalUserInfo(result)?.isNewUser) {
        dispatch(
          signUpRequestGoogle(user.uid, user.email, user.displayName, () => dispatch(success())),
        );
      } else dispatch(loginRequest(result.user.uid));
    })
    .catch(() => {
      dispatch(failure('Account information incorrect. Try again'));
    });
};

const logout = (successCallback: () => void) => (dispatch: Dispatch) => {
  dispatch(request());
  signOut(auth)
    .then(() => {
      dispatch(success());
      successCallback();
    })
    .catch((error) => {
      dispatch(failure(error.message));
    });
};

export { auth, googleSignin, signin, signup, logout };
