import styled from 'styled-components';
import { PaymentTextProps } from './types';

export const PaymentOption = styled.div`
  align-items: center;
  background: #29619e;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 171px;
  width: 342px;
`;

export const PaymentText = styled.div<PaymentTextProps>`
  font-size: 16px;
  font-weight: ${({ isTitle }) => (isTitle ? 600 : 400)};
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  width: 80%;
  &.fee {
    font-size: 12px;
  }
`;

export const Icon = styled.img`
  width: 36px;
  height: 36px;
`;
