import styled from 'styled-components';

export const Navigation = styled.div`
  width: 100%;
  padding: 0px 30px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  gap: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div<{ primary?: string; fontColor?: string }>`
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  color: ${(props) => props.fontColor};
  &.selected {
    background: ${(props) => props.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }
  &.capitalize {
    text-transform: capitalize;
  }
  &.mobile {
    margin-bottom: 20px;
    font-size: 22px;
  }
`;

export const Container = styled.div`
  background-color: #f3f3f3;
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -550px;
  z-index: 99;
  border-radius: 0px 25px 25px 0px;
  transition: all 0.6s ease;
  &.active {
    left: 0;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 30px;
`;

export const Wrapper = styled.div`
  &.margin {
    margin-bottom: 30px;
  }
`;
