import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useMobileView from 'hooks/useMobileView';
import { sendGAEvent } from 'Utils/utils';
import { BillingInfo, ReceivingTicketInfo } from 'Utils/types';
import Input from '../Input';

import { Container, MaxWidthWrap, Row, Title } from './styles';

const inputs = [
  { label: 'address line 1', placeholder: 'Address Line 1', property: 'addressLine1' },
  { label: 'address line 2', placeholder: 'Address Line 2', property: 'addressLine2' },
  { label: 'postal code', placeholder: '00000', property: 'postalCode' },
  { label: 'country', placeholder: 'Select Country', isSelect: true, property: 'country' },
  {
    label: 'state / district',
    placeholder: 'Select State or District',
    isSelect: true,
    property: 'state',
  },
  { label: 'city', placeholder: 'Select City', isSelect: true, property: 'city' },
  {
    label: 'phone number',
    placeholder: '+00 000 000 0000',
    isPhoneNumber: true,
    property: 'phone',
  },
  {
    label: 'email',
    placeholder: 'email@email.com',
    property: 'email',
  },
];

interface Props {
  setInputData: React.Dispatch<React.SetStateAction<BillingInfo>>;
  inputData: BillingInfo;
  receivingTicketInfoData: ReceivingTicketInfo;
}

const BillingDetails = ({ setInputData, inputData, receivingTicketInfoData }: Props) => {
  const [options, setOptions] = useState<any>({ country: null, state: null, city: null });
  const dispatch = useDispatch();
  const isMobile = useMobileView();

  useEffect(() => {
    dispatch(
      setInputData({
        ...inputData,
        city: options.city?.value,
        country: options.country?.value,
        state: options.state?.value,
        email: receivingTicketInfoData?.email,
      }),
    );
  }, [options]);

  useEffect(() => {
    sendGAEvent({ category: 'Purchase', action: 'Billing Info' });
  }, []);

  return (
    <Container isMobile={isMobile}>
      <MaxWidthWrap>
        <Row className="margin">
          <Title>Billing details</Title>
        </Row>
        {inputs.map(({ label, placeholder, isPhoneNumber, isSelect, property }) => (
          <Input
            key={label}
            label={label}
            placeholder={placeholder}
            isPhoneNumber={isPhoneNumber}
            isSelect={isSelect}
            setOptions={setOptions}
            options={options}
            property={property}
            setInputData={setInputData}
            inputData={inputData}
          />
        ))}
      </MaxWidthWrap>
    </Container>
  );
};

export default BillingDetails;
