import { queryClient } from 'QueryClientConfig';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useMobileView from 'hooks/useMobileView';
import { sendGAEvent } from 'Utils/utils';
import { Card } from 'Utils/types';
import CardDesign from '../CardDesign';
import { updatePrimary } from '../CreditCardModal/services';

import {
  Wrapper,
  Title,
  Row,
  Button,
  CheckBox,
  Filled,
  Container,
  Label,
  StyledInput,
} from './styles';

interface Props {
  primaryCard?: Card;
  cards?: Card[];
  isAllCardsOpen: boolean;
  setIsAllCardsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectedPressed: boolean;
  setIsSelectedPressed: React.Dispatch<React.SetStateAction<boolean>>;
  setCvv: React.Dispatch<React.SetStateAction<string>>;
  cvv: string;
}

const PurchaseWithCard = ({
  primaryCard,
  cards,
  isAllCardsOpen,
  setIsAllCardsOpen,
  isSelectedPressed,
  setIsSelectedPressed,
  setCvv,
  cvv,
}: Props) => {
  const [selected, setSelected] = useState<string>('');
  const userId = localStorage.getItem('userId');
  const isMobile = useMobileView();

  const { mutate: mutatePrimary } = useMutation(updatePrimary, {
    onSuccess: () =>
      queryClient.setQueryData(['getCards'], (oldData: any) => {
        setIsSelectedPressed(false);
        setIsAllCardsOpen(false);
        return oldData.map((card: Card) => {
          if (card.is_primary) card.is_primary = false;
          if (card.id === selected) card.is_primary = true;
          return card;
        });
      }),
  });

  useEffect(() => {
    if (isSelectedPressed) mutatePrimary({ userId, cardId: selected });
  }, [isSelectedPressed]);

  useEffect(() => {
    if (primaryCard) setSelected(primaryCard.id);
  }, [primaryCard]);

  useEffect(() => {
    sendGAEvent({ category: 'Purchase', action: 'Card Cvv' });
  }, []);

  return (
    <Wrapper
      className={isAllCardsOpen ? 'overflow' : ''}
      isMobile={isMobile}
      isAllCardsOpen={isAllCardsOpen}
    >
      {isAllCardsOpen ? (
        cards?.map(({ name_on_card, expiry_month, last4, funding_type, id, expiry_year }: Card) => (
          <Row
            onClick={() => setSelected(id)}
            className={`checkbox-container pointer ${selected === id ? 'selected' : ''}`}
            checked={selected === id}
          >
            <CheckBox checked={selected === id}>{selected === id && <Filled />}</CheckBox>
            <CardDesign
              name={name_on_card}
              expirationDate={`${expiry_month}/${expiry_year}`}
              lastFour={last4}
              isDebit={funding_type === 'debit'}
              fromModal
              fromSelect
              isAllCardsOpen={isAllCardsOpen}
            />
          </Row>
        ))
      ) : (
        <>
          {!!primaryCard && (
            <>
              <Title>primary Payment Method</Title>
              <Row className="primary">
                <CardDesign
                  name={primaryCard.name_on_card}
                  expirationDate={`${primaryCard.expiry_month}/${primaryCard.expiry_year}`}
                  lastFour={primaryCard.last4}
                  isDebit={primaryCard.funding_type === 'debit'}
                  fromModal
                />
                <Container>
                  <Label>Cvv</Label>
                  <StyledInput value={cvv} onChange={(e) => setCvv(e.target.value)} maxLength={4} />
                </Container>
              </Row>
            </>
          )}
          {!!cards?.length && (
            <Button className="anotherCard" onClick={() => setIsAllCardsOpen(true)}>
              Select Another Card
            </Button>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default PurchaseWithCard;
