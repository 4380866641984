import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { exit } from 'Assets/Images';
import { Button } from 'Components/CreditCard/CreditCardModal/styles';
import { RootState } from 'store';
import { reset } from 'Slices/paymentInfoSlice';
import useMobileView from 'hooks/useMobileView';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { queryClient } from 'QueryClientConfig';
import moment from 'moment';
import { ROUTES } from 'Utils/constants';
import { convertUTCToDate } from 'Utils/utils';
import {
  modalStyle,
  ModalContainer,
  Title,
  TitleContainer,
  Img,
  Wrapper,
  Text,
  Column,
  TextContainer,
} from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  image?: string;
  confirmation?: string;
  span?: string;
  closeButton?: boolean;
  fromPurchase?: boolean;
  orderNumber?: string;
}

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  title,
  image,
  confirmation,
  span,
  closeButton,
  fromPurchase,
  orderNumber,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isMobile = useMobileView();
  const slotId = new URLSearchParams(search).get('slot_id');
  const { event_id: eventId } = useParams<{ event_id: string }>();
  const { secondaryColor, primaryFontColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );
  const eventInfo: any = queryClient.getQueryData(`getInfo-${eventId}`);

  const selectedEvent = eventInfo?.days?.find(({ id }: any) => `${id}` === slotId);

  const closeModal = () => {
    window.top?.postMessage('purchase complete', `${process.env.REACT_APP_KINECT_WEBSITE}`);
  };

  return (
    <Modal open={isOpen} onClose={closeModal} sx={modalStyle}>
      <ModalContainer isMobile={isMobile}>
        <TitleContainer isMobile={isMobile}>
          <Title>{title}</Title>
        </TitleContainer>
        <Wrapper>
          <Img className="main" src={image} />
          <Column className="center max-width">
            {fromPurchase ? (
              <TextContainer className={isMobile ? 'mobile' : ''}>
                <Text className="font-weight medium-large">Order Number</Text>
                <Text className="medium-large">{orderNumber}</Text>
              </TextContainer>
            ) : (
              <Text>{confirmation}</Text>
            )}
            <Text className="span">{span}</Text>
          </Column>
          {!!slotId && (
            <Column className="center max-width shadow">
              <Text className="span">Purchase more tickets for timeslot</Text>
              <Title className="span">
                {`${moment(
                  convertUTCToDate(selectedEvent?.start_date, eventInfo?.time_zone),
                ).format('hh:mm a')} -
              ${moment(convertUTCToDate(selectedEvent?.end_date, eventInfo?.time_zone)).format(
                'hh:mm a',
              )}`}
              </Title>
              <Button
                color={primaryFontColor}
                background={secondaryColor}
                onClick={() => {
                  setIsOpen(false);
                  dispatch(reset());
                  history.push(`${ROUTES.BUY_TICKETS}/${eventId}?slot_id=${slotId}`);
                }}
              >
                Add Tickets
              </Button>
            </Column>
          )}
          {closeButton && !slotId && (
            <Button
              // TODO: Move to const or theme
              color="#FFFFFF"
              background="#EF6B57"
              onClick={closeModal}
            >
              {fromPurchase ? 'Finish' : 'Close'}
            </Button>
          )}
        </Wrapper>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
