import styled from 'styled-components';

import { kinectAirColors } from 'Utils/theme';

export const Container = styled.div`
  width: 100%;
  min-width: 350px;
  padding: 30px 0;

  &.small-container {
    width: 40%;
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: auto;
  outline: none;
  position: relative;
`;

export const ExitButton = styled.img`
  position: absolute;
  margin: auto 0;
  right: 30px;
  cursor: pointer;
  height: 40px;
`;

export const ModalContainer = styled.div<{ isMobile?: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : '60%')};
  height: ${(props) => (props.isMobile ? '100%' : '700px')};
  background-color: ${kinectAirColors.background};
  color: ${kinectAirColors.font}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${(props) => (props.isMobile ? '' : '25px')};
  box-shadow: 24px 24px 24px rgba(126, 126, 126, 0.25);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: ${(props) => (props.isMobile ? '' : '50px 0')};

  &.wide {
    width: ${(props) => (props.isMobile ? '100%' : '80%')};
    height: ${(props) => (props.isMobile ? '100%' : '80%')};
    max-width: 1000px;
    overflow: scroll;
  }
  &.small {
    height: ${(props) => (props.isMobile ? '100%' : '55%')};
  }

  &.mobile {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

export const ModalTitle = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  background-color: ${kinectAirColors.background2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;

  &.transfer {
    min-height: 65px;
  }
`;
