import styled from 'styled-components';

import { palette, shadowContainer } from 'Utils/theme';

export const CardContainer = styled.div<{
  fromModal?: boolean;
  fromSelect?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.isMobile ? '220px' : '325px')};
  height: ${(props) => (props.isMobile ? '125px' : '180px')};
  background: linear-gradient(270deg, #262626 28.46%, #6b6b6b 102.71%);
  box-shadow: -12px -20px 25px ${shadowContainer};
  border-radius: 16px;
  padding: 20px;
  position: relative;
  cursor: ${(props) => (props.fromSelect ? 'pointer' : props.fromModal ? 'auto' : 'pointer')};
  margin-bottom: ${(props) => !props.fromSelect && '10px'};

  &.editCard {
    padding: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  &.space {
    justify-content: space-between;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${palette.white};
  &.name {
    font-weight: 500;
    font-size: 18px;
  }
`;

export const CardNumber = styled.div<{ isAllCardsOpen?: boolean; isMobile: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.isAllCardsOpen && props.isMobile ? '18px' : '20px')};
  display: flex;
  align-items: center;
  color: ${palette.white};
  text-shadow: 0px 5.40741px 5.40741px rgba(0, 0, 0, 0.25);
`;

export const Img = styled.img`
  width: 30%;
  &.edit {
    width: 30px;
    position: absolute;
    right: -9px;
    top: -9px;
  }
  &.mobile {
    width: 28%;
  }
`;
