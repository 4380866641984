import styled from 'styled-components';
import { palette, shadowContainer } from 'Utils/theme';

export const TicketSummary = styled.div<{ isMobile: boolean }>`
  width: 100%;
  background-color: ${palette.gray[20]};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  height: ${(props) => (props.isMobile ? 'auto' : '1000px')};
  max-width: 350px;
`;

export const NotificationContainer = styled.div`
  margin: 40px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Text = styled.label`
  font-size: 12px;
  letter-spacing: -0.02em;
  letter-spacing: -0.02em;
  margin: 0;
  &.xlarge {
    font-size: 16px;
  }

  &.small {
    font-size: 10px;
  }
  &.subtitle {
    text-transform: uppercase;
  }
  &.textModal {
    text-align: center;
    font-size: 16px;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 14px;
  text-align: left;

  &.title {
    font-size: 18px;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${palette.white};
  box-shadow: 12px 20px 25px ${shadowContainer};
  border-radius: 16px;
  margin-top: 18px;
  font-weight: 300;
  padding: 20px;
`;

export const ContainerCard = styled.div`
  margin-bottom: 40px;
`;

export const ContainerText = styled.div`
  margin-bottom: 15px;

  &.contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &.textModal {
    display: flex;
    justify-content: center;
  }
  &.mobile {
    text-align: center;
  }
`;

export const Title = styled.label`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;

  &.large {
    font-size: 22px;
  }

  &.small {
    align-items: center;
  }
`;

export const Image = styled.img`
  box-sizing: border-box;
  margin-bottom: 16px;
  width: 100%;
  padding: 0 42px;
  max-width: 680px;
  border-radius: 18px;

  &.transaction {
    width: 40vh;
  }
`;

export const Header = styled.div<{ isMobile: boolean; isUpperHeader: boolean }>`
  position: relative;
  width: 100%;
  color: ${palette.white};
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: ${(props) => (props.isUpperHeader && props.isMobile ? '15px' : '30px')};
  box-shadow: ${(props) =>
    props.isUpperHeader && props.isMobile ? '0px 10px 20px rgb(0 0 0 / 10%)' : 'none'};
`;

export const Wrapper = styled.div<{ isMobile?: boolean }>`
  background-color: ${palette.white};
  border-radius: 16px;
  margin-top: ${(props) => (props.isMobile ? 0 : 18)};
  font-weight: 300;
  position: relative;
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: ${palette.gray[20]};
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  max-width: 650px;

  &.white {
    background-color: ${palette.white};
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  }

  &.gray {
    background-color: ${palette.gray};
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerTitle = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.gap {
    gap: 30px;
  }

  &.small-gap {
    gap: 8px;
  }

  &.padding-right {
    padding-right: 62px;
  }
`;

export const ContainerTransaction = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.isMobile ? '1fr' : '1fr 2fr')};
  gap: 70px;
  padding: ${(props) => (props.isMobile ? '0' : '0 32px')};
  &.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
