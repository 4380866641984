import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0px;
  position: relative;

  &.small {
    width: 30%;
  }
`;

export const InputContainer = styled.div`
  height: 45px;
  width: 100%;
  border-radius: 8px;
  background-color: ${palette.white};
  position: relative;
  border: solid 1px ${palette.white};
  cursor: pointer;

  &.gray {
    background-color: ${palette.gray[30]};
    border: 1px solid ${palette.gray[100]};
  }

  &.open {
    border-radius: 8px 8px 0 0;
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
`;

export const Span = styled.span`
  display: grid;
  align-items: center;
  color: ${palette.black};
  height: 100%;
  min-height: 40px;
  padding: 0 14px;
  font-size: 14px;

  &.placeholder {
    opacity: 0.5;
  }

  &.option {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &:first-child {
      border: none;
    }

    &:hover {
      background-color: ${palette.gray[25]};
    }
  }
`;

export const DropdownContainer = styled.span`
  position: absolute;
  width: calc(100% + 3px);
  left: -1px;
  background-color: ${palette.gray[50]};
  top: calc(100% + 1px);
  border-radius: 0 0 1rem 1rem;
  z-index: 100;
  overflow: auto;
  max-height: 20rem;
`;

export const Button = styled.button`
  position: absolute;
  border-radius: 0 8px 8px 0;
  top: -1px;
  right: -2px;
  height: calc(100% + 2px);
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.gray[90]};
  border: solid 1px ${palette.gray[90]};
  cursor: pointer;

  &.open {
    border-radius: 0 8px 0 0;
  }
`;

export const Icon = styled.img`
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
`;
