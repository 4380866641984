import styled from 'styled-components';

import { kinectAirColors, palette } from 'Utils/theme';

import { Wrapper as Wp, TitleContainer as TC, Img as StyledImg } from '../styles';

export { Title, modalStyle, Text, Img as CloseIcon } from '../styles';

export const Wrapper = styled(Wp)`
  width: 70%;
  justify-content: center;
  gap: 24px;
`;

export const TitleContainer = styled(TC)`
  width: 100%;
`;

export const Img = styled(StyledImg)`
  width: 65px !important;
`;

export const ModalContainer = styled.div`
  width: 60%;
  height: 700px;
  background: ${kinectAirColors.background};
  border-radius: 24px;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 730px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${palette.gray[80]};
  padding: 32px 0;
  justify-content: center;
  display: flex;
`;

export const TicketInfo = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
`;

export const TicketsInfoContainer = styled.div``;
