import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

interface AuthModalState {
  isOpen: boolean;
  isFromPurchase: boolean;
  type: string;
  back: string;
}

export const initialState: AuthModalState = {
  isOpen: false,
  isFromPurchase: false,
  type: 'login',
  back: '',
};

export const loginModalSlice = createSlice({
  name: 'authModal',
  initialState,
  reducers: {
    toggleAuthModal: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    toggleAuthModalType: (state, action: PayloadAction<{ back?: string; type: string }>) => {
      state.back = action.payload.back || '';
      state.type = action.payload.type;
    },
    setIsFromPurchase(state, action) {
      state.isFromPurchase = action.payload;
    },
  },
});

export const { toggleAuthModal, toggleAuthModalType, setIsFromPurchase } = loginModalSlice.actions;

export const selectIsOpen = (state: RootState) => state.authModal.isOpen;

export default loginModalSlice.reducer;
