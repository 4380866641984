import React from 'react';

import { emailSent } from 'Assets/Images';
import { SuccessWrapper, SuccessImage, Center, SuccessTitle } from './styles';

export default function EmailSentModal() {
  return (
    <>
      <SuccessWrapper>
        <SuccessTitle>Reset Password</SuccessTitle>
        <SuccessImage src={emailSent} />
        <Center>
          <div>We have sent you an email to reset your password.</div>
          <div>Please check your email to continue.</div>
        </Center>
      </SuccessWrapper>
    </>
  );
}
