import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div<{ fromCart?: boolean }>`
  position: absolute;
  top: ${(props) => (props.fromCart ? '24px' : '18px')};
  display: flex;
  gap: 18px;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 14px;
  text-align: left;

  &.title {
    font-size: 18px;
  }
  &.width {
    max-width: 250px;
    text-align: center;
  }
`;

export const Icon = styled.div`
  width: 38px;
  height: 38px;
  background-color: ${palette.white};
  border-radius: 50%;
  display: grid;
  justify-items: center;
  align-items: center;
  cursor: pointer;
`;

export const Img = styled.img`
  width: 20px;
  height: 20px;
`;
