import styled from 'styled-components';

import { palette, shadow } from '../../Utils/theme';

export const Container = styled.div`
  padding: 20px 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const EventInfo = styled.div<{ isImage: boolean }>`
  background-color: ${palette.gray[10]};
  width: 100%;
  height: ${(props) => (props.isImage ? '35%' : '100%')};
  position: absolute;
  bottom: 0;
  border-radius: ${(props) => (props.isImage ? '0 0 8px 8px' : '8px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EventContainer = styled.div<{ primaryColor: string; isImage: boolean }>`
  position: relative;
  width: 280px;
  height: ${(props) => (props.isImage ? '280px' : '98px')};
  border-radius: 8px;
  box-shadow: ${shadow};
  cursor: pointer;

  &:hover ${EventInfo} {
    color: ${palette.white};
    background: ${(props) => props.primaryColor};
  }
`;

export const EventImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

export const Label = styled.div`
  font-weight: 300;
  font-size: 14px;
  text-align: center;

  &.title {
    font-size: 18px;
  }
`;
