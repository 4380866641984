import React from 'react';

import useMobileView from 'hooks/useMobileView';
import { ReceivingTicketInfo } from 'Utils/types';
import Input from '../Input';
import { Wrapper, InfoContainer } from './styles';

const inputs = [
  { label: 'name of ticket holder', placeholder: 'Enter name', property: 'name' },
  {
    label: 'email to receive tickets',
    placeholder: 'email@email.com',
    property: 'email',
    isEmail: true,
  },
];

interface Props {
  setInputData: React.Dispatch<React.SetStateAction<ReceivingTicketInfo>>;
  inputData: ReceivingTicketInfo;
}

const RecevingTickets = ({ setInputData, inputData }: Props) => {
  const isMobile = useMobileView();

  return (
    <Wrapper isMobile={isMobile}>
      <InfoContainer>
        {inputs.map(({ label, placeholder, property, isEmail }) => (
          <Input
            key={label}
            label={label}
            placeholder={placeholder}
            property={property}
            setInputData={setInputData}
            inputData={inputData}
            isEmail={isEmail}
          />
        ))}
      </InfoContainer>
    </Wrapper>
  );
};

export default RecevingTickets;
