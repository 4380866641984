import { useMemo } from 'react';
import { QueryKey, useIsFetching, useIsMutating } from 'react-query';
import { useSelector } from 'react-redux';

import { queryClient } from 'QueryClientConfig';
import { RootState } from 'store';

const useLoading = (): boolean => {
  const getQueryKeysExcept = (excludedKeys: Set<QueryKey>) =>
    queryClient
      .getQueryCache()
      .getAll()
      .map((query) => query.queryKey)
      .filter((queryKey) => !excludedKeys.has(queryKey));

  const isMutating = useIsMutating();
  const isFetching = useIsFetching(getQueryKeysExcept(new Set(['getEvent', 'getWidgetData'])));
  const { loading } = useSelector(({ user }: RootState) => user);

  const isLoading = useMemo(
    () => loading || !!isFetching || !!isMutating,
    [isFetching, isMutating, loading],
  );

  return isLoading;
};

export default useLoading;
