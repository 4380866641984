import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { Container } from './styles';

interface Props {
  label: string | JSX.Element | Element;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
  id?: string;
  icon?: string;
}

export default function Button({ label, className, onClick, disabled, color, id, icon }: Props) {
  const { primaryColor, calloutsColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );

  return (
    <Container
      id={id}
      className={`${className} ${disabled ? 'disabled' : ''}`}
      onClick={() => !disabled && onClick?.()}
      color={color || primaryColor}
      textColor={calloutsColor}
    >
      {icon && <img src={icon} />}
      {label}
    </Container>
  );
}
