import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${palette.gray[50]};
  width: 100%;
  height: 100%;
  box-shadow: ${(props) => (props.isMobile ? 'none' : '-10.6069px 0px 13px rgb(0 0 0 / 12%)')};
`;

export const InnerContainer = styled.div<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? '28px' : '46px')};
`;

export const InformationContainer = styled.div`
  // border-top: 1px solid ${palette.black};
  border-bottom: 1px solid ${palette.black};
`;

export const Filled = styled.img<{ primaryColor: string }>`
  background: ${(props) => props.primaryColor};
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const CheckBox = styled.div<{ checked: boolean; primaryColor: string }>`
  border: 1px solid ${(props) => props.primaryColor};
  border-radius: 2px;
  max-width: 20px;
  max-height: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: 10px;
  position: relative;
  margin-bottom: -5px;
  &.pointer {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.margin-top {
    margin-top: 12px;
  }
  &.margin-bottom {
    margin-bottom: 24px;
  }
  &.ticket-bottom {
    margin-bottom: 12px;
  }
  &.conditions {
    margin-bottom: 20px;
    justify-content: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
  }
  &.border-top {
    border-top: 1px solid ${palette.black};
  }
  &.center {
    align-items: center;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${palette.black};
  &.bold {
    font-weight: 600;
    font-size: 18px;
  }
  &.event {
    font-size: 16px;
    margin-top: 24px;
  }
  &.ticket {
    margin-top: 12px;
  }
  &.tax {
    margin-bottom: 24px;
  }
  &.total {
    margin-bottom: 12px;
  }
  &.applied {
    color: #9a9a9a;
    text-decoration: line-through;
  }
`;

export const ContainerText = styled.label`
  margin-top: 10px;
`;

export const Label = styled.label`
  font-size: 16px;
  opacity: 0.7;
  font-weight: 600;

  &.conditions {
    opacity: 2;
    font-weight: 200;
    padding-top: 10px;
    font-size: 14px;
  }
  &.conditions-terms {
    opacity: 2;
    font-weight: 200;
    padding-top: 10px;
    font-size: 14px;
    color: #2f80ed;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Link = styled.a`
  opacity: 2;
  font-weight: 200;
  padding-top: 10px;
  font-size: 14px;
  color: #2f80ed;
  text-decoration: underline;
  cursor: pointer;
`;
