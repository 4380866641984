import { kinectAirColors } from 'Utils/theme';
import styled from 'styled-components';

export const StyledInput = styled.input<{ error: boolean }>`
  all: unset;
  width: 100%;
  padding: 8px 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #7d7d7d;
  background: #f9f9f9;
  border: 1px solid ${(props) => (props.error ? '#EB5757' : '#d9d9d9')};
  border-radius: 4px 0px 0px 4px;
`;

export const Row = styled.div`
  display: flex;
  &.margin-top {
    margin-top: 10px;
  }
  &.relative {
    position: relative;
  }
  &.absolute {
    position: absolute;
    bottom: -16px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.div<{ isTyping: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  width: 150px;
  background: ${kinectAirColors.callout};
  border: 1px solid ${kinectAirColors.callout};
  border-radius: 0px 4px 4px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.isTyping ? '#4E4E4E' : '#ffffff')};
  cursor: pointer;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;

  &.span {
    font-size: 10px;
    margin-left: 6px;
  }
  &.error {
    color: #eb5757;
  }
  &.valid {
    color: #27ae60;
  }
`;

export const Img = styled.img`
  &.margin {
    margin-left: 10px;
  }
  &.width {
    width: 11px;
  }
`;
