import styled from 'styled-components';

import { palette, gradient, checkBoxOrange } from 'Utils/theme';

export const Container = styled.div<{ color?: string; textColor: string }>`
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color || gradient};
  border-radius: 8px;
  color: ${({ textColor }) => textColor};
  cursor: pointer;
  height: 45px;
  text-align: center;
  margin: 5px 0px;
  min-width: 200px;
  gap: 5px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.full {
    width: 100%;
  }

  &.medium {
    width: 30%;
  }

  &.center {
    width: 50%;
  }

  &.outlined {
    border: 2px solid ${(props) => props.color || checkBoxOrange};
    background: none;
    color: ${(props) => props.color || checkBoxOrange};
  }
  &.isMobile {
    min-width: 74px;
  }
  &.select {
    font-size: 14px;
  }
  &.margin-bottom {
    margin-bottom: 12px;
  }
  &.secondary {
    background: ${palette.white};
    border: 2px solid ${(props) => props.color || checkBoxOrange};
    color: ${(props) => props.color || gradient};
  }
  &.mobile {
    position: absolute;
    bottom: 30px;
  }

  img {
    height: 27px;
    width: 27px;
  }

  &.register-form {
    min-width: unset;
    width: 150px;
  }
  &.register-form-google {
    min-width: unset;
    width: 225px;
  }
  @media screen and (max-width: 1000px) {
    &.register-form,
    &.register-form-google {
      width: 80%;
      min-width: 300px;
      max-width: 360px;
    }
  }
`;
