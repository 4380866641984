import styled from 'styled-components';

import { blackOpacity, kinectAirColors, palette } from 'Utils/theme';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-width: 350px;
  padding: 30px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: auto;
`;

export const Space = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 36px;
  align-items: center;

  &.mobile {
    justify-content: center;
  }
`;

export const Background = styled.img`
  display: flex;
  height: 75vh;
  object-fit: contain;
  align-self: flex-end;
`;

export const LoginModalContainer = styled.div`
  width: 60%;
  background-color: ${kinectAirColors.background};
  color: ${kinectAirColors.font};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  box-shadow: 24px 24px 24px rgba(126, 126, 126, 0.25);
  outline: none;
  height: 80%;
  max-height: 700px;

  &.mobile {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    max-height: unset;
  }
`;

export const ErrorMessage = styled.label`
  color: var(--color-danger-default);
`;

export const ButtonContainer = styled.div`
  border-top: 1px solid ${palette.gray[80]};
  display: flex;
  gap: 16px;
  padding: 24px;
  justify-content: center;
  width: 100%;

  &.login-modal,
  forgot-pw-modal {
    flex-direction: column;
    gap: 0px;
    max-width: 650px;
  }

  @media screen and (max-width: 1000px) {
    align-items: center;
    gap: 8px;
    flex-direction: column;
  }
`;

export const LoginModalTitle = styled.div`
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  height: 100px;
  background: ${kinectAirColors.background2};
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  flex-shrink: 0;

  &.mobile {
    border-radius: 0px;
    justify-content: center;
    font-size: 22px;
  }

  &.mobileForgot {
    justify-content: center;
    font-size: 22px;
    gap: 10%;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  align-self: center;
  text-align: center;
`;

export const FormInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  align-self: center;
  text-align: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  max-width: 650px;

  &.login-modal {
    flex-grow: 1;
    padding 30px;
  }

  &.mobile {
    padding: 0;
    max-width: 300px;
  }
`;

export const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  text-transform: uppercase;
`;

export const ButtonLink = styled.button`
  all: unset;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 300;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  &.mobile {
    flex-wrap: wrap;
    gap: 0px;
  }
`;

export const ModalButton = styled.button`
  all: unset;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  width: 50px;
  height: 50px;
`;

export const ModalButtonImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const SuccessImage = styled.img`
  width: 200px;
  height: 200px;
`;

export const SuccessWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-image: url('../static/img/multi-ticket-background.png');
  background-size: 100vw;
  background-color: var(--color-bg-2);
  height: 50%;
  margin-top: 50px;
  border-radius: 10px;
  padding: 2rem 0rem;
`;

export const SuccessTitle = styled.div`
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
`;

export const Center = styled.div`
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const styles = {
  container: {
    background: palette.gray[30],
    border: `1px solid ${blackOpacity}`,
    borderRadius: 8,
    padding: '8px',
  },
  input: {
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 20,
    color: palette.black,
    paddingLeft: 68,
    height: 30,
    background: palette.gray[30],
    width: '100%',
  },
  button: {
    background: palette.white,
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: 3,
    height: '80%',
    top: 4,
    width: 58,
    paddingLeft: 8,
  },
};

export const PaymentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
`;

export const PaymentText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  margin: 24px 0px 8px 0px;
  text-align: left;
`;

export const PhoneInputWrap = styled.div`
  padding: 5px 0px 17px 0px;
  width: 100%;
`;
