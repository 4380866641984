import React from 'react';
import { Modal } from '@mui/material';

import { Button } from 'Components/CreditCard/CreditCardModal/styles';
import { exit, rejected } from 'Assets/Images';
import { NestedTicketObject } from 'Components/CreditCard/CreditCardModal/utils';
import { kinectAirColors } from 'Utils/theme';

import {
  Img,
  ModalContainer,
  Text,
  Title,
  TitleContainer,
  Wrapper,
  modalStyle,
  ButtonContainer,
  TicketInfo,
  TicketsInfoContainer,
  CloseIcon,
} from './styles';

interface SoldOutModalProps {
  handleClose: () => void;
  isOpen: boolean;
  unavailableTickets: NestedTicketObject;
}

const SoldOutModal = ({ handleClose, isOpen, unavailableTickets }: SoldOutModalProps) => {
  return (
    <Modal open={isOpen} onClose={handleClose} sx={modalStyle}>
      <ModalContainer>
        <TitleContainer>
          <Title>Unable to complete this transaction</Title>
          <CloseIcon className="close" src={exit} onClick={handleClose} />
        </TitleContainer>
        <Wrapper>
          <Img className="main" src={rejected} />
          <Text className="span">
            The following tickets are sold out and are no longer available for purchase. They have
            been removed from your cart
          </Text>
          <TicketsInfoContainer>
            {Object.keys(unavailableTickets).map((key) => (
              <TicketInfo key={key}>
                <Text>
                  ({unavailableTickets[key].quantity}) {unavailableTickets[key].name}
                </Text>
              </TicketInfo>
            ))}
          </TicketsInfoContainer>
        </Wrapper>
        <ButtonContainer>
          <Button
            color={kinectAirColors.font}
            background={kinectAirColors.callout}
            onClick={handleClose}
          >
            Try Again
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SoldOutModal;
