import React, { useEffect } from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { RootState } from 'store';
import { setIsFromPurchase, toggleAuthModal, toggleAuthModalType } from 'Slices/authModal';
import {
  CHANGE_YOUR_PASSWORD,
  CREATE_ACCOUNT,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_OR_REGISTER,
  PRIVATE_ROUTES,
  ROUTES,
} from 'Utils/constants';
import useMobileView from 'hooks/useMobileView';
import { sendGAPage } from 'Utils/utils';
import { backArrow } from 'Assets/Images';
import { CloseTransparent } from 'Assets/Icons';
import { LoginForm, RegisterForm, ForgotPasswordForm, ChangePasswordForm } from '.';

import { LoginModalContainer, LoginModalTitle, ModalButton, ModalButtonImage } from './styles';

const AuthModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSpinup = localStorage.getItem('isSpinup') === 'true';
  const { isOpen, type, back, isFromPurchase } = useSelector(
    ({ authModal }: RootState) => authModal,
  );
  const isMobile = useMobileView();
  const isFromMyTix = pathname.includes(ROUTES.MY_TICKETS);

  useEffect(() => {
    if (isOpen && pathname.includes(ROUTES.RECEIVE_TRANSFER))
      sendGAPage({ page: ROUTES.RECEIVE_TRANSFER, title: 'Receive Ticket Page' });
  }, [isOpen]);

  const selectedOption = () => {
    switch (type) {
      case 'login':
        return {
          title: LOGIN,
          Component: <LoginForm link="/buy-tickets" />,
          goTo: '',
        };
      case 'register':
        return {
          title: CREATE_ACCOUNT,
          Component: <RegisterForm />,
          goTo: '',
          backButton: true,
        };
      case 'forgot_password':
        return {
          title: FORGOT_PASSWORD,
          Component: <ForgotPasswordForm />,
          goTo: '',
          backButton: true,
        };
      case 'change_password':
        return { title: CHANGE_YOUR_PASSWORD, Component: <ChangePasswordForm />, goTo: '' };
      default:
        return {
          title: LOGIN_OR_REGISTER,
          Component: <LoginForm link="/buy-tickets" />,
          goTo: '',
        };
    }
  };

  const renderForm = () => {
    const { title, Component, goTo, backButton } = selectedOption();
    return (
      <LoginModalContainer className={isMobile || isSpinup ? 'mobile' : ''}>
        <LoginModalTitle
          className={
            ((isMobile && title === FORGOT_PASSWORD) || isSpinup ? 'mobileForgot' : '') ||
            ((isMobile && title !== FORGOT_PASSWORD) || isSpinup ? 'mobile' : '')
          }
        >
          {(!isMobile || (isMobile && backButton && back)) && (
            <ModalButton
              disabled={!back}
              onClick={() => dispatch(toggleAuthModalType({ type: back, back: goTo }))}
            >
              {back && <ModalButtonImage src={backArrow} />}
            </ModalButton>
          )}
          {title}
          {!isMobile && (
            <ModalButton
              onClick={() => {
                dispatch(toggleAuthModal(false));
              }}
            >
              {!isFromPurchase && <ModalButtonImage src={CloseTransparent} />}
            </ModalButton>
          )}
        </LoginModalTitle>
        {Component}
      </LoginModalContainer>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if ((isFromPurchase || isFromMyTix) && ['backdropClick', 'escapeKeyDown'].includes(reason))
          return;
        const redirectToBuyTickets = !isFromPurchase && PRIVATE_ROUTES.includes(pathname);
        dispatch(toggleAuthModal(false));
        dispatch(setIsFromPurchase(false));
        if (redirectToBuyTickets) {
          if (isSpinup && event) history.push(`${ROUTES.BUY_TICKETS}/${event}`);
          else history.push(ROUTES.BUY_TICKETS);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: '0px',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'transparent',
      }}
      BackdropProps={{
        classes: {
          root: 'modal-background',
        },
      }}
    >
      {renderForm()}
    </Modal>
  );
};

export default AuthModal;
