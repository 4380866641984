import React from 'react';
import { Template1 } from 'Components/Templates/Template1';
// import { Template2 } from 'Components/Templates/Template2';
import { TicketImg } from './styles';

export interface DisplayTicketProps {
  ticket: any;
  event: any;
  displayType?: string;
  className?: string;
  isMobile: boolean;
  moreTickets: boolean;
}

export const TicketType = ({
  ticket,
  event,
  displayType,
  isMobile,
  moreTickets,
  className = '',
}: DisplayTicketProps) => {
  const selectedType = () => {
    switch (displayType) {
      case 'image':
        return (
          <TicketImg
            isMobile={isMobile}
            src={ticket?.image}
            alt="ticket"
            className={moreTickets ? `${className}  multiple` : className}
          />
        );
      case 'template0':
        return (
          <Template1
            event={event}
            ticket={ticket}
            className={moreTickets ? `${className}  multiple` : className}
          />
        );
      // case 'template1':
      //   return (
      //     <Template2
      //       event={event}
      //       ticket={ticket}
      //       className={moreTickets ? `${className}  multiple` : className}
      //     />
      //   );
      default:
        return (
          <TicketImg
            isMobile={isMobile}
            src={ticket?.image}
            alt="ticket"
            className={moreTickets ? `${className}  multiple` : className}
          />
        );
    }
  };
  return selectedType();
};
