import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useParams, useLocation } from 'react-router-dom';

import TicketList from 'Components/TicketList';
import { ROUTES, YOUR_TICKETS } from 'Utils/constants';
import { RootState } from 'store';
// import { Navbar } from 'Components/Common/Navbar/Navbar';
// import { HamburgerMenu } from 'Assets/Icons';
// import useMobileView from 'hooks/useMobileView';
// import CartCounter from 'Components/Common/CartCounter';
import { sendGAPage } from 'Utils/utils';

import { Header, Container, Hamburger } from '../styles';

export default function ExistingTickets() {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const { secondaryColor, headerColor, backgroundColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );
  // const isMobile = useMobileView();
  // const { pathname } = useLocation();
  // const { id: partnerId } = useParams<{ id: string }>();

  // useEffect(() => {
  //   if (partnerId && pathname.includes('partner')) localStorage.setItem('partnerId', partnerId);
  // }, [partnerId]);

  useEffect(() => {
    sendGAPage({ page: ROUTES.MY_TICKETS, title: 'My Tickets Page' });
  }, []);

  return (
    <Container>
      {/* <Navbar isMobile={isMobile} isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      <Header className="apply-font-0" style={{ background: secondaryColor, color: headerColor }}>
        {/* {isMobile && <Hamburger src={HamburgerMenu} onClick={() => setIsOpen(true)} />} */}
        {YOUR_TICKETS}
        {/* <CartCounter /> */}
      </Header>
      <TicketList pastEvents={false} />
    </Container>
  );
}
