import styled from 'styled-components';

import { checkBoxOrange, kinectAirColors, palette } from 'Utils/theme';

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ModalContainer = styled.div<{ isMobile?: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : '60%')};
  height: ${(props) => (props.isMobile ? '100%' : '700px')};
  background: ${kinectAirColors.background};
  border-radius: ${(props) => (props.isMobile ? '0' : '24px')};
  outline: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${kinectAirColors.font};
`;

export const TitleContainer = styled.div<{ isMobile?: boolean }>`
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${kinectAirColors.background2};
  border-radius: ${(props) => (props.isMobile ? '0' : '24px 24px 0px 0px')};
  position: relative;
`;

export const Img = styled.img`
  &.close {
    width: 24px;
    position: absolute;
    right: 22px;
    cursor: pointer;
  }
  &.main {
    width: 20%;
  }
  &.powered {
    position: absolute;
    right: 20px;
  }
  &.trash {
    width: 28px;
    height: 28px;
    margin-left: 10px;
  }
`;

export const Wrapper = styled.div<{
  isFromSelect?: boolean;
  fromCardConfirmation?: boolean;
  isFromEdit?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.isFromSelect ? 'flex-start' : props.isFromEdit ? 'center' : 'space-evenly'};
  align-items: center;
  flex-direction: column;
  height: ${(props) =>
    props.isMobile ? '' : props.isFromSelect || props.fromCardConfirmation ? '65%' : '85%'};
  overflow-y: ${(props) => (props.isFromSelect ? 'scroll' : 'unset')};
  padding-top: ${(props) => (props.isFromSelect ? '20px' : 0)};
  padding: ${(props) => (props.isFromEdit ? '10px' : 0)};
  margin-top: ${(props) => (props.isMobile ? '60px' : 0)};
  &.relative {
    position: relative;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${kinectAirColors.font};
  &.span {
    font-weight: 400;
    font-size: 20px;
  }
  &.font-weight {
    font-weight: 400;
    margin-right: 4px;
  }
  &.medium-large {
    font-size: 20px;
  }
  &.medium {
    font-size: 18px;
  }
  &.small {
    font-size: 16px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.center {
    align-items: center;
  }
  &.max-width {
    max-width: 600px;
  }
  &.shadow {
    box-shadow: 5px 5px 5px ${palette.gray[90]};
    background: ${palette.gray[50]};
    padding: 30px;
    border-radius: 10px;
  }
`;

export const Button = styled.div<{ color?: string; background?: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${palette.white};
  background: ${palette.gray[7]};
  border-radius: 100px;
  padding: 5px 10px;
  cursor: pointer;
  &.edit {
    padding: 5px 30px;
  }
  &.remove {
    background: #eb5757;
    border-radius: 8px;
    padding: 5px 10px;
    margin-top: 10px;
  }
  &.back {
    border-radius: 8px;
    padding: 5px 10px;
    margin-top: 10px;
    justify-content: center;
    width: 70px;
  }
  &.select {
    height: fit-content;
    border-radius: 8px;
    padding: 5px 35px;
    font-size: 18px;
    background: ${(props) => props.background};
    color: ${(props) => props.color || palette.white};
  }
  &.padding {
    padding: 5px 15px;
  }
  &.cancel {
    background: ${palette.white};
    border: 2px solid ${(props) => props.background};
    color: ${(props) => props.background};
    padding: 3px 35px;
  }
  &.margin-right {
    margin-right: 10px;
  }
`;

export const Row = styled.div<{ checked?: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.isMobile ? 'center' : '')};
  flex-direction: ${(props) => (props.isMobile ? 'column-reverse' : '')};
  gap: ${(props) => (props.isMobile ? '15px' : '')};
  &.align-center {
    align-items: center;
  }
  &.width {
    width: 365px;
  }
  &.space {
    justify-content: space-between;
  }
  &.buttonContainer {
    height: 20%;
    border-top: '1px ${palette.gray[80]} solid';
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.checkbox-container {
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 0px 10px 10px;
    ${(props) =>
      props.checked && {
        background: 'rgba(220, 114, 67, 0.1)',
        border: `1px solid ${checkBoxOrange}`,
        borderRadius: 16,
      }}
  }
  &.pointer {
    cursor: pointer;
  }
  &.selected {
    padding: 10px 40px 10px 10px;
  }
`;

export const CheckBox = styled.div<{ checked: boolean }>`
  border: 3px solid ${(props) => (props.checked ? checkBoxOrange : palette.gray[9])};
  border-radius: 50px;
  width: 25px;
  height: 25px;
  position: relative;
  margin-right: 10px;
  &.pointer {
    cursor: pointer;
  }
`;

export const Filled = styled.div`
  background: ${checkBoxOrange};
  border-radius: 50px;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const TextContainer = styled.div`
  display: flex;
  background: ${kinectAirColors.callout};
  border: 1px solid ${palette.black};
  border-radius: 8px;
  padding: 3px 10px;
  margin-bottom: 10px;
  &.mobile {
    flex-direction: column;
    align-items: center;
  }
`;
