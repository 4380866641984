export type Available = { [key: string]: boolean };

export type NestedTicketObject = {
  [key: string]: { quantity: number; promocode_id: string; name?: string };
};

type NumberObject = { [key: string]: number };

export function convertObject(originalObject: NestedTicketObject): NumberObject {
  const convertedObject: NumberObject = {};

  Object.keys(originalObject).forEach((key) => {
    const { quantity } = originalObject[key];
    convertedObject[key] = quantity;
  });

  return convertedObject;
}

export function getKeysWithFalseValues(obj: Available): string[] {
  return Object.keys(obj).filter((key) => obj[key] === false);
}

export function filterObjectByKeys(object: NestedTicketObject, keys: string[]): NestedTicketObject {
  const filteredObject: NestedTicketObject = {};

  Object.keys(object).forEach((key) => {
    if (keys.includes(key)) {
      filteredObject[key] = object[key];
    }
  });

  return filteredObject;
}
