import React, { useEffect, useState } from 'react';
import { useQuery, QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { useSelector } from 'react-redux';

import { purchaseConfirmation } from 'Assets/Images';
import ConfirmationModal from 'Components/Common/ConfirmationModal';
import CreditCardModal from 'Components/CreditCard/CreditCardModal';
import SoldOutModal from 'Components/Common/ConfirmationModal/SoldOutModal/SoldOutModal';
import { NestedTicketObject } from 'Components/CreditCard/CreditCardModal/utils';
import { getData } from 'api/api';
import { Card } from 'Utils/types';
import { RejectedModal } from 'Components/Common/ConfirmationModal/RejectedModal';
import { nuveiTax1, nuveiTax2 } from 'Utils/utils';
import { queryClient } from 'QueryClientConfig';
import { RootState } from 'store';
import { PurchaseWrapper } from './PurchaseWrapper';

const titles = [
  { title: 'Receiving Tickets', code: 'RT' },
  { title: 'Billing Info', code: 'BI' },
  { title: 'Payment info', code: 'CI' },
];

const titlesWithCards = [
  { title: 'Receiving Tickets', code: 'RT' },
  { title: 'Payment info', code: 'CI' },
];

interface Props {
  ticketsTypeIds: number[];
  currentPurchaseCart: any;
  setCurrentPurchaseCart: React.Dispatch<any>;
  totalQuantity: number;
  eventInfo: any;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>;
  setIsCreditCardModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreditCardModalOpen: boolean;
  totalTaxes: number;
  fixedPrice: number;
  totalFees: number;
  nuveiFees: number;
  tixologiFees: number;
  taxData: any;
  ticketsWithPromocodes: number[];
  promoCodeId: number;
  promoCodeData: any;
  promoCodeApplied: boolean;
  setTicketsWithPromocodes: React.Dispatch<React.SetStateAction<number[]>>;
  setPromoCodeId: React.Dispatch<React.SetStateAction<number>>;
  ticketTypeDic: {
    [key: string]: {
      quantity: number;
      promocode_id: string;
    };
  };
  setTicketTypeDic: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        quantity: number;
        promocode_id: string;
      };
    }>
  >;
}

const loadScript = (src: any) =>
  new Promise<void>((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: (e: any) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

export default function PurchaseOrder({
  currentPurchaseCart,
  ticketsTypeIds,
  setCurrentPurchaseCart,
  totalQuantity,
  eventInfo,
  refetch,
  setIsCreditCardModalOpen,
  isCreditCardModalOpen,
  totalTaxes,
  fixedPrice,
  totalFees,
  nuveiFees,
  tixologiFees,
  taxData,
  ticketsWithPromocodes,
  promoCodeId,
  promoCodeData,
  promoCodeApplied,
  setTicketsWithPromocodes,
  setPromoCodeId,
  ticketTypeDic,
  setTicketTypeDic,
}: Props) {
  const [purchaseOnClick, setPurchaseOnClick] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isRejectedOpen, setIsRejectedOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCards, setShowCards] = useState<boolean>(false);
  const [cardN, setCardN] = useState(null);
  const [transactionId, setTransactionId] = useState<string>('');

  const [errorDescription, setErrorDescription] = useState<string>('');
  const [isSoldOutModalOpen, setIsSoldOutModalOpen] = useState<boolean>(false);
  const [unavailableTickets, setUnavailableTickets] = useState<NestedTicketObject>({});

  const { transferId } = useSelector((state: RootState) => state.paymentInfo);

  const hasToken = !!localStorage.getItem('accessToken');

  const { data: cards } = useQuery('getCards', () => getData(`cards/users/my-cards`), {
    refetchOnWindowFocus: false,
    enabled: hasToken,
  });

  useEffect(() => {
    setIsConfirmationOpen(!!transferId);
    setTransactionId(transferId);
  }, [transferId]);

  useEffect(() => {
    if (cards?.length > 0) {
      // TODO - show cards when we have bluesnap cards stored -- setShowCards(true); --
    }
    cards?.sort((x: Card, y: Card) => (x.is_primary ? -1 : y.is_primary ? 1 : 0));
  }, [cards]);

  useEffect(() => {
    const ticketTypesDic = currentPurchaseCart?.reduce(
      (obj: any, currentValue: any) => ({
        ...obj,
        [currentValue.ID]: {
          name: currentValue.name,
          quantity: currentValue.quantity,
          ticket_price: currentValue.price_no_discount,
          net_price_per_ticket: currentValue.initial_price,
          discount_applied: Number(
            (currentValue.price_no_discount - currentValue.initial_price).toFixed(2),
          ),
          number_of_applied_promocodes:
            Number((currentValue.price_no_discount - currentValue.initial_price).toFixed(2)) === 0
              ? 0
              : currentValue.quantity,
          promocode_id: ticketsWithPromocodes.find((e) => e === currentValue.ID)
            ? String(promoCodeId)
            : '',
        },
      }),
      {},
    );
    setTicketTypeDic(ticketTypesDic);
  }, [promoCodeId, ticketsWithPromocodes, promoCodeData, promoCodeApplied]);

  return (
    <>
      <PurchaseWrapper
        setIsConfirmationOpen={setIsConfirmationOpen}
        quantity={totalQuantity}
        setIsOpen={setIsCreditCardModalOpen}
        currentPurchaseCart={currentPurchaseCart}
        setCurrentPurchaseCart={setCurrentPurchaseCart}
        totalTaxes={Number(totalTaxes)}
        totalFees={Number(totalFees)}
        fixedPrice={Number(fixedPrice)}
        eventInfo={eventInfo}
        ticketTypeDic={ticketTypeDic}
        setPurchaseOnClick={setPurchaseOnClick}
        purchaseOnClick={purchaseOnClick}
        ticketTypesID={ticketsTypeIds}
        setTicketsWithPromocodes={setTicketsWithPromocodes}
        setPromoCodeId={setPromoCodeId}
        openSoldOutModal={() => setIsSoldOutModalOpen(true)}
        setUnavailableTickets={setUnavailableTickets}
      />
      {isCreditCardModalOpen && (
        <CreditCardModal
          isOpen={isCreditCardModalOpen}
          setIsOpen={setIsCreditCardModalOpen}
          setIsConfirmationOpen={setIsConfirmationOpen}
          fromPurchase
          fromCart={false}
          titles={showCards ? titlesWithCards : titles}
          cards={cards}
          showCards={showCards}
          setShowCards={setShowCards}
          price={fixedPrice}
          totalAmount={Number(
            (Number(fixedPrice) + Number(totalTaxes) + Number(totalFees)).toFixed(2),
          )}
          nuveiFees={Number(nuveiFees)}
          ourFees={{
            amount: String(tixologiFees),
            currency: 'USD',
            fees_rate: taxData?.partner_royalties?.tixologi_primary_royalty,
            fixed_fee: taxData?.partner_royalties?.tixologi_fixed_primary_royalty,
          }}
          tixologiFees={Number(tixologiFees)}
          totalTaxes={Number(totalTaxes)}
          taxes={{
            rate:
              taxData?.ticket_taxes[currentPurchaseCart[0]?.ID] ||
              taxData?.partner_royalties?.partner_default_tax, // tax del primer ticket || partenr default tax
            our_taxes: String(
              Number(tixologiFees) *
                (taxData?.ticket_taxes[currentPurchaseCart[0]?.ID] ||
                  taxData?.partner_royalties?.partner_default_tax),
            ),
            vendor_taxes: String(
              Number(totalTaxes) -
                Number(tixologiFees) *
                  (taxData?.ticket_taxes[currentPurchaseCart[0]?.ID] ||
                    taxData?.partner_royalties?.partner_default_tax),
            ),
          }}
          setCardN={setCardN}
          cardN={cardN}
          isLoading={isLoading}
          setTransactionId={setTransactionId}
          setIsRejectedOpen={setIsRejectedOpen}
          ticketTypeDic={ticketTypeDic}
          setPurchaseOnClick={setPurchaseOnClick}
          serviceFee={nuveiTax1}
          fixedServiceFee={nuveiTax2}
          setTicketsWithPromocodes={setTicketsWithPromocodes}
          ticketsWithPromocodes={ticketsWithPromocodes}
          fetchCart={refetch}
          setErrorDescription={setErrorDescription}
          errorDescription={errorDescription}
          openSoldOutModal={() => setIsSoldOutModalOpen(true)}
          setUnavailableTickets={setUnavailableTickets}
        />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          title={fixedPrice === 0 ? 'Free Ticket Complete' : 'Purchase Complete'}
          image={purchaseConfirmation}
          orderNumber={transactionId}
          span={`Your ${
            fixedPrice === 0 ? 'free ticket' : 'purchase'
          } is complete. Expect an email with your ticket 
          information in the next 10mins.`}
          closeButton
          fromPurchase={fixedPrice !== 0}
        />
      )}
      {isRejectedOpen && (
        <RejectedModal
          title="Payment not complete"
          isOpen={isRejectedOpen}
          setIsOpen={setIsRejectedOpen}
          errorDescription={errorDescription}
          setErrorDescription={setErrorDescription}
        />
      )}
      {isSoldOutModalOpen && (
        <SoldOutModal
          isOpen={isSoldOutModalOpen}
          handleClose={() => setIsSoldOutModalOpen(false)}
          unavailableTickets={unavailableTickets}
        />
      )}
    </>
  );
}
