import styled from 'styled-components';

import { palette, shadowContainer, kinectAirColors } from 'Utils/theme';


export const Container = styled.div<{ isMobile?: boolean }>`
  background-color: ${kinectAirColors.background};
  box-shadow: 12px 20px 25px ${shadowContainer};
  color: ${kinectAirColors.font};
  margin-top: 0;
  font-weight: 300;
  min-height: 100vh;
  @media screen and (max-width: 730px) {
    box-shadow: none;
  }
  &.mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    max-height: 100vh;
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
`;

export const Img = styled.img`
  width: 150px;
  height: 20px;
  margin-bottom: 15px;
`;

export const Header = styled.div<{ isUpperHeader?: boolean; isMobile?: boolean }>`
  position: relative;
  width: 100%;
  color: ${palette.white};
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: ${(props) => (props.isUpperHeader && props.isMobile ? '15px' : '30px')};
  box-shadow: ${(props) =>
    props.isUpperHeader && props.isMobile ? '0px 10px 20px rgb(0 0 0 / 10%)' : 'none'};
`;

export const Loading = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: cover;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
  z-index: 1000000;
  opacity: 1;
`;

export const Div = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: ${shadowContainer};
  opacity: 0.8;
`;

export const Hamburger = styled.img<{ isUpperHeader?: boolean }>`
  position: absolute;
  left: 30px;
  top: ${(props) => (props.isUpperHeader ? '39px' : '34px')};
  width: 27px;
  &.top {
    top: 31px;
  }
`;

export const UpperHeader = styled.div`
  background-color: ${kinectAirColors.background2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 26px;
`;

export const Wrapper = styled.div``;

export const DivFonts = styled.div`
  height: 0;
  width: 0;
  overflow: hidden;
`;
