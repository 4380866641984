import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { Provider, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import AuthModal from 'Views/AuthModal/AuthModal';
import './App.css';
import { store } from 'store';
import { toggleAuthModal } from 'Slices/authModal';
import ErrorBoundary from 'ErrorBoundary';
import { queryClient } from 'QueryClientConfig';
// import FontsSelector from './FontsSelector';
import Router from './Router';

ReactGA.initialize(`${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`);

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (!pathname || !pathname.includes('/buy-tickets')) {
      dispatch(toggleAuthModal(true));
    } else if (pathname.includes('/transaction-id/')) {
      dispatch(toggleAuthModal(true));
    } else {
      dispatch(toggleAuthModal(false));
    }
  }, [pathname, dispatch]);

  useEffect(() => {
    if (!pathname.includes('/existing-tickets') && !!userId)
      queryClient.setQueryData(['getTicketsFromMail'], () => undefined);
  }, [pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Provider store={store}>
          <Router />
          <AuthModal />
          {/* <FontsSelector /> //TODO: Kinectair don't need this */}
        </Provider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
