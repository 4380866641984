import styled from 'styled-components';

import { palette } from 'Utils/theme';

export const Container = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  padding: ${(props) => (props.isMobile ? '0px' : '80px')};
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.w60 {
    width: 60%;
  }
  &.w40 {
    width: 40%;
  }
`;

export const Quantity = styled.div`
  background: ${palette.gray[15]};
  padding: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${palette.black};
`;
