import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { RootState } from 'store';
import { ROUTES } from 'Utils/constants';
import { Container, InnerContainer, Label, Wrapper } from './styles';

interface Props {
  isOpen: any;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: any;
}

const navigationOptions = [
  { name: 'Buy Tickets', value: ROUTES.BUY_TICKETS },
  { name: 'My Tickets', value: ROUTES.MY_TICKETS },
  { name: 'Past Events', value: ROUTES.PAST_EVENTS },
  { name: 'My Listings', value: ROUTES.MY_LISTINGS },
  { name: 'Account', value: ROUTES.ACCOUNT },
];

const event = localStorage.getItem('event');

const spinupNavigationOptions = [
  { name: 'Buy Tickets', value: `${ROUTES.BUY_TICKETS}/event/${event}` },
  { name: 'My Tickets', value: ROUTES.MY_TICKETS },
  { name: 'Past Events', value: ROUTES.PAST_EVENTS },
  { name: 'My Listings', value: ROUTES.MY_LISTINGS },
  { name: 'Account', value: ROUTES.ACCOUNT },
];

export const NavbarMobile = ({ isOpen, setIsOpen, user }: Props) => {
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const isSpinup = localStorage.getItem('isSpinup') === 'true';
  const { primaryColor, secondaryFontColor, secondaryColor } = useSelector(
    ({ widgetData }: RootState) => widgetData.data,
  );

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !(ref as any).current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <Container ref={ref} className={isOpen ? 'active' : ''}>
      <InnerContainer>
        {user && (
          <Wrapper className="margin">
            <Label className="apply-font-1" fontColor={secondaryFontColor}>
              Welcome
            </Label>
            <Label className="apply-font-1 capitalize" fontColor={secondaryColor}>
              {user.full_name}
            </Label>
          </Wrapper>
        )}
        {(isSpinup ? spinupNavigationOptions : navigationOptions).map(({ name, value }) => (
          <Label
            key={value}
            className={
              location.pathname.startsWith(value)
                ? 'selected apply-font-1 mobile'
                : 'apply-font-1 mobile'
            }
            onClick={() => history.push(value)}
            primary={primaryColor}
            fontColor={secondaryFontColor}
          >
            {name}
          </Label>
        ))}
      </InnerContainer>
    </Container>
  );
};
